import {Injectable} from '@angular/core';
import {Cart} from 'src/app/cart/interface/cart';
import {GetCartSelectedFinishProfileItemList} from 'src/app/cart/interface/cart-get';
import {CartCalculationService} from 'src/app/cart/service/cart-calculation.service';
import {MadeToOrderFabric} from 'src/app/product-details/interface/made-to-order-fabric';
import {VolumeDiscountProfile} from 'src/app/product-details/interface/volume-discount-profile';
import {FabricVolumeDiscuntCalculationService} from 'src/app/product-details/service/fabric-volume-discunt-calculation.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutFabricVolumeDiscountPriceService {

  constructor(
	private _cartCalculation: CartCalculationService,
	private _fabricVolumeDiscountCalculation: FabricVolumeDiscuntCalculationService
  ) { }

public calculateFabricVolumeDiscountPrice(data: Cart): number {

	this._cartCalculation.inStockFinishPrice = 0
		// console.log(data);

		let consumedFabric =(data.selectedSizeOption ? data.selectedSizeOption.consumedFabric:
			((data.selectedFabric && data.selectedFabric.product.madeToOrderProfile) ?  data.selectedFabric?.product.madeToOrderProfile.consumedFabric! : 1));

		this._cartCalculation.selectedConsumedFabric = consumedFabric;
		this._cartCalculation.selectedFabricItem = data.selectedFabric ? data.selectedFabric.product : {} as MadeToOrderFabric;

		let fabricPrice = this._cartCalculation.fabricPriceWithoutVD();

		let calcPrice = this._fabricVolumeDiscountCalculation.calculateFabricVolumeDiscount(
					fabricPrice,
					( data.quantity * consumedFabric),
					data.selectedFabric?.product.volumeDiscountProfile || {} as VolumeDiscountProfile
				);

		if(calcPrice) {
			let finishPrice = this._finishPriceCalc(data.selectedFinishList || []);

			if (finishPrice) calcPrice = calcPrice + finishPrice;

			calcPrice = calcPrice + data.makingCharge!||0;
		}

		return calcPrice;

	}




	private _finishPriceCalc(finishList:GetCartSelectedFinishProfileItemList[]): number {

		// debugger
		this._cartCalculation.selectedFinishForCartCalc = [...finishList]; // for cart selected fabric

		let price: number[] = [];
		// console.log(this.selectedFinishPrice);
		finishList.forEach((finish: GetCartSelectedFinishProfileItemList):void => {
			price.push(finish.price);
		});

		const arrSum:number = price.reduce((sum:number, current:number) => sum + current, 0);
		// return arrSum - this.inStockFinishPrice
		return arrSum;

	}
}
