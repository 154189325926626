 <section class="fb-home-fp-new w-full flex flex-col justify-center items-center min-h-[50vh] pt-10 pb-7">
	<div class="container flex flex-col lg:flex-row justify-between items-center">
		<div class="my-5 lg:my-0  lg:flex-[30%] mx-2 lg:mx-0 px-4 lg:px-0">
			<h2 class="text-3xl sm:text-4xl">Our</h2>
			<h1 class="text-3xl sm:text-4xl text-[#b7a98f] font-medium mb-3">
				Featured Products
			</h1>
<!--			<p class="text-2xl my-2">Shop by <span class="text-[#9c8a6c]">Categories</span></p>-->
			<a [routerLink]="'/products/fabric'"
			   target="_blank"
			   class="text-lg sm:text-3xl py-2 fb_animate_icon_button">
				<i class="fb_animate"><b ></b><span></span></i>
				Discover More
			</a>
		</div>
		<div class=" lg:flex-[70%]">
			<div class="fb-fp-tabs">
				<div (click)="tabSelect('fabric', 0)"
						class="{{deltaIndex ===0?'tab-selected':''}}">
					Fabric
				</div>
				<ng-container *ngFor="let category of finishedProducts; let i = index">
					<div (click)="tabSelect(category.name, i+1)"
							class="{{deltaIndex === (i+1)?'tab-selected':''}}">
						{{category.name}}
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	 <div class="fb-f-product gap-5" >
		 <ng-container *ngIf="selectedProductType === 'fabric'">
			 <owl-carousel-o [options]="customOptions">
				 <ng-container *ngFor="let segmentCategory of fabricProducts.category">

					 <ng-container *ngFor="let subCategory of segmentCategory.segmentCategory">
						 <ng-template carouselSlide>
							 <a [href]="generateSegmentRedirectionLink(
								 '/products/fabric', segmentCategory.segmentCategoryName, subCategory.subCategoryName
								 )"
								class="fb-fp-card flex flex-col justify-center items-center relative">
								 <img class="fb-fp-image" [src]="subCategory.image" [alt]="subCategory.subCategoryName">
								 <div
									class="w-[90%] max-w-[300px] flex justify-between items-center fb-fp-view  px-2 py-1.5 absolute bottom-5">
									 <p class="text-white text-xs sm:text-sm font-semibold">
										 {{subCategory.subCategoryName | truncketHtml: 15}}
									 </p>
<!--									 <div class="flex justify-center items-center text-xs text-white font-bold">-->
<!--										 • <span class="text-xs mx-1">{{selectedCurrency | uppercase}}</span> {{product.price | currencyConverter | number: '1.0-2'}}-->
<!--									 </div>-->
									 <button class="rounded-xl text-white bg-[#6c5b48] px-2.5 py-1">View</button>
								 </div>
							 </a>
						 </ng-template>
					 </ng-container>
				 </ng-container>
			 </owl-carousel-o>
		 </ng-container>

		 <ng-container *ngIf="finishedProducts.length>0">
			 <ng-container *ngFor="let category of finishedProducts; let i = index">
				 <ng-container *ngIf="selectedProductType === category.name">
					 <owl-carousel-o [options]="customOptions">
						 <ng-container *ngFor="let segmentCategory of category.category; let i = index">

							 <ng-container *ngFor="let subCategory of segmentCategory.segmentCategory; let l = index">
								 <ng-template carouselSlide>
									 <a [href]="generateFinishedSegementLink(
										 '/products/finished', segmentCategory.segmentCategoryName, subCategory.subCategoryName
										 )"
										class="fb-fp-card flex flex-col justify-center items-center relative">
										 <img class="fb-fp-image" [src]="subCategory.image" [alt]="subCategory.subCategoryName">
										 <div
											 class="w-[90%] max-w-[300px] flex justify-between items-center fb-fp-view  px-2 py-1.5 absolute bottom-5">
											 <p class="text-[#6c5b48] text-xs sm:text-sm font-semibold capitalize">
												 {{subCategory.subCategoryName.toLowerCase() | truncketHtml: 17}}
											 </p>
											 <!--									 <div class="flex justify-center items-center text-xs text-white font-bold">-->
											 <!--										 • <span class="text-xs mx-1">{{selectedCurrency | uppercase}}</span> {{product.price | currencyConverter | number: '1.0-2'}}-->
											 <!--									 </div>-->
											 <button class="rounded-xl text-white bg-[#6c5b48] px-2.5 py-1">View</button>
										 </div>
									 </a>
								 </ng-template>
							 </ng-container>
						 </ng-container>
					 </owl-carousel-o>
				 </ng-container>
			 </ng-container>
		 </ng-container>
	 </div>

</section>
