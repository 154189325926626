import {Injectable} from '@angular/core';
import {TransmissionService} from "./transmission.service";
import {HttpClient} from "@angular/common/http";
import {HttpErrorHandlerService} from "../http-error-handler.service";
import {JWTService} from "../../authentication/jwt.service";
import {PasteboxService} from "../../services/pastebox.service";
import {ProductPreview} from "../../product/product-listing/interface/product-preview";
import {SearchResponse} from "../interface/search-response/search-response";
import {ConstantService} from "../../services/constant.service";

@Injectable({
	providedIn: 'root'
})
export class SearchTransmissionService extends TransmissionService {

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
		_pastebox: PasteboxService
	) {
		super(
			_http,
			_httpErrorHandler,
			_jwt,
			_pastebox
		);
	}


	public getSearchResult(
		url:string,
		onPreExecute: () => void,
		onPostExecute: (response: ProductPreview[]) => void,
		onSuccess: (response: ProductPreview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void{
		this.executeGetPayload<SearchResponse,ProductPreview[]>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.PRODUCT_LISTING_ATTRIBUTE_KEY,
			false
		);
	}
}
