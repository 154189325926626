import {Injectable} from '@angular/core';
import {ProductListing} from "../../raintree/interface/product-listing/product-listing";
import {ProductPreview} from "../product-listing/interface/product-preview";
import {FinishProfileItem} from 'src/app/product-details/interface/finish-profile-item-list';
import {PriceCalculationService} from 'src/app/product-details/service/price-calculation.service';
import {VolumeDiscountProfile} from 'src/app/product-details/interface/volume-discount-profile';


@Injectable({
	providedIn: 'root'
})
export class ProductListPriceCalculationService {

	constructor(
		private _priceCalculation: PriceCalculationService,
	) { }

	public calculateListingPriceForFinishedProduct(
		products: ProductListing[],

	): ProductListing[] {

		products.forEach((product:ProductListing): void => {

			let calculatePrice: number = 0

			let productPreview: ProductPreview = product.product;
			let productBasePrice: number = productPreview.price;
			let isTheProductFinish:boolean = productPreview.productGroup==='finished';


			product.product.basePrice = product.product.price;

			if( isTheProductFinish ) {

				let selectedFabricPrice: number =
					productPreview.madeToOrderFabric?productPreview.madeToOrderFabric.price: productBasePrice;

				let consumedFabric: number = this._getConsumedFabric(productPreview);

				let selectedFinishPrice: number = this._getSelectedFinishPrice(
					productPreview,
					productPreview.finishProfileItemId?productPreview.finishProfileItemId:''
				);

				calculatePrice = selectedFabricPrice*consumedFabric;

				calculatePrice = calculatePrice + productBasePrice + selectedFinishPrice;

				product.product.price = calculatePrice;



			}
		});


		return products
	}

	public calculateFilterPriceForFinishedProduct(
		products: ProductPreview[],

	): ProductPreview[] {

		products.forEach((product: ProductPreview): void => {

			let calculatePrice: number = 0;

			let productPreview: ProductPreview = product;
			let productBasePrice: number = productPreview.price;
			let isTheProductFinish: boolean = productPreview.productGroup === 'finished';


			product.basePrice = product.price;

			if (isTheProductFinish) {
				let selectedFabricPrice: number =
					productPreview.madeToOrderFabric ? productPreview.madeToOrderFabric.price : productBasePrice;

				let consumedFabric: number = this._getConsumedFabric(productPreview);

				let selectedFinishPrice: number = this._getSelectedFinishPrice(
					productPreview,
					productPreview.finishProfileItemId ? productPreview.finishProfileItemId : ''
				);

				calculatePrice = selectedFabricPrice * consumedFabric;

				calculatePrice = calculatePrice + productBasePrice + selectedFinishPrice;
				product.price = calculatePrice;
			}
		});

		return products
	}

	private _getConsumedFabric(productPreview: ProductPreview): number {

		let consumedFabric: number = 0;

		if(productPreview.sizeProfile?.sizeProfileOptionList && productPreview.sizeProfile?.sizeProfileOptionList.length){

			consumedFabric = productPreview.sizeProfile?.sizeProfileOptionList[0].consumedFabric;
		}
		else consumedFabric =
			productPreview.madeToOrderProfile?.consumedFabric?productPreview.madeToOrderProfile?.consumedFabric:1;

		// if(!productPreview.madeToOrderProfile && !productPreview.madeToOrderFabric){
		// 	consumedFabric = 0;
		// }

		return consumedFabric;
	}

	private _getSelectedFinishPrice(productPreview: ProductPreview , defaultFinishIds: string): number {

		let selectedFinishedPrice: number = 0;

		if(defaultFinishIds) {

			let parsedSelectedFinishIds: string[] = defaultFinishIds.split(',');
			let selectedFinished: number[] = [];

			if(productPreview.finishProfile?.finishProfileItemList.length){

				parsedSelectedFinishIds.forEach((parsedId: string): void=>{

					productPreview.finishProfile?.finishProfileItemList.forEach(
						(finish: FinishProfileItem): void=> {

							if(parsedId == finish.id+'') {
								selectedFinished.push(finish.price);
							}
						});
				});

				selectedFinishedPrice = selectedFinished.reduce((sum:number, current:number) => sum + current, 0);
			}



		}
		else return selectedFinishedPrice;


		return selectedFinishedPrice;
	}

	public getBulkPrice(product:ProductListing, isThisProductFabric: boolean): number {


		this._priceCalculation.storePrice = 0;
		let productPreview: ProductPreview = product.product;
		let productBasePrice: number = productPreview.price;
		let productPreviousPrice: number = product.product.basePrice || 0;

		let selectedFabricPrice: number =
			productPreview.madeToOrderFabric?productPreview.madeToOrderFabric.price: productBasePrice;

		if(!productPreview.fabricProfile && !productPreview.sizeProfile && productPreview.productGroup==='fabric' ){
			selectedFabricPrice = 0;
		}

		if(!productPreview.fabricProfile && !productPreview.madeToOrderFabric && productPreview.sizeProfile && productPreview.productGroup==='fabric' ){
			selectedFabricPrice = 0;
		}

		if(!productPreview.fabricProfile && productPreview.madeToOrderFabric && productPreview.productGroup==='fabric') {
			selectedFabricPrice = productPreview.madeToOrderFabric.price;
		}

		let consumedFabric: number = this._getConsumedFabric(productPreview);

		let selectedFinishPrice: number = this._getSelectedFinishPrice(
			productPreview,
			productPreview.finishProfileItemId?productPreview.finishProfileItemId:''
		);

		let lowestVDQTY = productPreview.volumeDiscountProfile?.volumeDiscountProfileItemList[
		productPreview.volumeDiscountProfile?.volumeDiscountProfileItemList.length - 1
			].minimumOrderQuantity;

		this._priceCalculation.selectedConsumedFabric = consumedFabric?consumedFabric:1;
		this._priceCalculation.finishPrice = selectedFinishPrice;
		this._priceCalculation.selectedFabricPrice = selectedFabricPrice;
		this._priceCalculation.productQuantity = lowestVDQTY!;
		this._priceCalculation.productVolumeDiscountProfile = productPreview.volumeDiscountProfile!;
		this._priceCalculation.isThisProductFabric = isThisProductFabric;
		this._priceCalculation.defaultProductPrice = productPreviousPrice;
		this._priceCalculation.selectedFabricVolumeDiscountProfile = productPreview.madeToOrderFabric?.volumeDiscountProfile? productPreview.madeToOrderFabric?.volumeDiscountProfile : {} as VolumeDiscountProfile;







		// setTimeout(() => {
		if(!isThisProductFabric) {
			this._priceCalculation.calculatePrice(false, true);
		}


		if(isThisProductFabric &&  productPreview.madeToOrderFabric) {
			this._priceCalculation.calculatePrice(false, true);
		}
		if(isThisProductFabric &&  !productPreview.madeToOrderFabric) {
			this._priceCalculation.makingCharges = 0;
			this._priceCalculation.calculatePrice(false, false);
		}

		// if(isThisProductFabric){
		// 	this._priceCalculation.calculatePrice(false, false);
		// }


		product.product.bulkDiscountedPrice = this._priceCalculation.storePrice;
		return this._priceCalculation.storePrice
		// }, 0);








	}
}
