import {Injectable} from '@angular/core';
import {RequestMapperService} from "../../request-mapper.service";
import {RaintreeResponse} from "../../raintree/interface/raintree-response";
import {CartTransmissionService} from "../../raintree/transmission/cart/cart-transmission.service";
import {GetCart} from "../interface/cart-get";
import {Cart} from "../interface/cart";
import {PrepareGetCartService} from "./prepare-get-cart.service";
import {GetAndSetCartDataInLocalstorageService} from "./set-cart-data-in-localstorage.service";

@Injectable({
  providedIn: 'root'
})
export class ClearAllCartService {

  constructor(
	  private _cartTransmission: CartTransmissionService,
	  private _prepareCartResponse: PrepareGetCartService,
	  private _etSetCartData: GetAndSetCartDataInLocalstorageService,
  ) { }

	public deleteAllCartItem(): void {
		this._cartTransmission.deleteAllCartItem(
			RequestMapperService.DELETE_ALL_CART_ITEM,
			{} as Cart,
			() => {
			},
			() => {
			},
			(response: RaintreeResponse) => {
				localStorage.removeItem("cartData");
				this._etSetCartData.openCartModal.next(false);
				this.getCartData();
			},
			(error: string) => {
			},
			() => {
			}
		)
	}


	private getCartData(): void {

		this._cartTransmission.getCartItem(
			RequestMapperService.GET_CART_ITEM,
			() => {
			},
			() => {
			},
			(response: GetCart[]) => {
				// console.log(response);
				if (response.length > 0) {

					let data = this._prepareCartResponse.prepareCartList(response);
					this.setCartDataInLocal(data);
					this._etSetCartData.cartResponse.next(data);

				} else {
					// debugger
					this._etSetCartData.cartResponse.next([]);
					this._prepareCartResponse.prepareCartList([]);
				}
			},
			(): void => {
			},
			(): void => {
			}
		)
	}

	public setCartDataInLocal(data: Cart[]) {
		localStorage.removeItem("cartData");
		localStorage.setItem("cartData", JSON.stringify(data));
		this._etSetCartData.cartResponse.next(data)
	}

}
