import {Injectable} from '@angular/core';
import {Cart} from 'src/app/cart/interface/cart';
import {GetCart, GetCartSelectedFinishProfileItemList, SelectedFabric} from 'src/app/cart/interface/cart-get';
import {CartCalculationService} from 'src/app/cart/service/cart-calculation.service';
import {StorePriceService} from './store-price.service';
import {VolumeDiscountProfileItem} from 'src/app/product-details/interface/volume-discount-profile-item';
import {CartItemInStockCalculationService} from './cart-item-in-stock-calculation.service';
import {MadeToOrderProfile} from 'src/app/product-details/interface/made-to-order-profile';
import {CustomSizeProfile} from 'src/app/product-details/interface/custom-size-profile';

@Injectable({
	providedIn: 'root'
})
export class CheckoutPrepareCartResponseService {

	constructor(
		private _cartCalculation: CartCalculationService,
		private _storingPrice: StorePriceService,
		private _cartItemInStockCalciation:CartItemInStockCalculationService

	) { }
	public storeCartItems:GetCart[] = []
	public prepareCheckoutList(data: GetCart[] | any ): Cart[] {
		this.storeCartItems = data;
		let sendPreparedData: Cart[] = [];

		data.forEach((elm: GetCart) => {
			let cartContainer: Cart = {} as Cart;
			cartContainer.finishedProductId = elm.finishedProductPreview ? elm.finishedProductPreview!.id : 0;
			cartContainer.fabricProductId = elm.fabricProductPreview ? elm.fabricProductPreview!.id : 0;
			cartContainer.selectedFabricProfileItemId = elm.selectedFabric ? elm.selectedFabric.product!.id : 0;
			// debugger

			cartContainer.selectedFabric = elm.selectedFabric ? elm.selectedFabric : {} as SelectedFabric;

			cartContainer.fabricName = elm.selectedFabric ? elm.selectedFabric!.product.name : '';

			cartContainer.selectedFinishList = elm.selectedFinishList ? elm.selectedFinishList : [];
			cartContainer.selectedFinishProfileItemId = this._prepareSelectedFinisedId(elm.selectedFinishList);
			cartContainer.selectedSizeProfileOptionId = elm.selectedSizeOption ? elm.selectedSizeOption!.id : 0;
			cartContainer.selectedSizeOption = elm.selectedSizeOption ? elm.selectedSizeOption : undefined;
			cartContainer.productGroup = elm.productGroup;
			cartContainer.orderType = elm.orderType;
			cartContainer.quantity = elm.quantity;
			cartContainer.unit = elm.unit;
			cartContainer.makingCharge = elm.makingCharge;
			cartContainer.id = elm.id;
			cartContainer.price = this._getPrice(elm);
			cartContainer.copyPrice = this._getPrice(elm);
			cartContainer.fabricPriceWithOutVD = this._cartCalculation.fabricPriceWithoutVD();
			cartContainer.quantity_left = 500000; //TODO: Calculate later
			cartContainer.image =
				elm.finishedProductPreview ? elm.finishedProductPreview.product.heroImage : elm.fabricProductPreview!.product.heroImage;
			// debugger
			if( elm.finishedProductPreview){
				cartContainer.finishedProductPreview = elm.finishedProductPreview
			}
			else {
				cartContainer.fabricProductPreview = elm.fabricProductPreview
			}

			if (elm.finishedProductPreview) {

				cartContainer.productName = elm.finishedProductPreview.product.name;
				cartContainer.volumeDiscountProfile = elm.finishedProductPreview.product.volumeDiscountProfile ? elm.finishedProductPreview.product.volumeDiscountProfile : undefined;
				cartContainer.slug =  elm.finishedProductPreview.product.slug;
				cartContainer.volumeDiscountProfile = elm.finishedProductPreview.product.volumeDiscountProfile;
				cartContainer.passedVolumeDIscount = {} as VolumeDiscountProfileItem;
				cartContainer.volumeDiscountedPrice = 0;
				cartContainer.sku = elm.finishedProductPreview.product.sku;
			}
			else {

				cartContainer.productName = elm.fabricProductPreview!.product.name;
				cartContainer.volumeDiscountProfile = elm.fabricProductPreview!.product.volumeDiscountProfile ? elm.fabricProductPreview!.product.volumeDiscountProfile : undefined;
				cartContainer.slug =  elm.fabricProductPreview!.product.slug;
				cartContainer.volumeDiscountProfile = elm.fabricProductPreview!.product.volumeDiscountProfile;
				cartContainer.passedVolumeDIscount = {} as VolumeDiscountProfileItem;
				cartContainer.volumeDiscountedPrice = 0;
				cartContainer.sku = elm.fabricProductPreview!.product.sku;
			}

			if(elm.customSize && Object.keys(elm.customSize).length) {
				cartContainer.customSize = elm.customSize;
			}

			// debugger

			if(elm.orderType === 'MADE_TO_ORDER') {

				if(elm.finishedProductPreview) {
					cartContainer.madeToOrderProfile = elm.finishedProductPreview.product.madeToOrderProfile!;
				}

				if(elm.fabricProductPreview) {
					cartContainer.madeToOrderProfile = elm.fabricProductPreview.product.madeToOrderProfile!;
				}

			}
			if(elm.orderType === 'PRE_ORDER') {
				cartContainer.minQuantity =
				cartContainer.volumeDiscountProfile?cartContainer.volumeDiscountProfile.volumeDiscountProfileItemList[0].minimumOrderQuantity:1;

				cartContainer.volumeDiscount = {} as VolumeDiscountProfileItem;
			}
			else {
				if(elm.productGroup === 'fabric') {
					cartContainer.minQuantity = 0.5
				}
				else {
					cartContainer.minQuantity = 1
				}

			}

			if(elm.orderType === 'MADE_TO_ORDER') {

				if(elm.finishedProductPreview) {
					cartContainer.minQuantity = elm.finishedProductPreview.product.madeToOrderProfile? elm.finishedProductPreview.product.madeToOrderProfile.minimumOrderQuantity : 1;
				}

				if(elm.fabricProductPreview) {
					cartContainer.minQuantity = elm.fabricProductPreview.product.madeToOrderProfile? elm.fabricProductPreview.product.madeToOrderProfile.minimumOrderQuantity : 1;
				}

			}

			cartContainer.itemInStock = this._cartItemInStockCalciation.prepareCartItemInstockCalculation(cartContainer);


			cartContainer.finishDisplayName = elm.finishDisplayName?elm.finishDisplayName:'Finish';
			cartContainer.sizeDisplayName = elm.sizeDisplayName?elm.sizeDisplayName:'Size';

			sendPreparedData.push(cartContainer);
			cartContainer = {} as Cart;
		})
		// debugger
		// console.log(sendPreparedData)
		return sendPreparedData
	}

	private _prepareSelectedFinisedId(data: GetCartSelectedFinishProfileItemList[]): string {
		// debugger
		// console.log(data);
		if (!data[0]) return ''

		let id: string[] = [];
		data.forEach((elm: GetCartSelectedFinishProfileItemList) => {
			id.push(elm.id + '');
		})
		return id.join(",")
	}

	private _getPrice(data: GetCart): number {

		this._cartCalculation.selectedFinishForCartCalc = data.selectedFinishList;

		let madeToOrderConsumedFabric = 0;

		if (data.finishedProductPreview) {
			madeToOrderConsumedFabric = data.finishedProductPreview.product.madeToOrderProfile?.consumedFabric||1;
		}
		else {
			madeToOrderConsumedFabric = data.fabricProductPreview!.product.madeToOrderProfile?.consumedFabric||1;
		}

		if(data.customSize && Object.keys(data.customSize).length) {
			// this._cartCalculation.selectedConsumedFabric = 1;
			this._cartCalculation.selectedConsumedFabric = madeToOrderConsumedFabric;

			this._cartCalculation.customSizeProfile = data.fabricProductPreview ?
			data.fabricProductPreview.product.customSizeProfile!:
			data.finishedProductPreview?.product.customSizeProfile!;

		this._cartCalculation.hasThisProductCustomSize = true;
		}
		else{
			this._cartCalculation.selectedConsumedFabric =
			data.selectedSizeOption ? data.selectedSizeOption.consumedFabric : 0;

			this._cartCalculation.customSizeProfile = {} as CustomSizeProfile;
            this._cartCalculation.hasThisProductCustomSize = false;
		}

		// this._cartCalculation.selectedConsumedFabric =  data.selectedSizeOption?data.selectedSizeOption.consumedFabric:0;
		this._cartCalculation.defaultProductPrice =
			data.finishedProductPreview ? data.finishedProductPreview.product.price : data.fabricProductPreview!.product.price;
			this._cartCalculation.selectedFabricItem =
			data.selectedFabric?data.selectedFabric.product:undefined;
		// debugger
		this._cartCalculation.makingCharge = data.makingCharge?data.makingCharge:0;
		this._cartCalculation.swatchProduct = data.productGroup === 'swatch';

		if (data.finishedProductPreview) {
			this._cartCalculation.madeToOrderProfile = data.finishedProductPreview.product.madeToOrderProfile?data.finishedProductPreview.product.madeToOrderProfile:{} as MadeToOrderProfile;
		}
		else this._cartCalculation.madeToOrderProfile =  data.fabricProductPreview!.product.madeToOrderProfile?data.fabricProductPreview!.product.madeToOrderProfile:{} as MadeToOrderProfile;

		// console.log(this._cartCalculation.cartPriceCalculation())
		return this._cartCalculation.cartPriceCalculation()
	}

	public prepareTotalQTY(){
		// debugger
		let totalQTY = 0
		this.storeCartItems.forEach((cart: GetCart)=>{
			totalQTY += cart.quantity;
		});
		this._storingPrice.storedPrice.totalQuantity = totalQTY;
	}



}
