import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JWTService} from 'src/app/authentication/jwt.service';
import {PasteboxService} from 'src/app/services/pastebox.service';
import {HttpErrorHandlerService} from '../../http-error-handler.service';
import {TransmissionService} from '../transmission.service';
import {CustomerProfileResponse} from '../../interface/customer-profile/customer-profile-response';
import {Customer} from '../../interface/customer-profile/customer';
import {RaintreeResponse} from '../../interface/raintree-response';

@Injectable({
	providedIn: 'root'
})
export class CustomerProfileTransmissionService extends TransmissionService {

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
		_pastebox: PasteboxService
	) {
		super(_http, _httpErrorHandler, _jwt, _pastebox);
	}
	getCustomerInfo(
		url: string,
		onPreExecute: () => void,
		onPostExecute: (response: Customer) => void,
		onSuccess: (response: Customer) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executeGetPayload<CustomerProfileResponse, Customer>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'customer',
			true
		);
	}

	updtateCustomerInfo(
		url: string,
		payload: Customer,
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executePatchPayload(
			url,
			payload,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			true
		);
	}
}
