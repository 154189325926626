import {Injectable} from '@angular/core';
import {BmxToastService} from 'bmx-toast';
import {SpecificPosition} from 'bmx-toast/lib/toast/interface/toast';

@Injectable({
	providedIn: 'root'
})
export class ToastMessageService {

	constructor(
		public _toastService: BmxToastService
	) {
	}


	public success(heading: string, para: string,position?:SpecificPosition, timeout: number = 3000): void {
		this._toastService.generate({
			type: 'success',
			toastHeading: heading,
			toastText: para,
			timeout: timeout,
			position:  position?position:'top-right',
			closeIcon: true,
			autoClose: true,
			progressbar: true
		});
	}

	public error(heading: string, para: string,position?:SpecificPosition): void {
		this._toastService.generate({
			type: 'error',
			toastHeading: heading,
			toastText: para,
			timeout: 3000,
			position: position?position:'top-right',
			closeIcon: true,
			autoClose: true,
			progressbar: true,
			preventDuplicate: true
		});
	}

	public warning(heading: string, para: string): void {
		this._toastService.generate({
			type: 'warning',
			toastHeading: heading,
			toastText: para,
			timeout: 3000,
			position: 'top-right',
			closeIcon: true,
			autoClose: true,
			progressbar: true,
			preventDuplicate: true
		});
	}

	public info(heading: string, para: string): void {
		this._toastService.generate({
			type: 'info',
			toastHeading: heading,
			toastText: para,
			timeout: 3000,
			position: 'top-right',
			closeIcon: true,
			autoClose: true,
			progressbar: true
		});
	}
}
