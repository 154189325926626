import {Injectable} from '@angular/core';
import {Price} from '../interface/price';

@Injectable({
	providedIn: 'root'
})
export class StorePriceService {

	constructor() { }
	public storedPrice = {} as Price;


}
