import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MobileNavigationComponent} from './mobile-navigation/mobile-navigation.component';
import {ComponentsLibraryModule} from "../../components-library/components-library.module";
import {DesktopNavigationModule} from "../desktop-navigation/desktop-navigation.module";
import {MobileCategoryNavComponent} from './mobile-category-nav/mobile-category-nav.component';
import {RouterModule} from "@angular/router";
import {FabricNavSectionMobileComponent} from './fabric-nav-section-mobile/fabric-nav-section-mobile.component';
import {FinishNavSectionMobileComponent} from './finish-nav-section-mobile/finish-nav-section-mobile.component';
import {NavSectionWithImageMobileComponent} from './nav-section-with-image-mobile/nav-section-with-image-mobile.component';
import {ForexMobileComponent} from './forex-mobile/forex-mobile.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";


@NgModule({
	declarations: [
		MobileNavigationComponent,
		MobileCategoryNavComponent,
		FabricNavSectionMobileComponent,
		FinishNavSectionMobileComponent,
		NavSectionWithImageMobileComponent,
  ForexMobileComponent
	],
	imports: [
		CommonModule,
		ComponentsLibraryModule,
		DesktopNavigationModule,
		RouterModule,
		ReactiveFormsModule,
		FormsModule
	],
	exports: [
		MobileNavigationComponent
	]
})
export class MobileNavigationModule {
}
