<fb-notification-bar (closeNotificatonNav)="closeNotification()" *ngIf="isOpen"></fb-notification-bar>

<ng-container  *ngIf="!isMobileDevice">

<fb-static-top-navigation [dataStaticLeftMenu]="dataStaticLeftMenu" [dataStaticRightMenu]="dataStaticRightMenu">
</fb-static-top-navigation>

	<fb-category-nav-desktop [dataCategoryMenu]="dataMenuList" [craftData]="craftMoldedData"
							[ClusterData]="clusterMoldedData" [designerCollaboration]="designerMoldedData"
							[MaterialDataCraft]='navMaterialData' [patternData]="navPatternData" [colorData]="navColorData"
							[fabricCraftData]="fabricCraft" [finishedData]="finishNav">
	</fb-category-nav-desktop>
</ng-container>
