import {Injectable} from '@angular/core';
import {ProductPreview} from "../product/product-listing/interface/product-preview";
import {ProductListing} from "../raintree/interface/product-listing/product-listing";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class PrepareSearchDataService {

	private _paramSubscription: Subscription = {} as Subscription;

	constructor(
		private _route: ActivatedRoute
	) {
	}

	public prepareSearchData(searchData: ProductPreview[]): ProductListing[] {
		let listing: ProductListing[] = [];
		searchData.forEach((data: ProductPreview, i: number): void => {
			listing.push({
				product: data,
				id: i,
				version: 1,
				gsm: 1,
				inWishlist: false
			})
		});

		return listing.sort((
			product1,
			product2
		) => product1.product.id - product2.product.id);
	}

	public prepareSearchParam(): string {
		let param: string | null = '';
		this._paramSubscription = this._route.queryParamMap.subscribe(
			(paramMap) => {
				param = paramMap.get('search');
			}
		)
		return (param === null) ? '' : param;
	}

	public unSubscribe() {
		if(this._paramSubscription) {
			this._paramSubscription.unsubscribe();
		}
	}
}
