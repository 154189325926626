<div class="page-container">
	<h1 class="ethical_head">
		<span>
			Follow anuprerna_atelier <br>
			on Instagram
		</span>
	</h1>
	<div
		class="iframe_wrapper">
		<script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script>
		<iframe src="https://cdn.lightwidget.com/widgets/8621f6c3d638570d9a94d40512f837f1.html" scrolling="no"
				allowtransparency="true" class="lightwidget-widget"
				style="width:100%;border:0;overflow:hidden;"></iframe>
	</div>
</div>
