import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Address} from '../interface/address';
// import {CountryList} from '../interface/countryList';
import {HttpClient} from '@angular/common/http';
import {RequestMapperService} from "../../request-mapper.service";
import {CountryList} from '../interface/countryList';
import {StoreForexService} from 'src/app/navigation/service/store-forex.service';

@Injectable({
	providedIn: 'root'
})
export class AddressFunctionService {

	public selectedAddress: Subject<Address> = new Subject<Address>();
	public addShipmentButtonClicked: boolean = false;

	constructor(
		private _http: HttpClient,
		private storeForex: StoreForexService,

	) {
	}

	public methSelectedAddress(data: Address): void {
		this.selectedAddress.next(data);
	}

	public fetchCountryList(): Observable<CountryList[]> {
		return this._http.get<CountryList[]>(RequestMapperService.COUNTRY_LIST);
	}

	public changeCurrencyBasedOnAddressCountry(country: string): void {

		let isDefaultCurrencyINR = this.storeForex.selectedDefaultCurrencyName === 'inr';

		if(isDefaultCurrencyINR && country!=='India') {
			this.storeForex.currencySubject.next('usd');
		}

		else if(!isDefaultCurrencyINR && country!=='India') {
			this.storeForex.currencySubject.next('usd');
		}
		else if(isDefaultCurrencyINR && country==='India') {
			this.storeForex.currencySubject.next('inr');
		}
		else {
			this.storeForex.currencySubject.next('inr');
		}

	}
}
