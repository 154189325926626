import {Injectable} from '@angular/core';
import {Bloomsight} from "./interface/bloomsight";
import {BloomsightEventService} from "@bloomscorp/ngx-bloomsight";

@Injectable({
	providedIn: 'root'
})
export class BloomsightService implements Bloomsight {

	constructor(
		private _bloomsight: BloomsightEventService
	) {
	}

	public trackSimpleEvent(eventToken: string, label: string): void {
		try {
			this._bloomsight.resolveSimpleEvent(eventToken, label);
		}
		catch (ex) {
			console.error("bloomsight event error", ex);
		}
	}

	public trackDataEvent(eventToken: string, data: { [key: string]: any }, label: string): void {
		try {
			this._bloomsight.resolveDataEvent(eventToken, data, label);
		}
		catch (ex) {
			console.error("bloomsight event error", ex);
		}
	}
}
