<!-- <p>static-top-navigation works!</p> -->
<div class="static_logo_container">
	<div class="col col_left">
		<!-- <a *ngFor="let leftSideMenu of dataStaticLeftMenu ; let i = index">
			<button class="menu_static">
				{{leftSideMenu.text}}
			</button>
		</a> -->
		<div class="menu_static dd_cont">
			<button class="menu_static">
				ABOUT US
			</button>
			<div class="col_left_dropdown craft_wrapper">
				<ul>
					<a href="{{anuprernaBlogLink}}about-us/about-the-brand/56485" target="_blank">
						<li>
							About The Brand
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}about-us/about-our-impact/57938" target="_blank">
						<li>
							About Our Impact
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}about-us/about-the-founder/57073" target="_blank">
						<li>
							About the Founder
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}about-us/about-anuprerna-studio/53794" target="_blank">
						<li>
							About the studio
						</li>
					</a>
					<a  routerLink="/contact">
						<li>
							Contact Us
						</li>
					</a>

				</ul>
			</div>
		</div>

		<div class="menu_static dd_cont">
			<button class="menu_static">
				WHOLESALE
			</button>
			<div class="col_left_dropdown craft_wrapper">
				<ul>
					<a href="{{anuprernaBlogLink}}wholesale/wholesale-production-preorder/59335" target="_blank">
						<li>
							Wholesale Production process
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}wholesale/order-fabric-swatches/59195" target="_blank">
						<li>
							Order Fabric Swatches
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}wholesale/natural-sustainable-custom-dyeing/59105" target="_blank">
						<li>
							Custom Sustainable Dyeing
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}wholesale/custom-clothing-accessories-homewares/59339" target="_blank">
						<li>
							Custom Clothing, Accessories, & Homewares
						</li>
					</a>
				</ul>
			</div>
		</div>

		<div class="menu_static dd_cont">
			<button class="menu_static">
				CAREGUIDE
			</button>
			<div class="col_left_dropdown craft_wrapper">
				<ul>
					<a href="{{anuprernaBlogLink}}care-guide/how-to-nurture-your-natural-dyed-clothing/126408" target="_blank">
						<li>
							Natural Fabric CareGuide
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}care-guide/handmade-textiles-care-guide/108968" target="_blank">
						<li>
							Natural Dye CareGuide
						</li>
					</a>
				</ul>
			</div>
		</div>
	</div>
	<a>
		<button class="menu_static hamburger" (click)="mobileMenuOpenClose(isMobileMenuOpen)">
			<ng-container *ngIf="isMobileMenuOpen">
				<span class="material-symbols-outlined">
					close
				</span>
			</ng-container>
			<ng-container *ngIf="!isMobileMenuOpen">
				<span class="material-symbols-outlined">
					menu
				</span>
			</ng-container>
		</button>
	</a>
	<div class="col col_center for_logo" (click)="closeMobileMenu()">
		<a routerLink="/">
			<img class="logo_white" src="assets/img/logo_white.svg" alt="anuperna logo white" />
			<img class="logo_black" src="assets/img/logo_black.svg" alt="anuperna logo black">
			<span>ANUPRERNA</span></a>
	</div>
	<div class="col col_right">

		<fb-forex></fb-forex>

		<fb-search-navigation class="desk_hide"></fb-search-navigation>


		<ng-container *ngFor="let rightSideMenu of dataStaticRightMenu ; let i = index">

			<ng-container *ngIf="!((rightSideMenu.text).toLowerCase()==='cart') &&
			!((rightSideMenu.text).toLowerCase()==='contact us')">
				<ng-container *ngIf="!rightSideMenu.auth">
						<a routerLink="{{rightSideMenu.link}}" class="{{rightSideMenu.icon=='person'?'login':''}}">
							<button class="menu_static pos_rel">
								<strong *ngIf="((rightSideMenu.text).toLowerCase()==='wishlist') && wishlistCount>0"
										class="absolute top-[-12px] right-1 md:right-3 count">
									{{wishlistCount}}
								</strong>
								<span class="image_icon_cont">
								<img class="dark_image" src="{{rightSideMenu.imageLink}}"
									 alt="{{rightSideMenu.text}}" />
								<img class="light_image" src="{{rightSideMenu.imageLinkWhite}}"
									 alt="{{rightSideMenu.text}}" />
							</span>

								<ng-container>
									<div>{{rightSideMenu.text}}</div>
								</ng-container>
							</button>
						</a>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="((rightSideMenu.text).toLowerCase()==='cart')">
				<ng-container *ngIf="!rightSideMenu.auth">
					<!-- <a routerLink="{{rightSideMenu.link}}"> -->
					<button class="menu_static pos_rel" (click)="[rightMenuAction(rightSideMenu.text)];">

						<strong *ngIf="((rightSideMenu.text).toLowerCase()==='cart') &&cartCount>0"
							class="cart_count count">{{cartCount}}
						</strong>

						<!-- <span class="material-symbols-outlined">
								{{rightSideMenu.icon}}
						</span> -->
						<span class="image_icon_cont">
							<img class="dark_image" src="{{rightSideMenu.imageLink}}" alt="{{rightSideMenu.text}}" />
							<img class="light_image" src="{{rightSideMenu.imageLinkWhite}}"
								alt="{{rightSideMenu.text}}" />
						</span>
						<ng-container>
							<div>{{rightSideMenu.text}}</div>
						</ng-container>
					</button>
					<!-- </a> -->
				</ng-container>
			</ng-container>

			<ng-container *ngIf="isLoggedIn && rightSideMenu.auth">
				<ng-container *ngIf="!((rightSideMenu.text).toLowerCase()==='cart')">
					<a routerLink="{{rightSideMenu.link}}">
						<button
							class="menu_static pos_rel {{rightSideMenu.text.toLowerCase()=='logout'?'logout_button':''}}"
							(click)="[logout((rightSideMenu.text).toLowerCase()),rightMenuAction(rightSideMenu.text)];">

							<span class="material-symbols-outlined">
								{{rightSideMenu.icon}}
							</span>
							<ng-container>
								<div>{{rightSideMenu.text}}</div>
							</ng-container>
						</button>
					</a>
				</ng-container>
			</ng-container>


		</ng-container>

		<!-- <a>
			<button class="menu_static" (click)="logout()" *ngIf="isLoggedIn">
				<span class="material-symbols-outlined">logout</span>
				<div>Log out</div>
			</button>
		</a> -->

	</div>
</div>


<div class="cart_cont" *ngIf="openCartPop">
	<fb-cart (closeCartTrigger)="openCartPop =false;" (updateList)="refreshCartList()"
		(cartLength)="cartLengthUpdate($event)" [cartData]="fetchedCartData"></fb-cart>
</div>
