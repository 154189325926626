<!-- <p>navigation works!</p> -->
<div class="desk_nav">
	<fb-desktop-navigation [dataMenuList]="navigationData" [dataStaticLeftMenu]="dataStaticLeftMenu"
						   [dataStaticRightMenu]="dataStaticRightMenu"></fb-desktop-navigation>
</div>

<div class="mobile_nav">
	<fb-mobile-navigation [dataMenuList]="navigationData" [dataStaticLeftMenu]="dataStaticLeftMenu"
						  [dataStaticRightMenu]="dataStaticRightMenu"></fb-mobile-navigation>
</div>
