import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {JWTResponse} from 'src/app/authentication/model/jwtresponse';
import {ConfigurationService} from 'src/app/services/configuration.service';
import {ConstantService} from 'src/app/services/constant.service';

@Injectable({
	providedIn: 'root',
})
export class HttpErrorHandlerService {
	constructor() {}

	private static prepareErrorMessage(error: HttpErrorResponse): string {
		if (!ConfigurationService.PRODUCTION) console.trace();

		let errorMessage: string;

		if (error.error instanceof ErrorEvent)
			errorMessage = error.error.message;
		else if (error.status === 0)
			errorMessage = ConstantService.COULD_NOT_CONNECT_TO_SERVER_ERROR;
		else errorMessage = error.error.message;

		return errorMessage;
	}

	public interceptJWTError(
		error: HttpErrorResponse
	): Observable<JWTResponse> {
		return throwError(HttpErrorHandlerService.prepareErrorMessage(error));
	}

	public intercept(error: HttpErrorResponse): Observable<any> {
		return throwError(HttpErrorHandlerService.prepareErrorMessage(error));
	}
}
