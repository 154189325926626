import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {MoldedContent} from '../interface/molding/molded-content';
import {Router} from "@angular/router";
import {MoldedContentSubCategory} from '../interface/molding/monded-content-sub-category';
import {isPlatformBrowser} from '@angular/common';
import {MoldedContentSegmantCategory} from "../interface/molding/molded-content-segment-category";

@Component({
	selector: 'fb-finish-nav-section',
	templateUrl: './finish-nav-section.component.html',
	styleUrls: ['./finish-nav-section.component.scss']
})
export class FinishNavSectionComponent {
	@Input() dataFinish: MoldedContent = {} as MoldedContent;
	public hoveredImage: string = '';
	public hoveredImageSubCategory: MoldedContentSubCategory = {} as MoldedContentSubCategory;
	public isBrowser: boolean = false;

	constructor(
		private _router: Router,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	private _prepareUrl(param: string): string {
		if(param.includes('-'))
			return param.toLowerCase().replace(/\s+/g, '');
		return param.toLowerCase().replace(/\s+/g, '-');
	}

	public ngOnChanges(): void {
		// debugger
		this.dataFinish.category[0].segmentCategory[0].hoverActive = true;
		this.hoveredImage = this.dataFinish.category[0].segmentCategory[0].image;
		this.hoveredImageSubCategory = this.dataFinish.category[0].segmentCategory[0];
	}

	public hoverUpdateImage(subCategory: MoldedContentSubCategory, image: string, segIndex: number, subIndex: number): void {
		this.hoveredImage = image;
		this.dataFinish.category[0].segmentCategory[0].hoverActive = false;

		this.hoveredImageSubCategory = subCategory;
	}

	public hoverleaveImage(image: string): void {
		this.hoveredImage = image;
		this.dataFinish.category[0].segmentCategory[0].hoverActive = true;
	}

	public getRouterParams(key: string, value: string) {
		return { ['segment-' + key]: value };
	}

	public navigate(
		productTypeLink: string,
		paramKey: string,
		paramValue: string
	): void {

		this._router.navigate([productTypeLink,],
			{ queryParams: { [paramKey]: paramValue } }).then((): void => {
				if (this.isBrowser) {
					window.location.reload()
				}

			});
	}

	public generateSegmentRedirectionLink(url: string, segment: string, subCategory: string): string {
		return  url +'?'+ this._prepareUrl(segment) + '='+ this._prepareUrl(subCategory);
	}
}
