<a class="for_search" *ngIf="isMobile">
    <button class="menu_static" (click)="openSearchBar()">
				<span class="image_icon_cont">
					<img ngSrc="./assets/img/search.svg" alt="search" height="16" width="16"/>
					<div>Search</div>
				</span>
    </button>
    <div class="search_input_container" *ngIf="isSearchModalOpen">
        <div class="input_container_inner">
            <img src="./assets/img/search.svg" alt="search" (click)="triggerSearch()"/>
            <input type="text" name="search"
                   placeholder="What are you looking for??"
                   (keyup.enter)="triggerSearch()"
                   (input)="searchInput($event)"/>
        </div>
    </div>
    <div
            *ngIf="isSearchModalOpen"
            class="search_input_container_overlay"
            (click)="openSearchModal()"></div>
</a>


<div class="menu_ind for_search" *ngIf="isDesktop">
    <a (click)="openSearchBar()">
        <span class="desk_search_icon">Search</span>
        <img src="./assets/img/search.svg" alt="search" />
    </a>
    <div class="search_input_container" *ngIf="isSearchModalOpen">
        <div class="input_container_inner">
            <img src="./assets/img/search.svg" alt="search" (click)="triggerSearch()"/>
            <input type="text" name="search"
                   placeholder="What are you looking for?"
                   (keyup.enter)="triggerSearch()"
                   (input)="searchInput($event)"
					autocomplete="off"/>
        </div>
    </div>
    <div *ngIf="isSearchModalOpen" class="search_input_container_overlay" (click)="openSearchModal()"></div>
</div>
