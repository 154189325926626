import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SingleCarouselComponent} from './single-carousel/single-carousel.component';
import {CarouselModule} from 'ngx-owl-carousel-o';


@NgModule({
  declarations: [
    SingleCarouselComponent
  ],
  imports: [
    CommonModule,
	CarouselModule
  ],
  exports:[
	SingleCarouselComponent
  ]
})
export class SingleCarouselModule { }
