import {Component} from '@angular/core';

@Component({
  selector: 'fb-homepag-instagram-post',
  templateUrl: './homepag-instagram-post.component.html',
  styleUrls: ['./homepag-instagram-post.component.scss']
})
export class HomepagInstagramPostComponent {

}
