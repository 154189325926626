import {Injectable} from '@angular/core';
import {BlogPreview} from '../interface/story-preview';
import {Subject} from 'rxjs';
import {BlogTransmissionService} from 'src/app/raintree/transmission/blog-response/blog-transmission.service';
import {RequestMapperService} from 'src/app/request-mapper.service';

@Injectable({
	providedIn: 'root'
})
export class FetchBlogListService {

	constructor(
		private _blogtransmission: BlogTransmissionService
	) { }

	public blogDataFromNav: Subject<BlogPreview[]> = new Subject();
	public storeBlogList:BlogPreview[] = [];

	public getBlogList(): void {

		this._blogtransmission.getBlogList(

			RequestMapperService.GET_BLOG_LIST,
			() => { },
			(response: BlogPreview[]): void => {
			},
			(response: BlogPreview[]): void => {
				let slicedBlogList: BlogPreview[] = response.slice(0, 5);
				// console.log(slicedBlogList);
				this.storeBlogList = response.slice(0, 5);
				this.blogDataFromNav.next(slicedBlogList)
				// debugger
			},
			(error: string): void => {
			},
			(): void => {
			},

		)
	};
}
