import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CartComponent} from './cart/cart.component';
import {CartItemComponent} from './cart-item/cart-item.component';
import {RouterModule} from '@angular/router';
import {PipeModule} from "../pipe/pipe.module";
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
  declarations: [
    CartComponent,
    CartItemComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        PipeModule,
        MatTooltipModule
    ],
  exports:[
	CartComponent

  ]
})
export class CartModule { }
