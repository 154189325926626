import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {MoldedMeterialNav} from "../../desktop-navigation/interface/molding/molded-meterial-nav";
import {MoldedPatternNav} from "../../desktop-navigation/interface/molding/molded-pattern-nav";
import {MoldedColorNav} from "../../desktop-navigation/interface/molding/molded-color-nav";
import {MoldedContent} from "../../desktop-navigation/interface/molding/molded-content";
import {Router} from "@angular/router";
import {isPlatformBrowser} from '@angular/common';
import {MoldedContentSegmantCategory} from "../../desktop-navigation/interface/molding/molded-content-segment-category";

@Component({
    selector: 'fb-fabric-nav-section-mobile',
    templateUrl: './fabric-nav-section-mobile.component.html',
    styleUrls: ['./fabric-nav-section-mobile.component.scss']
})
export class FabricNavSectionMobileComponent {

    @Input() material: MoldedMeterialNav[] = [];
    @Input() pattern: MoldedPatternNav[] = [];
    @Input() color: MoldedColorNav[] = [];
    @Input() fabricCraft: MoldedContent = {} as MoldedContent;
    public selectedAccordion: string = 'crafts';
    public copySelectedAccordion: string = '';
	public isBrowser: boolean = false;
    constructor(
        private _router: Router,
		@Inject(PLATFORM_ID) private _platformId: Object
    ) {
		this.isBrowser = isPlatformBrowser(this._platformId);
    }

	private _prepareUrl(param: string): string {
		if(param.includes('-'))
			return param.toLowerCase().replace(/\s+/g, '');
		return param.toLowerCase().replace(/\s+/g, '-');
	}

	public navigateCategoryRoute(
		productTypeLink: string,
		segment: MoldedContentSegmantCategory
	): void {
		const segments: string[] = segment.segmentCategory
			.map((sub) => sub.subCategoryName);
		this.navigate(
			productTypeLink,
			segment.segmentCategoryName,
			segments.join()
		);
	}

    public getRouterParams(key: string, value: string) {
        return {[this._prepareUrl(key)]: this._prepareUrl(value)};
    }

    public navigate(
        productTypeLink: string,
        paramKey: string,
        paramValue: string
    ): void {

        this._router.navigate([productTypeLink,],
            {queryParams: {[this._prepareUrl(paramKey)]: this._prepareUrl(paramValue)}})
			.then((): void => {
				if (this.isBrowser) {
					window.location.reload();
				}

        });
    }

    public triggerAccordion(str: string): void {
        if (this.copySelectedAccordion === str) {
            this.selectedAccordion = '';
            this.copySelectedAccordion = ''
            return
        }
        this.copySelectedAccordion = str;
        this.selectedAccordion = str;
    }


}
