import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JWTService} from 'src/app/authentication/jwt.service';
import {PasteboxService} from 'src/app/services/pastebox.service';
import {HttpErrorHandlerService} from '../../http-error-handler.service';
import {TransmissionService} from '../transmission.service';
import {Discount} from 'src/app/checkout/interface/discount';
import {DiscountResponse} from '../../interface/discount-response/discount-response';

@Injectable({
  providedIn: 'root'
})
export class DiscountTransmissionService extends TransmissionService{

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
		_pastebox: PasteboxService
	) {
		super(_http, _httpErrorHandler, _jwt, _pastebox);
	}

	public getDiscount(
		url: string,
		onPreExecute: () => void,
		onPostExecute: (response: Discount[]) => void,
		onSuccess: (response: Discount[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executeGetPayload<DiscountResponse, Discount[]>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'discountList',
			true
		);
	}
}
