import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Settings} from 'src/app/profile/interface/settings';
import {SettingsDataService} from 'src/app/services/settings-data.service';

@Component({
  selector: 'fb-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements OnInit {

	@Output() closeNotificatonNav = new EventEmitter<boolean>()

	constructor(
		private _settingsService : SettingsDataService
	){

	}

	public notification: Settings = {} as Settings;
	public close():void {
		this.closeNotificatonNav.emit(false)
	}

	public ngOnInit():void {
		this._settingsService.topNotification.subscribe((notification: Settings)=>{
			this.notification = notification;
		})
	}
}
