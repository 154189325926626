<fb-notification-bar (closeNotificatonNav)="closeNotification()" *ngIf="isOpen"></fb-notification-bar>
<!-- <ng-container *ngIf="isMobileDevice"> -->
<ng-container class="mobile_and_tab_show">
<fb-static-top-navigation [dataStaticLeftMenu]="dataStaticLeftMenu" [dataStaticRightMenu]="dataStaticRightMenu">
</fb-static-top-navigation>


	<fb-mobile-category-nav [dataCategoryMenu]="dataMenuList" [craftData]="craftMoldedData"
		[ClusterData]="clusterMoldedData" [designerCollaboration]="designerMoldedData"
		[MaterialDataCraft]='navMaterialData' [patternData]="navPatternData" [colorData]="navColorData"
		[fabricCraftData]="fabricCraft" [finishedData]="finishNav" [dataStaticLeftMenu]="dataStaticLeftMenu">

	</fb-mobile-category-nav>
</ng-container>


<!--<fb-category-nav-desktop [dataCategoryMenu]="dataMenuList" [craftData]="craftMoldedData"-->
<!--						 [ClusterData]="clusterMoldedData" [designerCollaboration]="designerMoldedData"-->
<!--						 [MaterialDataCraft]='navMaterialData' [patternData]="navPatternData" [colorData]="navColorData"-->
<!--						 [fabricCraftData]="fabricCraft" [finishedData]="finishNav">-->
<!--</fb-category-nav-desktop>-->
