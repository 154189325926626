import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {CategoryNavDesktopComponent} from './category-nav-desktop/category-nav-desktop.component';
import {DesktopNavigationComponent} from './desktop-navigation/desktop-navigation.component';
import {StaticTopNavigationComponent} from './static-top-navigation/static-top-navigation.component';
import {ComponentsLibraryModule} from 'src/app/components-library/components-library.module';
import {RouterModule} from '@angular/router';
import {CartModule} from 'src/app/cart/cart.module';
import {NavSectionWithImageComponent} from './nav-section-with-image/nav-section-with-image.component';
import {FabricNavSectionComponent} from './fabric-nav-section/fabric-nav-section.component';
import {FinishNavSectionComponent} from './finish-nav-section/finish-nav-section.component';
import {ForexComponent} from './forex/forex.component';
import {FormsModule} from "@angular/forms";
import {SearchNavigationComponent} from "./search-navigation/search-navigation.component";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
    declarations: [
        CategoryNavDesktopComponent,
        DesktopNavigationComponent,
        StaticTopNavigationComponent,
        NavSectionWithImageComponent,
        FabricNavSectionComponent,
        FinishNavSectionComponent,
        ForexComponent,
		SearchNavigationComponent
    ],
    imports: [
        CommonModule,
        ComponentsLibraryModule,
        RouterModule,
        CartModule,
        FormsModule,
        NgOptimizedImage,
		MatDialogModule
    ],
    exports: [
        DesktopNavigationComponent,
        StaticTopNavigationComponent,
    ]
})
export class DesktopNavigationModule {
}
