import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieInformationComponent } from './cookie-information/cookie-information.component';



@NgModule({
    declarations: [
        CookieInformationComponent
    ],
    exports: [
        CookieInformationComponent
    ],
    imports: [
        CommonModule
    ]
})
export class MiscellaneousModule { }
