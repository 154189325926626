import {Injectable} from '@angular/core';
import {TransmissionService} from "./transmission.service";
import {HttpClient} from "@angular/common/http";
import {HttpErrorHandlerService} from "../http-error-handler.service";
import {JWTService} from "../../authentication/jwt.service";
import {PasteboxService} from "../../services/pastebox.service";
import {RaintreeResponse} from "../interface/raintree-response";
import {IReview} from "../../review/interface/review";
import {RequestMapperService} from "../../request-mapper.service";
import {IReviewPayloadResponse} from "../interface/review-payload-response";
import {ConstantService} from "../../services/constant.service";
import {IReviewListPayloadResponse} from "../interface/review-list-payload-response";
import {IReviewStats} from "../../review/interface/review-stats";
import {IReviewStatsResponse} from "../interface/review-stats-response";

@Injectable({
	providedIn: 'root'
})
export class ReviewTransmissionService extends TransmissionService {

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
		_pastebox: PasteboxService
	) {
		super(
			_http,
			_httpErrorHandler,
			_jwt,
			_pastebox
		);
	}

	public addReview(
		payload: IReview,
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {
		this.executePostPayload<IReview>(
			RequestMapperService.ADD_REVIEW,
			payload,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			true
		);
	}

	public updateReview(
		payload: IReview,
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {
		this.executePatchPayload<IReview>(
			RequestMapperService.UPDATE_REVIEW,
			payload,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			true
		);
	}

	public getReview(
		reviewId: number,
		onPreExecute: () => void,
		onPostExecute: (response: IReview) => void,
		onSuccess: (response: IReview) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {
		this.executeGetPayload<IReviewPayloadResponse, IReview>(
			RequestMapperService.GET_REVIEW + '/' + reviewId,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.REVIEW_ATTRIBUTE_KEY,
			true
		);
	}

	public getReviewList(
		pageNo: number = 0,
		pageSize: number = 10,
		onPreExecute: () => void,
		onPostExecute: (response: IReview[]) => void,
		onSuccess: (response: IReview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {
		this.executeGetPayload<IReviewListPayloadResponse, IReview[]>(
			RequestMapperService.GET_REVIEW_LIST + `?pageNumber=${pageNo}&pageSize=${pageSize}`,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.REVIEW_LIST_ATTRIBUTE_KEY,
			false
		);
	}

	public getProductSpecificReviewList(
		productId: number,
		pageNo: number = 0,
		pageSize: number = 10,
		onPreExecute: () => void,
		onPostExecute: (response: IReview[]) => void,
		onSuccess: (response: IReview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {
		this.executeGetPayload<IReviewListPayloadResponse, IReview[]>(
			RequestMapperService.GET_PRODUCT_REVIEW_LIST + `/${productId}?pageNumber=${pageNo}&pageSize=${pageSize}`,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.REVIEW_LIST_ATTRIBUTE_KEY,
			false
		);
	}

	public getReviewStats(
		onPreExecute: () => void,
		onPostExecute: (response: IReviewStats) => void,
		onSuccess: (response: IReviewStats) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {
		this.executeGetPayload<IReviewStatsResponse, IReviewStats>(
			RequestMapperService.GET_REVIEW_STATS,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.REVIEW_STATS_ATTRIBUTE_KEY,
			false
		);
	}
}
