import {Injectable} from '@angular/core';
import {MoldedMeterialNav} from '../desktop-navigation/interface/molding/molded-meterial-nav';
import {NavProductFetchedData} from '../desktop-navigation/interface/nav-product-fetched-data';
import {NavProductMaterialFetched} from '../desktop-navigation/interface/nav-product-material-fetched';

@Injectable({
	providedIn: 'root'
})
export class PrepareMeterialNavService {

	constructor() { }

	public prepareMeterial(data: NavProductFetchedData[]): MoldedMeterialNav[] {

		// console.log(data);
		let materials: MoldedMeterialNav[] = [];

		if (data && data.length > 0) {
			data.forEach((elm: NavProductFetchedData) => {
				if (elm.materialId) {
					elm.materials!.forEach((material: NavProductMaterialFetched) => {
						if (elm.materialId) {
							materials.push({
								name: material.name,
								id: material.id
							})
						}
					})
				}

			})
			// debugger

			materials = [...new Map(materials.map(item => [item['name'], item])).values()]
			// console.log(materials);
		}


		return materials
	}
}
