import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JWTService} from 'src/app/authentication/jwt.service';
import {PasteboxService} from 'src/app/services/pastebox.service';
import {HttpErrorHandlerService} from '../../http-error-handler.service';
import {TransmissionService} from '../transmission.service';
import {Settings} from 'src/app/profile/interface/settings';
import {SettingsResponse} from '../../interface/settings-response/settings-response';

@Injectable({
  providedIn: 'root'
})
export class SettingsTransmissionService  extends TransmissionService{

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
        _pastebox: PasteboxService
	) {
		super(_http, _httpErrorHandler, _jwt, _pastebox);
	}

	public getSettingsList(
		url:string,
		onPreExecute: () => void,
		onPostExecute: (response: Settings[]) => void,
		onSuccess: (response: Settings[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	){
		// debugger
		this.executeGetPayload<SettingsResponse,Settings[]>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'settingsList',
			false
		);
	}
}
