<div class="cart_item">
	<!-- <img src="./assets/img/cartitem.png" /> -->

	<ng-container *ngIf="cartItem.productGroup === 'swatch'">
		<a class="image_wrapper" href="product/fabric-product/{{cartItem.slug}}">
			<div class="custom-border" [ngStyle]="{'background': 'url(' + cartItem.image + ')'}">
			</div>
		</a>
	</ng-container>
	<ng-container *ngIf="cartItem.productGroup != 'swatch'">
		<a class="image_wrapper" href="product/{{cartItem.productGroup}}-product/{{cartItem.slug}}"><img
				src="{{cartItem.image}}" /></a>
	</ng-container>

	<div class="cart_details">
		<!-- <p>{{cartItem.orderType}} , {{cartItem.minQuantity}}</p> -->
		<div class="cart_details_left">
			<!-- <span style="font-size: 10px;">
				{{cartItem.id}}
				{{cartItem.selectedFinishProfileItemId}}
				{{cartItem.id}}
			</span> -->
			<p class="name">
				{{(cartItem.productGroup === 'swatch'?'SWATCH - ':'')}}
				{{cartItem.productName! | truncketHtml:30}}
			</p>
			<div class="other_information">
				<p class="fabric_name" *ngIf="cartItem.fabricName">Fabric: {{cartItem.fabricName | truncketHtml:20}}</p>

				<p class="size" *ngIf="cartItem.selectedSizeOption">
					{{cartItem.sizeDisplayName?cartItem.sizeDisplayName:'Size'}}:
					{{cartItem.selectedSizeOption.label}}</p>

				<p class="size custom_size_cont_cart" *ngIf="cartItem.customSize">
					<!-- Custom: -->
					 {{(prepareCustomSizeLabel(cartItem.customSize || '') | truncketHtml:35)}}</p>
				<!-- <p class="finish" *ngIf="cartItem.selectedFinishProfileItemId">
					Finish: {{cartItem.selectedFinishProfileItemId.split(",").length}} Finish selected</p> -->

				<p class="finish" *ngIf="cartItem.selectedFinishProfileItemId">

					{{cartItem.finishDisplayName?cartItem.finishDisplayName:'Finish'}}:
					<ng-container *ngFor="let finish of cartItem.selectedFinishList; let i = index">
						{{finish.label}}<ng-container *ngIf="i!=(cartItem.selectedFinishList!.length - 1)">,</ng-container>
					</ng-container>
				</p>
			</div>
			<p class="price">
				{{preferredCurrency | uppercase}}

				<span class="{{cartItem.volumeDiscountedPrice?'previous_price':''}}">
					{{(cartItem.price || 0) | currencyConverter | number: '1.0-2'}}
				</span>

				<span *ngIf="cartItem.volumeDiscountedPrice">
					{{ (cartItem.volumeDiscountedPrice ? cartItem.volumeDiscountedPrice : 0) | currencyConverter | number: '1.0-2'}}
				</span>

				/ {{cartItem.productGroup==='swatch'?'UNIT':cartItem.unit}}</p>


			<!-- <p class="price prod_price" *ngIf="cartItem.volumeDiscountedPrice">

				{{storeForex.selectedCurrencyName}}
				{{ (cartItem.volumeDiscountedPrice ? cartItem.volumeDiscountedPrice : 0) | preferredCurrencyConverter | number: '1.0-2'}}
				/ {{cartItem.unit}}

			</p> -->


			<div class="quantity_cont">
				<span>Quantity</span>
				<div class="counter">
					<div (click)="decreaseQTY()" class="btn btn-minus">-</div>
					<div class="order-count">
						<input type="number" min="0" class="orderInp" [value]="quantity"
							(change)="changeQTYFromInput($event)" >
					</div>
					<div (click)="increaseQTY()" class="btn btn-plus">+</div>
				</div>
				<span class="hide_span">{{cartItem.itemInStock}}</span>
			</div>

		</div>
		<div class="cart_details_right">

			<span class="material-symbols-outlined" (click)="deleteCartItem(cartItem.id, true)">
				close
			</span>
			<div [matTooltip]="'Save for later'"
				  (click)="moveToWishlist()"
				  class="flex flex-col justify-center items-center text-[8px] text-center cursor-pointer">
				<img class="w-[25px]" src="assets/img/storyboard.svg" alt="">
				<p class="mt-1">Save for later</p>
			</div>
		</div>
	</div>
</div>
