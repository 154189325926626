import {Component, Input} from '@angular/core';
import {MoldedContent} from "../../desktop-navigation/interface/molding/molded-content";
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'fb-nav-section-with-image-mobile',
    templateUrl: './nav-section-with-image-mobile.component.html',
    styleUrls: ['./nav-section-with-image-mobile.component.scss']
})
export class NavSectionWithImageMobileComponent {

    @Input() moldedData: MoldedContent = {} as MoldedContent;
    @Input() categoryName = '';
    public hoveredImage = '';
    public copySelectedAccordion: string = '';
    public selectedAccordion: string = '';

	constructor(
		private _globalService: GlobalService,
	) {

	}

    public ngOnChanges(): void {
        // debugger
        if (this.moldedData.category && this.moldedData.category.length>0) {
            this.moldedData.category[0].segmentCategory[0].hoverActive = true;
            this.hoveredImage = this.moldedData.category[0].segmentCategory[0].image;
        }

    }

    public hoverUpdateImage(image: string, segIndex: number, subIndex: number): void {
        this.hoveredImage = image;
        this.moldedData.category[0].segmentCategory[0].hoverActive = false;
    }

    public hoverleaveImage(image: string): void {
        this.hoveredImage = image;
        this.moldedData.category[0].segmentCategory[0].hoverActive = true;
    }

    public triggerAccordion(str: string): void {
        if (this.copySelectedAccordion === str) {
            this.selectedAccordion = '';
            this.copySelectedAccordion = ''
            return
        }
        this.copySelectedAccordion = str;
        this.selectedAccordion = str;
    }

	public closeMobileMenu(): void {
		this._globalService.isMobileMenuOpen.next(false);
	}
}
