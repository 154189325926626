import {Component, Input, OnInit} from '@angular/core';
import {ProductPreview} from "../../product/product-listing/interface/product-preview";
import {ApCurrency} from "../../pipe/enum/ap-currency";
import {MoldedContent} from "../../navigation/desktop-navigation/interface/molding/molded-content";
import {OwlOptions} from "ngx-owl-carousel-o";
import {OwlMultiSliderConfigService} from "../service/owl-multi-slider-config.service";

@Component({
	selector: 'fb-home-featured-products',
	templateUrl: './home-featured-products.component.html',
	styleUrls: ['./home-featured-products.component.scss']
})
export class HomeFeaturedProductsComponent implements OnInit {

	@Input() fabricProducts: MoldedContent = {} as MoldedContent;
	@Input() finishedProducts: MoldedContent[] = [];
	public customOptions: OwlOptions = OwlMultiSliderConfigService.owlOptions;
	public selectedProductType: string = 'fabric';
	public deltaIndex: number = 0;
	public isBrowser: boolean = false;
	public loading: boolean = false;
	public products: ProductPreview[] = [];
	public selectedCurrency: ApCurrency = ApCurrency.USD;
	public csv: string = "DBR1210314,DBR1250062,CKD1260064,CKD1260066,CML1260067";

	ngOnInit() {
	}

	private _prepareUrl(param: string): string {
		if(param.includes('-'))
			return param.toLowerCase().replace(/\s+/g, '');
		return param.toLowerCase().replace(/\s+/g, '-');
	}

	public generateSegmentRedirectionLink(url: string, segment: string, subCategory: string): string {
		return  url +'?'+ this._prepareUrl(segment) + '='+ this._prepareUrl(subCategory);
	}

	public generateFinishedSegementLink(url: string, segment: string, subCategory: string): string {
		return url + '?segment-' + segment + '=' + subCategory;
	}

	public tabSelect(productType: string, index: number): void {
		this.selectedProductType = productType;
		this.deltaIndex = index;
	}
}
