import {Injectable} from '@angular/core';
import {Settings} from '../profile/interface/settings';
import {RequestMapperService} from '../request-mapper.service';
import {SettingsTransmissionService} from '../raintree/transmission/setting-transmission/settings-transmission.service';
import {Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SettingsDataService {

	constructor(
		private _settingAPi: SettingsTransmissionService
	) { }

	public topNotification: Subject<any> = new Subject<any>();

	public settingsData: Settings[] = [];
	public swatchDiscount = 0;

	public getSetSetting(): void {
		this.settingHttpCall();
	}

	private settingHttpCall(): void {
		this._settingAPi.getSettingsList(
			RequestMapperService.GET_SETTINGS,
			() => { },
			() => { },
			(response: Settings[]) => {
				// console.log(response);
				if (response.length > 0) {
					this.settingsData = response;
					this.getSwatchDiscount(response);

					this.generateTopNotification(response);
				}
			},
			() => { },
			() => { }
		)
	}

	private getSwatchDiscount(settingsData: Settings[]): void {

		settingsData.forEach((settings: Settings)=>{

			if(settings.attributeName === 'SWATCH_PRICE_PERCENTAGE'){
				this.swatchDiscount = settings.attributeValue
			}
		})
	}

	public generateTopNotification(settings: Settings[]): void {

		settings.forEach((setting: Settings)=>{

			if(setting.attributeName === 'FABRIC_SITE_NOTIFICATION') {
				this.topNotification.next(setting)
			}
		})
	}
}
