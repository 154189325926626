import {Injectable} from '@angular/core';
import {MoldedContent} from '../desktop-navigation/interface/molding/molded-content';
import {MoldedContentSegmantCategory} from '../desktop-navigation/interface/molding/molded-content-segment-category';
import {NavContentFetchedData} from '../desktop-navigation/interface/nav-content-fetched-data';

@Injectable({
  providedIn: 'root'
})
export class PrepareDesignersCollabNavService {

  constructor() { }
  public prepareDesignerContentNav(data: NavContentFetchedData[]): MoldedContent {

	// let preparedMoldedData: MoldedContent[] = [];
	let segmentCategoryname: string[] = [];
	let contentStruc: MoldedContent = {
		name: '',
		category: []
	}
	  if (data && data.length > 0) {

		  data.forEach((elm: NavContentFetchedData) => {

			  if (elm.storyContentCategory.storyContentType == 'COLLABORATIONS') {

				  segmentCategoryname.push(elm.storyContentCategory.name)
				  segmentCategoryname = [...new Set(segmentCategoryname)];

			  }

		  })

		  data.forEach((elm: NavContentFetchedData) => {
			  segmentCategoryname.forEach((segName: string) => {

				  if (segName == elm.storyContentCategory.name) {
					  contentStruc.name = 'COLLABORATIONS';
					  contentStruc.category.push({
						  segmentCategoryName: segName,
						  segmentCategory: []
					  })

				  }

			  })

		  })

		  contentStruc.category =
			  [...new Map(contentStruc.category.map(item => [item['segmentCategoryName'], item])).values()]


		  contentStruc.category.forEach((cat: MoldedContentSegmantCategory) => {
			  data.forEach((elm: NavContentFetchedData) => {
				  if (cat.segmentCategoryName == elm.storyContentCategory.name) {
					  cat.segmentCategory.push({
						  subCategoryName: elm.title,
						  image: elm.bannerImageMobile,
						  slug: elm.slug,
						  id: elm.id
					  })
				  }
			  })
		  })

	  }
	// console.log(contentStruc)
	// console.log(segmentCategoryname)
	// debugger
	return contentStruc
}
}
