import {AuthConfig} from "@auth0/auth0-angular";


export const config: AuthConfig = {
	domain: 'dev-cxnfeuu6gvepp7qu.us.auth0.com', //auth0 domain url
	clientId: 'iW2PThISjeDP6I1dGoTgsoWDGwneBXPP', //auth0 client ID
	authorizationParams: {
		// redirect_uri: (typeof window !== 'undefined') ? window.location.origin : '',
		// redirect_uri: window.location.origin,
		// redirect_uri: ConfigurationService.FRONTEND_ENDPOINT
	}
}
