import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {LeftSideMenu} from "../../desktop-navigation/interface/left-side-menu";
import {RightSideMenu} from "../../desktop-navigation/interface/right--side-menu";
import {PrimaryNavigation} from "../../interface/primary-navigation";
import {PrepareContentNavService} from "../../service/prepare-craft-nav.service";
import {PrepareClusterNavService} from "../../service/prepare-cluster-nav.service";
import {PrepareDesignersCollabNavService} from "../../service/prepare-designers-collab-nav.service";
import {PrepareMeterialNavService} from "../../service/prepare-meterial-nav.service";
import {PreparePatternsNavService} from "../../service/prepare-patterns-nav.service";
import {PrepareColorNavService} from "../../service/prepare-color-nav.service";
import {PrepareFabricProductCraftService} from "../../service/prepare-fabric-product-craft.service";
import {PrepareFinishProductDataService} from "../../service/prepare-finish-product-data.service";
import {NavigationResponseService} from "../../../raintree/transmission/navigation-response/navigation-response.service";
import {NavFetchedData} from "../../desktop-navigation/interface/nav-fetched-data";
import {MoldedContent} from "../../desktop-navigation/interface/molding/molded-content";
import {MoldedMeterialNav} from "../../desktop-navigation/interface/molding/molded-meterial-nav";
import {MoldedPatternNav} from "../../desktop-navigation/interface/molding/molded-pattern-nav";
import {MoldedColorNav} from "../../desktop-navigation/interface/molding/molded-color-nav";
import {RequestMapperService} from "../../../request-mapper.service";
import {isPlatformBrowser} from '@angular/common';

@Component({
	selector: 'fb-mobile-navigation',
	templateUrl: './mobile-navigation.component.html',
	styleUrls: ['./mobile-navigation.component.scss']
})
export class MobileNavigationComponent {

	@Input() dataMenuList: PrimaryNavigation[] = [];
	@Input() dataStaticLeftMenu: LeftSideMenu[] = [];
	@Input() dataStaticRightMenu: RightSideMenu[] = [];
	public isOpen = true;
	public nav: NavFetchedData = {} as NavFetchedData;
	public craftMoldedData: MoldedContent = {} as MoldedContent;
	public clusterMoldedData: MoldedContent = {} as MoldedContent;
	public designerMoldedData: MoldedContent = {} as MoldedContent;
	public navMaterialData: MoldedMeterialNav[] = [];
	public navPatternData: MoldedPatternNav[] = [];
	public navColorData: MoldedColorNav[] = [];
	public fabricCraft: MoldedContent = {} as MoldedContent;
	public finishNav: MoldedContent[] = [];
	public isMobileDevice = false;
	public isBrowser: boolean = false;
	constructor(
		private _prepareCraftContentNav: PrepareContentNavService,
		private _prepareClusterContentNav: PrepareClusterNavService,
		private _prepareDesignersCollaborationContentNav: PrepareDesignersCollabNavService,
		private _prepareMoldedMaterial: PrepareMeterialNavService,
		private _prepareMoldedPattern: PreparePatternsNavService,
		private _prepareMoldedColor: PrepareColorNavService,
		private _prepareFabricCraftContentNav: PrepareFabricProductCraftService,
		private _prepareFinishCraftContentNav: PrepareFinishProductDataService,
		private _getNavRequest: NavigationResponseService,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	public closeNotification(): void {
		this.isOpen = false;
	}

	public ngOnInit(): void {

		if (this.isBrowser) {
			this.isMobileDevice = window.screen.width <= 999;
		}

		if(!this.isMobileDevice) {
			return
		}

		this._getNavRequest.getNavRequest(
			RequestMapperService.GET_NAV,
			() => {
				//success
			},
			() => {
				//error
			},
			(response: NavFetchedData) => {
				this.nav = response;
				this.sendDataForMolding()
			},
			() => {
			},
			() => {
			}
		)
	}

	public ngOnChanges(): void {
		this.sendDataForMolding();
	}

	public sendDataForMolding(): void {

		this.craftMoldedData = this._prepareCraftContentNav.prepareCraftContentNav(this.nav.contents);

		this.clusterMoldedData = this._prepareClusterContentNav.prepareClusterContentNav(this.nav.contents);

		this.designerMoldedData = this._prepareDesignersCollaborationContentNav.prepareDesignerContentNav(this.nav.contents);

		this.navMaterialData = this._prepareMoldedMaterial.prepareMeterial(this.nav.fabricProducts);

		this.navPatternData = this._prepareMoldedPattern.preparePattern(this.nav.fabricProducts);

		this.navColorData = this._prepareMoldedColor.prepareColor(this.nav.fabricProducts);

		this.fabricCraft = this._prepareFabricCraftContentNav.prepareCraftContentNav(this.nav.fabricProducts);

		this.finishNav = this._prepareFinishCraftContentNav.prepareFinishData(this.nav.finishedProducts);

	}
}
