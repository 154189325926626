import {Injectable} from '@angular/core';
import {Cart} from 'src/app/cart/interface/cart';
import {GetCartProduct, GetCartSelectedSizeProfileOption} from 'src/app/cart/interface/cart-get';
import {ProductSizeProfileList} from 'src/app/product-details/interface/product-size-profile-list';

@Injectable({
  providedIn: 'root'
})
export class CartItemInStockCalculationService {

  constructor() { }

  public prepareCartItemInstockCalculation(cartData: Cart   ): number {

	let itemInSTock: number = 5;

	let productPreview:GetCartProduct = cartData.fabricProductPreview ? cartData.fabricProductPreview.product : cartData.finishedProductPreview?.product || {} as GetCartProduct;

	let MadeToOrderfabricQTY = productPreview.madeToOrderFabric?productPreview.madeToOrderFabric.totalQuantity:productPreview.totalQuantity;

	let selectedFabricQTY = cartData.selectedFabricProfileItemId ? cartData.selectedFabric!.product.totalQuantity : productPreview.totalQuantity;

	let madeToOrderProfile = productPreview.madeToOrderProfile;
	let madeToOrderFabric = productPreview.madeToOrderFabric;
	// debugger
	let avilableSizeQTY = this._calcAvilableSizeQTY(
		productPreview.productSizeProfileList||[],
		cartData.selectedSizeOption || undefined
	)

	// debugger

	if(!cartData.selectedFabricProfileItemId && !cartData.selectedSizeProfileOptionId) {

		itemInSTock = productPreview.totalQuantity;
	}
	// else itemInSTock = avilableSizeQTY || 1;


	if (!cartData.selectedSizeProfileOptionId) { // if there is no size profile selected

		if(cartData.orderType === 'IN_STOCK' && cartData.selectedFabricProfileItemId) {

			if(productPreview.totalQuantity){
				itemInSTock = productPreview.totalQuantity
			}
			else{
				itemInSTock = selectedFabricQTY / (productPreview.madeToOrderProfile?.consumedFabric || 1);
			}

			// itemInSTock = selectedFabricQTY / (productPreview.madeToOrderProfile?.consumedFabric || 1);
			return itemInSTock
		}

		if(cartData.orderType === 'IN_STOCK') {

			itemInSTock = productPreview.totalQuantity;
			return itemInSTock
		}

		if(cartData.customSize && Object.keys(cartData.customSize).length) {
			itemInSTock = selectedFabricQTY / 1;
		}

		else{

			if(productPreview.madeToOrderFabric && productPreview.madeToOrderProfile) {

				itemInSTock = productPreview.madeToOrderFabric.totalQuantity / productPreview.madeToOrderProfile.consumedFabric;
			}

			if(cartData.selectedFabricProfileItemId && !cartData.selectedSizeProfileOptionId) {  //for scarf
				itemInSTock  = selectedFabricQTY / (productPreview.madeToOrderProfile?.consumedFabric || 1)
			}
		}


	}
	else{   // if has size profile

		if(cartData.orderType === 'IN_STOCK') {

			// for instock items

			if(!cartData.selectedFabricProfileItemId) { // for no fabric profile

				itemInSTock = madeToOrderFabric?madeToOrderFabric?.totalQuantity : productPreview.totalQuantity / (cartData.selectedSizeOption?.consumedFabric || 1);
			}
			else {

				// itemInSTock = avilableSizeQTY / (cartData.selectedSizeOption?.consumedFabric || 1);
				itemInSTock = avilableSizeQTY?avilableSizeQTY:
				(madeToOrderFabric ? madeToOrderFabric?.totalQuantity:1);

				return itemInSTock;
			}

			if(productPreview.totalQuantity) {
				itemInSTock = productPreview.totalQuantity;
			}
			else {
				itemInSTock = selectedFabricQTY / (productPreview.madeToOrderProfile?.consumedFabric || 1);
			}

		}
		else {

			itemInSTock  = selectedFabricQTY /(cartData.selectedSizeOption?.consumedFabric || 1)
		}


		// if(!cartData.selectedFabricProfileItemId && !cartData.selectedSizeProfileOptionId) {

		// 	itemInSTock = productPreview.quantity;
		// }
		// else itemInSTock = avilableSizeQTY || 1;
	}


	return Math.floor(itemInSTock)
  }


  private _calcAvilableSizeQTY(availableSIzeList: ProductSizeProfileList[], SelectedSizeData: GetCartSelectedSizeProfileOption | undefined): number {

	let sizeQTY = 0;

	if(!availableSIzeList.length ) {
		return 0
	}

	if(!SelectedSizeData){
		return 0
	}

	availableSIzeList.forEach((availabeSIze)=>{

		if(availabeSIze.sizeProfileOption.label === SelectedSizeData.label) {
			sizeQTY = availabeSIze.quantity;
		}
	})

	return sizeQTY
  }
}
