<div class="product_item_ind_cont" *ngIf="productListingData">

	<a routerLink="/product/{{fabricOrFinished}}/{{productListingData.product.slug}}" target="_blank">
		<div class="similar_products" *ngIf="productListingData.product.specialStatus">
			{{productListingData.product.specialStatus.name}}
		</div>
		<div class="img_cont" (mouseenter)="changeHeroToHoverImage('onover')"
			 (mouseleave)="changeHeroToHoverImage('out')">
			<img class="foreground"  loading="lazy"
				 alt="hero image" src="{{this.productListingData.product.heroImage}}"  />

			<img class="overlayImage" [@enterAnimation] *ngIf="hoverActive"
				 src="{{this.productListingData.product.hoverImage}}" loading="lazy" alt="hover image" />
		</div>
	</a>
	<!-- fabric only start -->
	<a routerLink="/product/{{fabricOrFinished}}/{{productListingData.product.slug}}">
		<div class="for_fabric_image_cont" *ngIf="productListingData.product.fabricProfile">
			<ng-container *ngFor="let fabricProfile of fabricProfileItemList">
				<img src="{{this.fabricProfile.fabricPreview.heroImage}}"
					 class="active" loading="lazy"
					 alt="{{fabricProfile.mockupText}}" />
			</ng-container>
			<div class="fabric_more_count" *ngIf="rateOfFabricProfileItemList.length!=0">
				+{{rateOfFabricProfileItemList.length}}
			</div>
		</div>
	</a>
	<!-- fabric only end -->
	<div class="listing_item_info">
		<a routerLink="/product/{{fabricOrFinished}}/{{productListingData.product.slug}}">
			<p class="name">
				{{productListingData.product.name| truncketHtml:20}}
			</p>

			<p class="price">
				{{selectedCurrency}}
				{{productListingData.product.price | currencyConverter | number: '1.0-2'}} /
				{{productListingData.product.unit}}
			</p>
		</a>
		<div class="login_for_bulk_price" *ngIf="!isLoggedIn"  (click)="storeUrl()" [routerLink]="['../../auth/login']">
			<p>Login For Bulk Price</p>
			<span class="material-symbols-outlined"> person </span>
		</div>
		<div class="login_for_bulk_price loggedIn" *ngIf="isLoggedIn && maxDiscount!=0">
			<p>Bulk Order &#64; {{discountedPrice | currencyConverter | number: '1.0-2'}} / {{productListingData.product.unit}}
				<!-- {{maxDiscount}}  -->
			</p>
		</div>
	</div>

	<div class="circle_image_cont">
		<ng-container *ngFor="let relatedProductList of relatedProductList">
			<a routerLink="/product/{{fabricOrFinished}}/{{relatedProductList.slug}}">
				<span style="background-image: url({{relatedProductList.heroImage}});"></span>
			</a>
		</ng-container>
		<a routerLink="/product/{{fabricOrFinished}}/{{productListingData.product.slug}}">
			<div class="fabric_more_count for_related_product" *ngIf="extendRelatedProductList.length!=0">
				+{{extendRelatedProductList.length}}
			</div>
		</a>
	</div>
</div>
