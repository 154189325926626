import {Injectable} from '@angular/core';
import {VolumeDiscountProfile} from '../interface/volume-discount-profile';
import {VolumeDiscountProfileItem} from '../interface/volume-discount-profile-item';

@Injectable({
  providedIn: 'root'
})
export class FabricVolumeDiscuntCalculationService {

  constructor() { }
	public passedVolumeDIscount: VolumeDiscountProfileItem = {} as VolumeDiscountProfileItem;
	public minimumVolumeDIscountItem: number = 0;
  public calculateFabricVolumeDiscount(
	fabricPrice: number,
	consumedFabricWithQuantity: number,
	selectedVolumeDiscountProfile:VolumeDiscountProfile,

	): number {

		let calcFabricVDPrice = fabricPrice;

		if(selectedVolumeDiscountProfile && selectedVolumeDiscountProfile.volumeDiscountProfileItemList) {

			let volumeDiscountProfileItemList: VolumeDiscountProfileItem[] = selectedVolumeDiscountProfile.volumeDiscountProfileItemList;



			let passedVolumeDIscount = {} as VolumeDiscountProfileItem;

			volumeDiscountProfileItemList = volumeDiscountProfileItemList.sort(
				(a: VolumeDiscountProfileItem, b: VolumeDiscountProfileItem) => a.minimumOrderQuantity - b.minimumOrderQuantity) //sorting vd by minimum quantity for calc

			// debugger

			// console.log(volumeDiscountProfileItemList);


			for (let i = 0; i < volumeDiscountProfileItemList.length; i++) {
				let minAmount = volumeDiscountProfileItemList[i].minimumOrderQuantity;
				let maxAmount =
					!volumeDiscountProfileItemList[i + 1] ? minAmount + 10000 : volumeDiscountProfileItemList[i + 1].minimumOrderQuantity;
				// console.log(vd);



				if (
					consumedFabricWithQuantity < minAmount
				) { //if quantity less than vs quantity

					passedVolumeDIscount = {} as VolumeDiscountProfileItem;
					this.minimumVolumeDIscountItem = 0;
					break

				}
				// debugger
				if (consumedFabricWithQuantity >= minAmount && consumedFabricWithQuantity <= (maxAmount - 1)) {

					if (volumeDiscountProfileItemList.length < 2) {
						passedVolumeDIscount = volumeDiscountProfileItemList[0];this.minimumVolumeDIscountItem = selectedVolumeDiscountProfile.volumeDiscountProfileItemList[0].minimumOrderQuantity;
						// if (cart.passedVolumeDIscount.id) {
						// 	cart.minQuantity = sorterdVolumeDiscountUtem[0].minimumOrderQuantity;
						// }
						break
					}
					else {
						passedVolumeDIscount = volumeDiscountProfileItemList[i];
						this.minimumVolumeDIscountItem = selectedVolumeDiscountProfile.volumeDiscountProfileItemList[0].minimumOrderQuantity;
						break
					}
				}
			}

			// console.log(passedVolumeDIscount);

			if(passedVolumeDIscount.id){

				calcFabricVDPrice = this._discountCalculation(fabricPrice, passedVolumeDIscount.discount);

				this.passedVolumeDIscount = passedVolumeDIscount;
			}
		}

	return calcFabricVDPrice;
  }


  private _discountCalculation(amount: number, discountPercentage: number): number {

		const discount = (discountPercentage / 100) * amount;
		const discountedAmount = amount - discount;
		return discountedAmount;
	}

}
