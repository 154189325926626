import {Component, OnInit} from '@angular/core';
import {Forex} from "../../desktop-navigation/interface/forex";
import {Currency} from "../../desktop-navigation/interface/currency";
import {StoreForexService} from "../../service/store-forex.service";
import {ForexTransmissionService} from "../../../raintree/transmission/forex-transmision/forex-transmission.service";
import {RequestMapperService} from "../../../request-mapper.service";
import {CurrencyList} from "../../desktop-navigation/model/currency-list";
import {Customer} from "../../../raintree/interface/customer-profile/customer";
import {IpTransmissionService} from "../../../raintree/transmission/ip-transmission/ip-transmission.service";
import {JWTService} from "../../../authentication/jwt.service";
import {UpdateForexInProfileService} from "../../service/update-forex-in-profile.service";
import {GlobalService} from "../../../services/global.service";
import {Router} from '@angular/router';
import {BrowserAPIService} from "@bloomscorp/ngbase";
import {CurrencyResolverService} from "../../../currency-resolver.service";

@Component({
  selector: 'fb-forex-mobile',
  templateUrl: './forex-mobile.component.html',
  styleUrls: ['./forex-mobile.component.scss']
})
export class ForexMobileComponent implements OnInit{
	public currencyList: Currency[] = [];
	public selectedCurrency: string = '';
	public customerProfile: Customer = {} as Customer

	constructor(
		private storeForex: StoreForexService,
		private _forexTransmission: ForexTransmissionService,
		private _idService: IpTransmissionService,
		private _jwt: JWTService,
		private _updateForex: UpdateForexInProfileService,
		private _globalService: GlobalService,
		private _router: Router,
		private _browser: BrowserAPIService,
		private _currencyResolver: CurrencyResolverService
	) {
	}

	public ngOnInit(): void {
		this.selectedCurrency = this._currencyResolver.getSelectedCurrency().toLowerCase();
		this._currencyResolver.selectCurrencyChange.subscribe(() => {
			this.selectedCurrency = this._currencyResolver.getSelectedCurrency().toLowerCase();
		});
		this._globalService.customerProfile.subscribe((customerData: Customer): void => {
			// debugger
			this._updateForex.storeCustomerProfile = customerData;
			this.getUserProfileAndSetForex(customerData)
		});

		if (!this._jwt.hasValidJWT()) {
			this.getForex();
		}
	}

	public onCurrencyChange(): void {

		this._currencyResolver.onCurrencyChange(this._currencyResolver.convertStringToApCurrency(this.selectedCurrency));

		if (this._jwt.hasValidJWT()) {
			this._updateForex.updateProfileApi(this.selectedCurrency);
		}

	}

	public getUserProfileAndSetForex(customerProfile: Customer): void {

		this.customerProfile = customerProfile;
		this.getForex();
	}

	public getForex(): void {
		// debugger
		this._forexTransmission.getForexResponse(
			RequestMapperService.FOREX,
			(): void => {
			},
			(): void => {
			},
			(response: Forex): void => {

				this.currencyList = CurrencyList.getExchangeRate;
				this.storeForex.forexResponse = response;

				this.currencyList.forEach((currency: Currency): void => {

					if (currency.name === 'inr') {
						currency.rate = 1
					} else if (currency.name === 'gbp') {
						currency.rate = response.gbp
					} else if (currency.name === 'usd') {
						currency.rate = response.usd
					} else if (currency.name === 'eur') {
						currency.rate = response.eur
					}
				});

				this.storeForex.forexData = this.currencyList;
			},
			(): void => {
			},
			(): void => {
			}
		)
	}

}
