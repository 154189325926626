<div class="outer">
	<div class="craft_wrapper for_craft">
		<div class="menu_ind_wrapper">
			<div class="menu_ind_secondary">
				CRAFTS
			</div>
			<ng-container *ngFor="let seg of this.fabricCraft.category; let i = index">

					<div class="tertiary_menu_inner {{this.fabricCraft.category.length<2?'full':''}}">
						<div class="tertiary_ind">
							<a class="new_url_for_fabric" [href]="generateCategoryRedirectionLink('products/fabric', seg)">

								<span class="tertiary_heading">{{seg.segmentCategoryName}}</span>
							</a>
							<ng-container *ngFor="let sub of seg.segmentCategory; let o = index">
								   <a class="new_url_for_fabric"
									  [href]="generateSegmentRedirectionLink('products/fabric',seg.segmentCategoryName,sub.subCategoryName)">
									<button class="tertiary_menu ">
										{{sub.subCategoryName}}
									</button>
								</a>
							</ng-container>
						</div>
					</div>

			</ng-container>
		</div>
	</div>
	<div class="craft_wrapper">
		<div class="menu_ind_wrapper by_material_by_color_by_pattern">
			<div class="menu_ind_secondary">
				BY MATERIAL
			</div>
			<div class="tertiary_menu_inner">
				<div class="tertiary_ind">

					<ng-container *ngFor="let item of material">
						<a [href]="generateRedirectionLink('products/fabric','material',item.name)">
							<button class="tertiary_menu">
								{{item.name}}
							</button>
						</a>
					</ng-container>

				</div>
			</div>
		</div>
	</div>
	<div class="craft_wrapper">
		<div class="menu_ind_wrapper by_material_by_color_by_pattern">
			<div class="menu_ind_secondary">
				BY PATTERN
			</div>
			<div class="tertiary_menu_inner">
				<div class="tertiary_ind">

					<ng-container *ngFor="let item of pattern">
						<a [href]="generateRedirectionLink('products/fabric','pattern',item.name)">
							<button class="tertiary_menu">
								{{item.name}}
							</button>
						</a>
					</ng-container>

				</div>
			</div>
		</div>
	</div>
	<div class="craft_wrapper">
		<div class="menu_ind_wrapper by_material_by_color_by_pattern">
			<div class="menu_ind_secondary">
				BY COLOUR
			</div>

			<div class="tertiary_menu_inner">
				<div class="tertiary_ind">

					<ng-container *ngFor="let item of color">
						<a [href]="generateRedirectionLink('products/fabric','color',item.name)">
							<button class="tertiary_menu for_colors">
								<span class="hex" [ngStyle]="{'background-color': item.hex}"></span>
								<span>{{item.name}}</span>
							</button>
						</a>
					</ng-container>

				</div>
			</div>
		</div>
	</div>
</div>
