import {PrimaryNavigation} from "../interface/primary-navigation";

export class NavigationList {
	private static readonly _fabric: PrimaryNavigation = {
		primaryMenuName: 'Fabric',
		link: '/product/fabric',
		primaryMenu: [
			{
				secondaryName: 'Crafts',
				menuType: 'menu',
				link: '/',
				secondaryMenu: [
					{
						tertiaryName: 'TER MENU 1',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string1.1',
								link: 'string1',
							},
							{
								text: 'string2.1',
								link: 'string2',
							},
							{
								text: 'string3.1',
								link: 'string3',
							},
						],
					},
					{
						tertiaryName: 'TER MENU 2',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string1.2',
								link: 'string1',
							},
							{
								text: 'string2.2',
								link: 'string2',
							},
							{
								text: 'string3.2',
								link: 'string3',
							},
						],
					},
				],
				imageMenu: [
					{
						secondaryName: 'Crafts 2',
						link: '/',
						menuType: 'image',
						imageLink: 'https://dummyimage.com/400x400/000/fff',
						text: 'Lorem vsdvsd',
					}
				]
			},

			{
				secondaryName: 'YARN DYED DESIGN',
				menuType: 'menu',
				link: '/',
				secondaryMenu: [
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string',
								link: 'string',
							}
						],
					}
				],
				imageMenu: []
			},
			{
				secondaryName: 'INDIAN PREMIUM SILK',
				menuType: 'menu',
				link: '/',
				secondaryMenu: [
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string',
								link: 'string',
							}
						],
					}
				],
				imageMenu: []
			},
			{
				secondaryName: 'ORGANIC & NATURAL',
				link: '/',
				menuType: 'menu',
				secondaryMenu: [
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string',
								link: 'string',
							}
						],
					}
				],
				imageMenu: []
			},
			{
				secondaryName: 'EMBROIDERY',
				link: '/',
				menuType: 'menu',
				secondaryMenu: [
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string',
								link: 'string',
							}
						],
					}
				],
				imageMenu: []
			},

			{
				secondaryName: 'HAND PRINTED',
				link: '/',
				menuType: 'menu',
				secondaryMenu: [
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string',
								link: 'string',
							}
						],
					}
				],
				imageMenu: []
			},
			{
				secondaryName: 'RESIST DYED',
				link: '/',
				menuType: 'menu',
				secondaryMenu: [
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string',
								link: 'string',
							}
						],
					}
				],
				imageMenu: []
			}
		],
	}
	private static readonly _sarves: PrimaryNavigation = {
		primaryMenuName: 'Scarves',
		link: '/product/finished',
		primaryMenu: [
			{
				secondaryName: 'Scarves secondary',
				menuType: 'menu',
				link: '/',
				secondaryMenu: [
					{
						tertiaryName: 'YARN DYED DESIGN',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string1',
								link: 'string1',
							},
							{
								text: 'string2',
								link: 'string2',
							},
							{
								text: 'string3',
								link: 'string3',
							},
							{
								text: 'string4',
								link: 'string1',
							},
							{
								text: 'string',
								link: 'string',
							}
						],
					},
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string1',
								link: 'string1',
							},
							{
								text: 'string2',
								link: 'string2',
							},
							{
								text: 'string3',
								link: 'string3',
							},
							{
								text: 'string4',
								link: 'string1',
							},
							{
								text: 'string',
								link: 'string',
							}
						],
					},
				],
				imageMenu: [
					{
						secondaryName: '',
						link: '/',
						menuType: 'image',
						imageLink: 'https://dummyimage.com/400x400/000/fff',
						text: 'Lorem vsdvsd',
					}
				]
			},

			{
				secondaryName: 'YARN DYED DESIGN',
				menuType: 'menu',
				link: '/',
				secondaryMenu: [
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string',
								link: 'string',
							}
						],
					}
				],
				imageMenu: []
			},
			{
				secondaryName: 'INDIAN PREMIUM SILK',
				menuType: 'menu',
				link: '/',
				secondaryMenu: [
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string',
								link: 'string',
							}
						],
					}
				],
				imageMenu: []
			},
			{
				secondaryName: 'ORGANIC & NATURAL',
				link: '/',
				menuType: 'menu',
				secondaryMenu: [
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string',
								link: 'string',
							}
						],
					}
				],
				imageMenu: []
			},
			{
				secondaryName: 'EMBROIDERY',
				link: '/',
				menuType: 'menu',
				secondaryMenu: [
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string',
								link: 'string',
							}
						],
					}
				],
				imageMenu: []
			},

			{
				secondaryName: 'HAND PRINTED',
				link: '/',
				menuType: 'menu',
				secondaryMenu: [
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string',
								link: 'string',
							}
						],
					}
				],
				imageMenu: []
			},
			{
				secondaryName: 'RESIST DYED',
				link: '/',
				menuType: 'menu',
				secondaryMenu: [
					{
						tertiaryName: 'string',
						link: 'string',
						tertiaryMenu: [
							{
								text: 'string',
								link: 'string',
							}
						],
					}
				],
				imageMenu: []
			}
		],
	}

	private static readonly _menu: PrimaryNavigation[] = [
		NavigationList._fabric,
		NavigationList._sarves,
		NavigationList._fabric,
		NavigationList._fabric,
	]
	public static get methNavigationList() {
		return NavigationList._menu
	}
}
