import {Component, OnInit} from '@angular/core';
import {ProductPreview} from "../../product/product-listing/interface/product-preview";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {SearchTransmissionService} from "../../raintree/transmission/search-transmission.service";
import {Router} from "@angular/router";
import {PrepareSearchDataService} from "../../services/prepare-search-data.service";
import {ProductListPriceCalculationService} from "../../product/service/product-list-price-calculation.service";
import {RequestMapperService} from "../../request-mapper.service";
import {ProductListing} from "../../raintree/interface/product-listing/product-listing";
import {BloomsightService} from "../../bloomsight.service";

@Component({
	selector: 'fb-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

	public static waitTime: number = 1500;
	public loading: boolean = false;
	public searchProducts: ProductListing[] = [];
	public searchFormControl: FormControl = new FormControl('', [
		Validators.required,
		Validators.minLength(3)
	]);
	public timer: any;

	public searchFormGroup: FormGroup = new FormGroup({
		search: this.searchFormControl
	})

	constructor(
		public dialogRef: MatDialogRef<SearchComponent>,
		private _api: SearchTransmissionService,
		private _router: Router,
		private _searchService: PrepareSearchDataService,
		private _preparePricing: ProductListPriceCalculationService,
		private _bloomsight: BloomsightService
	) {
	}

	ngOnInit() {
	}

	public closeSearchBar() {
		this.dialogRef.close();
	}

	public clearSearchBar() {
		this.searchFormControl.reset();
	}

	public searchOnChange(): void {

		clearTimeout(this.timer);

		this.timer = setTimeout(() => {
			if (this.searchFormControl.valid) {
				this._bloomsight.trackDataEvent(
					'65a91134adae2d98edc6c5c2',
					{searchKey: this.searchFormControl.value},
					''
				);
				this.searchProducts = [];
				this._api.getSearchResult(
					RequestMapperService.SEARCH + this.searchFormControl.value,
					() => this.loading = true,
					() => {
					},
					(response: ProductPreview[]) => {
						this.searchProducts = this._searchService.prepareSearchData(response);
						this.searchProducts = this._preparePricing.calculateListingPriceForFinishedProduct(this.searchProducts);
						this.searchProducts = this.searchProducts.slice(0, 4);
					},
					(error: string) => {
					},
					() => this.loading = false
				);
			}
		}, SearchComponent.waitTime);
	}

	public onSubmit(event: KeyboardEvent): void {
		if (this.searchFormGroup.valid && event.key === 'Enter') {
			this._router.navigate(
				[RequestMapperService.SEARCH_RESULTS_URL, RequestMapperService.SEARCH_URL],
				{
					queryParams: {search: this.searchFormControl.value}
				}
			).then(() => {
				this.dialogRef.close();
			});
		}
	}
}
