<!-- <p>category-nav-desktop works!</p> -->
<div class="cat_menu flex justify-between">
	<img class="insignia_desktop" src="./assets/img/logo_white.svg" />


	<div class="menu_ind_outer">
		<a href="products/fabric" class="menu_ind">
			Fabrics
		</a>
		<div class="secondary_menu_container fadein_animation slidein_animation"
			style="background-image: url(./assets/img/tempnavmotif.svg);">
			<div class="page-container">
				<div class="secondary_menu_container_inner">
					<div class="secondary_menu_inner">

						<!-- <fb-nav-section-with-image class="nav_sec_with_image_comp full_width"
							[categoryName]="'DESIGNER'" [moldedData]="designerCollaboration"></fb-nav-section-with-image> -->
						<fb-fabric-nav-section class="nav_sec_with_image_comp full_width" [material]="MaterialDataCraft"
							[pattern]="patternData" [color]="colorData" [fabricCraft]="fabricCraftData">
						</fb-fabric-nav-section>

					</div>
				</div>
			</div>
			<button class="swatch_product_button"
					(click)="navigate('products/fabric','swatchkit', 'swatchkits')">
				ORDER A SWATCHKIT
			</button>
		</div>
	</div>


	<ng-container *ngFor="let finish of finishedData">
		<div class="menu_ind_outer">
			<a [href]="generateRedirectionLink('products/finished','category', finish.name)"  class="menu_ind href_only">
			<!-- <a href="products/finished?{{'category'}}={{finish.name}}"  class="menu_ind href_only"> -->
			<!-- <a routerLink="products/finished" [queryParams]="{category: finish.name}" class="menu_ind" (click)="reload()"> -->
			<!-- <a (click)="navigate('products/finished',
			'category', finish.name)" class="menu_ind"> -->
				{{finish.name}}
			</a>
			<div class="secondary_menu_container fadein_animation slidein_animation"
				style="background-image: url(./assets/img/tempnavmotif.svg);">
				<div class="page-container">
					<div class="secondary_menu_container_inner">
						<div class="secondary_menu_inner">

							<fb-finish-nav-section class="nav_sec_with_image_comp full_width" [dataFinish]="finish">
							</fb-finish-nav-section>

						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>


	<div class="menu_ind_outer">
		<a target="_blank" [routerLink]="['story']" class="menu_ind">

			Craft & Cluster

		</a>
		<div class="secondary_menu_container fadein_animation slidein_animation"
			style="background-image: url(./assets/img/tempnavmotif.svg);">
			<div class="page-container">
				<div class="secondary_menu_container_inner">
					<div class="secondary_menu_inner">

						<fb-nav-section-with-image class="nav_sec_with_image_comp" [categoryName]="'CRAFTS'"
							[moldedData]="craftData"></fb-nav-section-with-image>

						<fb-nav-section-with-image class="nav_sec_with_image_comp" [categoryName]="'CLUSTERS'"
							[moldedData]="ClusterData"></fb-nav-section-with-image>

					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="menu_ind_outer">
		<a target="_blank" [routerLink]="['story']" [queryParams]="{category: 'collaborations'}" class="menu_ind">

			Collaborations

		</a>
		<div class="secondary_menu_container fadein_animation slidein_animation"
			style="background-image: url(./assets/img/tempnavmotif.svg);">
			<div class="page-container">
				<div class="secondary_menu_container_inner">
					<div class="secondary_menu_inner">

						<fb-nav-section-with-image class="nav_sec_with_image_comp full_width"
							[categoryName]="'DESIGNER'" [moldedData]="designerCollaboration">
						</fb-nav-section-with-image>

					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="menu_ind_outer">
		<a target="_blank" href="https://blog.anuprerna.com/" class="menu_ind">

			STORIES

		</a>
	</div>

	<fb-search-navigation></fb-search-navigation>

</div>
