import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {RequestMapperService} from "../../request-mapper.service";

@Component({
	selector: 'fb-home-caraousel',
	templateUrl: './home-caraousel.component.html',
	styleUrls: ['./home-caraousel.component.scss']
})
export class HomeCaraouselComponent implements AfterViewInit, OnDestroy {
	@ViewChild('stack', { static: true }) stackElement!: ElementRef;
	private intervalId: any;
	public activeCardIndex: number = 0;
	public cdn: string = RequestMapperService.CDN_HOME;
	public typingEffect: boolean = false;
	public cards = [
		{ type: 'video', src: this.cdn + 'weaving.mp4', poster: this.cdn + 'weaving-poster.png', text: `Empowering <span class="text-[#9c8a6c]">500+</span> Artisans`},
		{ type: 'video', src: this.cdn + 'dyeing.mp4', poster: this.cdn + 'dyeing-poster.png', text: `<span class="text-[#9c8a6c]">Natural</span> & <span class="text-[#9c8a6c]">Eco-friendly</span> Dyeing` },
		{ type: 'video', src: this.cdn + 'stitching.mp4', poster: this.cdn + 'stitching-poster.png', text: `<span class="text-[#9c8a6c]">Fabric-to-Fashion</span> Simplified` },
		{ type: 'video', src: this.cdn + 'bts.mp4', poster: this.cdn + 'bts-poster.png', text: `Capture Behind The <span class="text-[#9c8a6c]">Scenes</span>` },
	];

	ngAfterViewInit(): void {
		const stack = this.stackElement.nativeElement;

		Array.from(stack.children).reverse().forEach((child: any) => {
			stack.append(child);
		});

		if (this.cards[this.activeCardIndex].type === 'video') {
			const currentVideo = document.getElementById('stackVideo' + this.activeCardIndex) as HTMLVideoElement;
			
			if (currentVideo) {
				const playPromise = currentVideo.play();

				if (playPromise !== undefined) {
					playPromise
						.then(_ => {})
						.catch(error => {});
				}
			}
		}
		setTimeout(() => {
			this.typingEffect = true;
		}, 15);

		this.intervalId = setInterval(() => {
			this.rotateCard(stack);
		}, 6000);
	}

	ngOnDestroy(): void {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
	}

	private rotateCard(stack: HTMLElement): void {
		const card = stack.querySelector(".fb-stack-card:last-child") as HTMLElement;
		if (!card) return;

		card.classList.add('swap-this');

		setTimeout(() => {
			card.classList.remove('swap-this');
			stack.prepend(card);
			this.updateActiveCardIndex(stack);
		}, 2000);
	}

	private updateActiveCardIndex(stack: HTMLElement): void {
		const previousIndex = this.activeCardIndex;
		this.activeCardIndex = (this.activeCardIndex + 1) % stack.children.length;

		if (this.cards[previousIndex].type === 'video') {
			const previousVideo = document.getElementById('stackVideo' + previousIndex) as HTMLVideoElement;

			if (previousVideo) {
				previousVideo.pause();
			}
		}

		if (this.cards[this.activeCardIndex].type === 'video') {
			const currentVideo = document.getElementById('stackVideo' + this.activeCardIndex) as HTMLVideoElement;

			if (currentVideo) {
				const playPromise = currentVideo.play();

				if (playPromise !== undefined) {
					playPromise
						.then(_ => {})
						.catch(error => {});
				}
			}
		}

		this.triggerTypingEffect();
	}

	private triggerTypingEffect(): void {
		this.typingEffect = false;
		setTimeout(() => {
			this.typingEffect = true;
		}, 5);
	}

}
