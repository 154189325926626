import {Component, Input, OnInit} from '@angular/core';
import {BlogPreview} from "../../navigation/interface/story-preview";

@Component({
  selector: 'fb-home-all-stories',
  templateUrl: './home-all-stories.component.html',
  styleUrls: ['./home-all-stories.component.scss']
})
export class HomeAllStoriesComponent implements OnInit {

	@Input()
	public blogPreviewList: BlogPreview[] = [];
	public blogListShow: boolean[] = [];

	ngOnInit(): void {
		this.blogListShow = new Array(this.blogPreviewList.length).fill(false);
		this.blogListShow[0] = true;
	}

	public onMouseEnter(i: number): void {
		this.blogListShow[i] = true;
		if (i !== 0) {
			this.blogListShow[0] = false;
		}
	}
}
