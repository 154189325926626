import {Injectable} from '@angular/core';
import {NavProductFetchedData} from '../desktop-navigation/interface/nav-product-fetched-data';
import {MoldedContent} from '../desktop-navigation/interface/molding/molded-content';
import {MoldedContentSegmantCategory} from '../desktop-navigation/interface/molding/molded-content-segment-category';
import {Subject} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class PrepareFinishProductDataService {

	public finishDataFromNav: Subject<MoldedContent[]> = new Subject();
	public storeFinishData: MoldedContent[] = [];

	constructor() {
	}

	public prepareFinishData(data: NavProductFetchedData[]): MoldedContent[] {

		// let preparedMoldedData: MoldedContent[] = [];
		let contentStructureArr: MoldedContent[] = []

		if (data && data.length > 0) {

			data.forEach((elm: NavProductFetchedData) => {

				// debugger
				let contentStructure: MoldedContent = {
					name: '',
					category: []
				}
				contentStructure.name = elm.category.name
				contentStructureArr.push(contentStructure)

				contentStructureArr = [...new Map(contentStructureArr.map(item => [item['name'], item])).values()]
			})

			// console.log(contentStructureArr)
			// debugger

			data.forEach((elmData: NavProductFetchedData) => {
				contentStructureArr.forEach((elm: MoldedContent) => {
					if (elmData.category.name == elm.name) {
						elm.category.push({
							segmentCategoryName: elmData.segment.name,
							segmentCategory: [],
							parentCategoryName: elmData.category.name
						})
					}
					elm.category = [...new Map(elm.category.map(item => [item['segmentCategoryName'], item])).values()]
				})
			});

			contentStructureArr.forEach((parentArr: MoldedContent) => {
				parentArr.category.forEach((parentCat: MoldedContentSegmantCategory) => {
					data.forEach((elmData: NavProductFetchedData) => {
						// debugger
						if (parentCat.parentCategoryName == elmData.category.name && parentCat.segmentCategoryName == elmData.segment.name) {
							parentCat.segmentCategory.push({
								subCategoryName: elmData.subCategory.name,
								image: elmData.heroImage,
								slug: elmData.slug,
								id: elmData.id,
							})
						}
						parentCat.segmentCategory = [...new Map(parentCat.segmentCategory.map(item => [item['subCategoryName'], item])).values()]

					})
				})
			})
			// })


			this.finishDataFromNav.next(contentStructureArr)
			this.storeFinishData = contentStructureArr

		}

		return contentStructureArr
	}
}
