import {Injectable} from '@angular/core';
import {Discount} from '../interface/discount';
import {DiscountTransmissionService} from 'src/app/raintree/transmission/discount-transmisssion/discount-transmission.service';
import {Cart} from 'src/app/cart/interface/cart';
import {Price} from '../interface/price';
import {StoreFreeShippingDiscountService} from './store-free-shipping-discount.service';

@Injectable({
	providedIn: 'root'
})
export class AutoDiscountCalculationService {

	constructor(
		private _getDiscountResponse: DiscountTransmissionService,
		private _storeFreeShipping: StoreFreeShippingDiscountService
	) { }

	public discountList: Discount[] = [];
	public autoDiscountedDiscount: Discount[] = [];
	public freeShippingOption = {} as Discount;

	public intCalculate(cardData: Cart[], price: Price): Price {
		// debugger
		let newPrice = {} as Price;
		this.autoDiscountedDiscount = [];

		this.discountList.forEach((elm: Discount) => {

			if (elm.discountType == 'FREE_SHIPPING' && elm.active) {
				this.freeShippingOption = elm;
				this._storeFreeShipping.storeFreeShippingDiscount = elm;
			}

			if (elm.discountMethod === 'AUTOMATIC' && elm.discountType != 'FREE_SHIPPING' && elm.active) {
				this.autoDiscountedDiscount.push(elm)
			}
		})

		let sortedAutoDiscount = this.autoDiscountedDiscount.sort((a: Discount, b: Discount) => a.minimumOrderValue - b.minimumOrderValue) //sorting vd by minimum quantity for calc
		// debugger
		this.autoDiscountedDiscount = [...sortedAutoDiscount];
		price.storedAutoDiscounts = this.autoDiscountedDiscount;

		if (this.discountList.length > 1) {
			// debugger
			// console.log(cardData)
			// console.log(this.discountList)
			// console.log(price);
			newPrice = this.calculateAutoDiscount(cardData, price);

			return newPrice
		}
		else return newPrice

	}


	private calculateAutoDiscount(cardData: Cart[], price: Price): Price {

		let totalAutoDiscountPrice = 0;
		let normalItemPrice = 0;
		let volumeDiscountedPrice = 0;


		for (let cart of cardData) {

			if (!cart.volumeDiscountedPrice) {

				normalItemPrice += cart.price! * cart.quantity

			}

			if (cart.volumeDiscountedPrice) {

				volumeDiscountedPrice += (cart.quantity * cart.volumeDiscountedPrice!)

			}

		}


		// debugger
		for (let [i, discount] of this.autoDiscountedDiscount.entries()) {

			let minVal = discount.minimumOrderValue;
			let maxVal = this.autoDiscountedDiscount[i + 1] ? this.autoDiscountedDiscount[i + 1].minimumOrderValue : (minVal + 1000000);

			if (
				normalItemPrice < minVal
			) {

				totalAutoDiscountPrice =
					this._discountCalculation(normalItemPrice, 0);

					price.autoDiscountPercentage = 0;

				break

			}

			if (
				normalItemPrice >= minVal && normalItemPrice <= maxVal
			) {
				// debugger
				totalAutoDiscountPrice =
					this._discountCalculation(normalItemPrice, this.autoDiscountedDiscount[i].discountPercentage);

				price.autoDiscountPercentage = this.autoDiscountedDiscount[i].discountPercentage;

				break

			}

		}

		// debugger

		price.totalPrice = Math.round(((totalAutoDiscountPrice?totalAutoDiscountPrice:normalItemPrice) + volumeDiscountedPrice))

		price.autoDiscountedPrice = Math.round(normalItemPrice - totalAutoDiscountPrice);
		price.totalAutoDiscountPrice = Math.round(totalAutoDiscountPrice);
		price.volumeDiscounterPrice = Math.round(volumeDiscountedPrice);
		price.normalItemPrice = Math.round(normalItemPrice);



		return price
	}



	private _discountCalculation(amount: number, discountPercentage: number): number {

		const discount = (discountPercentage / 100) * amount;
		const discountedAmount = amount - discount;
		return discountedAmount;

	}

}
