import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'fb-cookie-information',
  templateUrl: './cookie-information.component.html',
  styleUrls: ['./cookie-information.component.scss']
})
export class CookieInformationComponent implements OnInit {

	public isBrowser: boolean = false;
	public showBanner: boolean = false;


	constructor(
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	ngOnInit() {
		if (this.isBrowser) {
			const accept = localStorage.getItem("acceptCC");
			if (accept !== 'Yes') {
				setTimeout(() => {
					this.showBanner = true;
				}, 3000)
			}
		}
	}

	public accept(): void {
		if (this.isBrowser) {
			localStorage.setItem("acceptCC", "Yes");
			this.showBanner = false;
		}
	}
}
