import {Injectable} from '@angular/core';
import {NavProductFetchedData} from '../desktop-navigation/interface/nav-product-fetched-data';
import {MoldedPatternNav} from '../desktop-navigation/interface/molding/molded-pattern-nav';
import {Pattern} from 'src/app/product-details/interface/pattern';

@Injectable({
	providedIn: 'root'
})
export class PreparePatternsNavService {

	constructor() { }

	public preparePattern(data: NavProductFetchedData[]): MoldedPatternNav[] {

		// console.log(data);
		let patterns: MoldedPatternNav[] = [];
		if (data && data.length > 0) {

			data.forEach((elm: NavProductFetchedData): void => {
				if (elm.patternId) {
					elm.patterns!.forEach((pattern: Pattern):void  => {

						patterns.push({
							name: pattern.name,
							id: pattern.id
						})

					})
				}

			})
			// debugger
			patterns = [...new Map(patterns.map((item:MoldedPatternNav) => [item['name'], item])).values()];
		}


		// console.log(patterns);
		return patterns
	}
}
