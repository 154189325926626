import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {MoldedMeterialNav} from '../interface/molding/molded-meterial-nav';
import {MoldedPatternNav} from '../interface/molding/molded-pattern-nav';
import {MoldedColorNav} from '../interface/molding/molded-color-nav';
import {MoldedContent} from '../interface/molding/molded-content';
import {Params, Router} from "@angular/router";
import {isPlatformBrowser} from '@angular/common';
import {MoldedContentSegmantCategory} from "../interface/molding/molded-content-segment-category";


@Component({
	selector: 'fb-fabric-nav-section',
	templateUrl: './fabric-nav-section.component.html',
	styleUrls: ['./fabric-nav-section.component.scss']
})
export class FabricNavSectionComponent {

	@Input() material: MoldedMeterialNav[] = [];
	@Input() pattern: MoldedPatternNav[] = [];
	@Input() color: MoldedColorNav[] = [];
	@Input() fabricCraft: MoldedContent = {} as MoldedContent;
	public isBrowser: boolean = false;
	constructor(
		private _router: Router,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	private _prepareUrl(param: string): string {
		if(param.includes('-'))
			return param.toLowerCase().replace(/\s+/g, '');
		return param.toLowerCase().replace(/\s+/g, '-');
	}
	public getSegmentRouterParams(segmentCategoryName: string): Params {

		let params:Params = {} as Params;
		params['segment-'+segmentCategoryName] = 'all';
		// debugger
		return params;

	}


	public getRouterParams(key: string, value: string) {
		return {['segment-' + key]: value};
	}

	public navigate(
		productTypeLink: string,
		paramKey: string,
		paramValue: string
	): void {

		this._router.navigate([productTypeLink,],
			{ queryParams: { [paramKey]: paramValue}}).then((): void => {

			if (this.isBrowser) {
				window.location.reload();
			}

		});
	}

	public reload(): void {
		if (this.isBrowser) {

			setTimeout(() => {
				let currentUrl:string = this._router.url;
				window.location.href = currentUrl;
			}, 100);
		}


	}

	public generateRedirectionLink(url: string, name: string, value: string): string {
		return  url +'?'+ name + '='+ this._prepareUrl(value);
	}

	public generateCategoryRedirectionLink(url: string, segment: MoldedContentSegmantCategory): string {
		const segments: string[] = segment.segmentCategory.map((sub) => this._prepareUrl(sub.subCategoryName));
		return  url +'?'+ this._prepareUrl(segment.segmentCategoryName) + '='+ segments.join();
	}
	public generateSegmentRedirectionLink(url: string, segment: string, subCategory: string): string {
		return  url +'?'+ this._prepareUrl(segment) + '='+ this._prepareUrl(subCategory);
	}



}
