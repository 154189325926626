import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {PrimaryNavigation} from "../../interface/primary-navigation";
import {MoldedContent} from "../../desktop-navigation/interface/molding/molded-content";
import {MoldedMeterialNav} from "../../desktop-navigation/interface/molding/molded-meterial-nav";
import {MoldedPatternNav} from "../../desktop-navigation/interface/molding/molded-pattern-nav";
import {MoldedColorNav} from "../../desktop-navigation/interface/molding/molded-color-nav";
import {LeftSideMenu} from "../../desktop-navigation/interface/left-side-menu";
import {GlobalService} from "../../../services/global.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {AddressFunctionService} from "../../../address/service/address-function.service";
import {CountryList} from "../../../address/interface/countryList";
import {Event, Router} from "@angular/router";
import {RequestMapperService} from "../../../request-mapper.service";
import {AuthService} from "@auth0/auth0-angular";
import {JWTService} from "../../../authentication/jwt.service";
import {isPlatformBrowser} from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'fb-mobile-category-nav',
    templateUrl: './mobile-category-nav.component.html',
    styleUrls: ['./mobile-category-nav.component.scss']
})
export class MobileCategoryNavComponent implements OnInit {
    @Input() dataCategoryMenu: PrimaryNavigation[] = [];
    @Input() craftData: MoldedContent = {} as MoldedContent;
    @Input() ClusterData: MoldedContent = {} as MoldedContent;
    @Input() designerCollaboration: MoldedContent = {} as MoldedContent;
    @Input() MaterialDataCraft: MoldedMeterialNav[] = [];
    @Input() patternData: MoldedPatternNav[] = [];
    @Input() colorData: MoldedColorNav[] = [];
    @Input() fabricCraftData: MoldedContent = {} as MoldedContent;
    @Input() finishedData: MoldedContent[] = [];
    @Input() dataStaticLeftMenu: LeftSideMenu[] = []
    public selectedAccordion: string = '';
    public copySelectedAccordion: string = '';
    public isMenuOpen = false;
    public countryList: CountryList[] = []
    public selectedCountry: CountryList = {} as CountryList;
    public isCountryListVisible: boolean = false;
	public isLoggedIn = false;
	public isBrowser: boolean = false;
	public anuprernaBlogLink = RequestMapperService.ANUPRERNA_BLOG_URL;
	public staticIndex = -1;
    constructor(
        private _globalService: GlobalService,
        private _addressService: AddressFunctionService,
		private _router: Router,
		public authService: AuthService,
		private _jwt: JWTService,
		@Inject(PLATFORM_ID) private _platformId: Object
    ) {
		this.isBrowser = isPlatformBrowser(this._platformId);

		this._router.events.subscribe((event:Event):void => {
			this.isLoggedIn = this._jwt.hasValidJWT();
		})
    }

    public triggerAccordion(str: string): void {
        // debugger
        if (this.copySelectedAccordion === str) {
            this.selectedAccordion = '';
            this.copySelectedAccordion = ''
            return
        }
        this.copySelectedAccordion = str;
        this.selectedAccordion = str;
    }

    public ngOnInit(): void {
		if (this.isBrowser) {
			if (window.innerWidth < 1200) {
				this._globalService.isMobileMenuOpen.subscribe((isMenuOpen: boolean): void => {
					this.isMenuOpen = isMenuOpen;
				});
				this._addressService.fetchCountryList().subscribe((data: any) => {
					this.countryList = data;
					this.defaultCountrySelect(data)
				});

				this.isLoggedIn = this._jwt.hasValidJWT();
			}
		}

    }



    public closeMenu(): void {
        this.isMenuOpen = false;
        this._globalService.isMobileMenuOpen.next(false);
    }

    public selectCountry(selectedCountry: CountryList): void {
        this.selectedCountry = selectedCountry;
    }

    public defaultCountrySelect(countryList: CountryList[]): void {
        countryList.forEach((country: CountryList) => {
            if (country.name === 'India') {
                this.selectedCountry = country
            }
        })

    }

    public showCountry(): void {
        this.isCountryListVisible = !this.isCountryListVisible;
    }

	public navigate(
		productTypeLink: string,
		paramKey: string,
		paramValue: string
	): void {

		this._router.navigate([productTypeLink,],
			{queryParams: {[paramKey]: paramValue}}).then((): void => {
				if (this.isBrowser) {
					window.location.reload()
				}

		});
	}

	public logout(): void {
		this._jwt.destroySession();
		this.authService.logout({
			logoutParams: {
				// returnTo: RequestMapperService.HOME
			}
		}).subscribe(() => {
			location.href = RequestMapperService.BASE_RELATIVE_URL;
			// localStorage.removeItem("cartData");
			if(this.isBrowser) {
				localStorage.clear()
			}

		})
	}

	public closeMobileMenu(): void {
		this._globalService.isMobileMenuOpen.next(false);
	}

	public openStaticIndex(index:number): void {

		if(this.staticIndex == index) {
			this.staticIndex = -1
			return
		}
		this.staticIndex = index
	}

}
