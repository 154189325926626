import {Injectable} from '@angular/core';
import {Currency} from '../desktop-navigation/interface/currency';
import {Forex} from '../desktop-navigation/interface/forex';
import {Subject} from 'rxjs';
import {ForexList} from '../desktop-navigation/interface/forex-list';
import {ForexListTransmissionService} from 'src/app/raintree/transmission/forex-list-transmission/forex-list-transmission.service';

@Injectable({
	providedIn: 'root',
})
export class StoreForexService {
	public currencySubject: Subject<string> = new Subject<string>();
	public forexList$: Subject<ForexList[]> = new Subject<ForexList[]>();

	public countryChange: Subject<string> = new Subject<string>();


	public forexResponse: Forex = {} as Forex;
	public forexListResponse: ForexList[] = [ // TODO: implement this latter by api,
			{
				"country": "United States",
				"currency": "USD",
				"rate": 1.25,
				"id": 41866,
				"version": 0
			},
			{
				"country": "United Kingdom",
				"currency": "GBP",
				"rate": 1.25,
				"id": 41862,
				"version": 1
			},
			{
				"country": "European Union",
				"currency": "EUR",
				"rate": 1.25,
				"id": 41866,
				"version": 0
			},
			{
				"country": "India",
				"currency": "INR",
				"rate": 1,
				"id": 41865,
				"version": 3
			},
		];
	public forexData: Currency[] = [];
	public selectedCurrencyName: string = 'inr';
	public selectedDefaultCurrencyName: string = 'inr';


	constructor(
		private _forexList: ForexListTransmissionService
	) {
	}


}
