import {Currency} from "../interface/currency";


export class CurrencyList {
	private static readonly currencyInr: Currency = {
		name: 'inr',
		rate: 1
	}
	private static readonly currencyGbp: Currency = {
		name: 'gbp',
		rate: 0
	}
	private static readonly currencyUsd: Currency = {
		name: 'usd',
		rate: 0
	}
	private static readonly currencyEur: Currency = {
		name: 'eur',
		rate: 0
	}

	private static readonly currencies: Currency[] = [
		CurrencyList.currencyInr,
		CurrencyList.currencyGbp,
		CurrencyList.currencyUsd,
		CurrencyList.currencyEur
	];

	public static get getExchangeRate(): Currency[] {
		return CurrencyList.currencies;
	}
}
