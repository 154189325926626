import {isPlatformBrowser} from '@angular/common';
import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {SearchComponent} from "../../../search/search/search.component";

@Component({
    selector: 'fb-search-navigation',
    templateUrl: './search-navigation.component.html',
    styleUrls: ['./search-navigation.component.scss']
})
export class SearchNavigationComponent implements  OnInit{

    public isMobile: boolean = false;
    public isDesktop: boolean = false;
    public isSearchModalOpen: boolean = false;
    public searchString: string = '';
	public isBrowser: boolean = false;
    constructor(
        private _router: Router,
		private _dialog: MatDialog,
		@Inject(PLATFORM_ID) private _platformId: Object
    ) {
		this.isBrowser = isPlatformBrowser(this._platformId);
    }

    public ngOnInit(): void {

		if (this.isBrowser) {

			if(window.innerWidth<1200) {
				this.isMobile = true;
			}

			if(window.innerWidth>1200) {
				this.isDesktop = true;
			}
		}

    }

    public openSearchModal(): void {
        this.isSearchModalOpen = !this.isSearchModalOpen;
    }

    public triggerSearch(): void {
        if (this.searchString.length < 3) return

		this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this._router.navigate(['display/search'],
            {queryParams: {search: this.searchString}})
		});
    }

    public searchInput(evt: any): void {
        this.searchString = evt.target.value;
    }

	public openSearchBar = () => {
		this._dialog.open(SearchComponent,{
			maxWidth:'900px',
			width: '90vw',
			maxHeight: '85vh',
			position: {
				top: '70px'
			}
		});
	}
}
