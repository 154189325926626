<fb-home-caraousel></fb-home-caraousel>
<fb-home-featured-products [fabricProducts]="fabricData"
						   [finishedProducts]="finishedData"></fb-home-featured-products>
<ng-container *ngIf="craftStoryData.length > 0">
	<fb-home-blog-view class="hidden lg:block"
					   [storyContentType]="'Crafts'"></fb-home-blog-view>
	<fb-home-blog-view-mobile class="block lg:hidden"
							  [storyCSV]="'9149,14799,16676'"
							  productCSV="JKD1000003, SAK0960002"
							  [storyContentType]="'Crafts'"></fb-home-blog-view-mobile>
</ng-container>

<!--<ng-container *ngIf="craftStoryData.length>0">-->
<!--    <fb-homepage-template-one [storyPreview]="craftStoryData"-->
<!--                              [storyContentType]="'ALL CRAFTS'"></fb-homepage-template-one>-->
<!--</ng-container>-->

<!--<ng-container *ngIf="artistStoryData.length>0">-->
<!--    <fb-homepage-template-two [storyPreview]="artistStoryData"-->
<!--                              [storyContentType]="'ALL ARTISTS'"></fb-homepage-template-two>-->
<!--</ng-container>-->

<ng-container *ngIf="collaborationStoryData.length > 0">
	<fb-home-blog-view-2 class="hidden lg:block"
						 [storyContentType]="'Collaborations'"></fb-home-blog-view-2>
	<fb-home-blog-view-mobile class="block lg:hidden"
							  [storyCSV]="'273394,54087'"
							  [storyContentType]="'Collaborations'"
							  productCSV="SBP9000007, TRA230011280010"></fb-home-blog-view-mobile>
</ng-container>

<!--<ng-container *ngIf="collaborationStoryData.length>0">-->
<!--    <fb-homepage-template-one [storyPreview]="collaborationStoryData"-->
<!--                              [storyContentType]="'ALL COLLABORATIONS'"></fb-homepage-template-one>-->
<!--</ng-container>-->

<ng-container *ngIf="clusterStoryData.length>0">
	<fb-home-blog-view-3 class="hidden lg:block"
						 [storyPreview]="clusterStoryData.slice(0,3)"
						 [storyContentType]="'Clusters'"></fb-home-blog-view-3>
	<fb-home-blog-view-mobile class="block lg:hidden"
							  [storyCSV]="'1832,8392,8700'"
							  [storyContentType]="'Clusters'"
							  productCSV="Hoodie, SMOCKING DRESS"></fb-home-blog-view-mobile>
</ng-container>

<!--<ng-container *ngIf="clusterStoryData.length>0">-->
<!--    <fb-homepage-template-two [storyPreview]="clusterStoryData"-->
<!--                              [storyContentType]="'ALL CLUSTERS'"></fb-homepage-template-two>-->
<!--</ng-container>-->

<ng-container *ngIf="blogList && blogList.length > 0">
	<fb-home-all-stories [blogPreviewList]="blogList"></fb-home-all-stories>
</ng-container>


<div class="white_container_with_motif">
	<fb-homepag-instagram-post></fb-homepag-instagram-post>
	<!--TODO: do not remove start-->
	<!--<fb-homepage-ethical-sustainable-tradition></fb-homepage-ethical-sustainable-tradition>-->
	<!--TODO: do not remove end-->
	<fb-home-third-party></fb-home-third-party>
</div>

<ng-container *ngIf="reviewList && reviewList.length > 0">
	<fb-home-review-carousel [reviews]="reviewList"></fb-home-review-carousel>
</ng-container>
