import {Injectable} from '@angular/core';
import {NavProductFetchedData} from '../desktop-navigation/interface/nav-product-fetched-data';
import {MoldedContent} from '../desktop-navigation/interface/molding/molded-content';
import {MoldedContentSegmantCategory} from '../desktop-navigation/interface/molding/molded-content-segment-category';
import {Subject} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class PrepareFabricProductCraftService {

	constructor() { }

	public fabricDataFromNav:Subject<MoldedContent> = new Subject();
	public storeFabricData: MoldedContent = {} as MoldedContent;
	public prepareCraftContentNav(data: NavProductFetchedData[]): MoldedContent {

		// let preparedMoldedData: MoldedContent[] = [];
		let segmentCategoryName: string[] = [];
		let contentStructure: MoldedContent = {
			name: '',
			category: []
		}

		if (data && data.length > 0) {

			data.forEach((elm: NavProductFetchedData): void => {

				segmentCategoryName.push(elm.segment.name)
				segmentCategoryName = [...new Set(segmentCategoryName)];

			})


			data.forEach((elm: NavProductFetchedData): void => {
				segmentCategoryName.forEach((segName: string) => {

					if (segName == elm.segment.name) {
						contentStructure.name = 'CRAFT';
						contentStructure.category.push({
							segmentCategoryName: segName,
							segmentCategory: []
						})

					}
				})

			})

			contentStructure.category =
				[...new Map(contentStructure.category.map((item:MoldedContentSegmantCategory) =>
					[item['segmentCategoryName'], item])).values()]


			contentStructure.category.forEach((cat: MoldedContentSegmantCategory): void => {
				data.forEach((elm: NavProductFetchedData) => {
					if (cat.segmentCategoryName == elm.segment.name) {

						cat.segmentCategory.push({
							subCategoryName: elm.subCategory.name,
							image: elm.heroImage,
							slug: elm.slug,
							id: elm.subCategory.id
						});

						cat.segmentCategory = [...new Map(cat.segmentCategory.map((item) => [item['subCategoryName'], item])).values()]
					}
				})
			})


			this.fabricDataFromNav.next(contentStructure);
			this.storeFabricData = contentStructure;
		}

		return contentStructure
	}
}
