/// <reference types="@angular/localize" />

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';


if (environment.production) {
	enableProdMode();
	Sentry.init({
		dsn: "https://18830b222ba93da9b77bfea08bbaf088@o4507401959243776.ingest.us.sentry.io/4507401968812032",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));

