import {Injectable} from '@angular/core';
import {ProductPreview} from "../../product/product-listing/interface/product-preview";

@Injectable({
	providedIn: 'root'
})
export class HomeBlogViewService {

	constructor() {
	}

	public getProductLink(product: ProductPreview): string {
		return `/product/${(product.productGroup === 'fabric') ? 'fabric' : 'finished'}-product/${product.slug}`;
	}
}
