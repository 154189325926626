import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {PrimaryNavigation} from '../../interface/primary-navigation';
import {MoldedContent} from '../interface/molding/molded-content';
import {MoldedMeterialNav} from '../interface/molding/molded-meterial-nav';
import {MoldedPatternNav} from '../interface/molding/molded-pattern-nav';
import {MoldedColorNav} from '../interface/molding/molded-color-nav';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {MoldedContentSegmantCategory} from "../interface/molding/molded-content-segment-category";

@Component({
    selector: 'fb-category-nav-desktop',
    templateUrl: './category-nav-desktop.component.html',
    styleUrls: ['./category-nav-desktop.component.scss']
})
export class CategoryNavDesktopComponent implements OnInit {

    @Input() dataCategoryMenu: PrimaryNavigation[] = [];
    @Input() craftData: MoldedContent = {} as MoldedContent;
    @Input() ClusterData: MoldedContent = {} as MoldedContent;
    @Input() designerCollaboration: MoldedContent = {} as MoldedContent;
    @Input() MaterialDataCraft: MoldedMeterialNav[] = [];
    @Input() patternData: MoldedPatternNav[] = [];
    @Input() colorData: MoldedColorNav[] = [];
    @Input() fabricCraftData: MoldedContent = {} as MoldedContent;
    @Input() finishedData: MoldedContent[] = [];
	public isBrowser: boolean = false;


    constructor(
        private _router: Router,
		@Inject(PLATFORM_ID) private _platformId: Object
    ) {
		this.isBrowser = isPlatformBrowser(this._platformId);
    }

	private _prepareUrl(param: string): string {
		if(param.includes('-'))
			return param.toLowerCase().replace(/\s+/g, '');
		return param.toLowerCase().replace(/\s+/g, '-');
	}

    public ngOnInit(): void {
    }

    public ngOnChanges(): void {
        // debugger
        // console.log(this.craftData)
    }

    public navigate(
        productTypeLink: string,
        paramKey: string,
        paramValue: string
    ): void {

        this._router.navigate([productTypeLink,],
            {queryParams: {[paramKey]: paramValue}}).then((): void => {
				if (this.isBrowser){
					window.location.reload();
				}

        });
    }

	public reload(): void {
		if (this.isBrowser){
		setTimeout(() => {
			let currentUrl:string = this._router.url;


				window.location.href = currentUrl;

		}, 100);
		}
	}

	public generateRedirectionLink(url: string, name: string, value: string): string {
		return  url +'?'+ name + '='+ this._prepareUrl(value);
	}

}
