import {Injectable} from '@angular/core';
import {Cart} from '../interface/cart';
import {CustomSize} from "../interface/custom-size";

@Injectable({
	providedIn: 'root'
})
export class CheckForDuplicateCartDataService {

	public storedCartItems: Cart[] = [];
	public duplicateObject: Cart = {} as Cart;
	public makingCharges: number = 0;

	constructor() { }


	private isCustomSizeObjectEqual(object1: CustomSize, object2: CustomSize): boolean {
		const keys1: string[] = Object.keys(object1);
		const keys2: string[] = Object.keys(object2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (let key of keys1) {
			if (object1[key] !== object2[key]) {
				return false;
			}
		}

		return true;
	}


	public isCartItemExist(cartItem: Cart): boolean {

		for (let storedCartItem of this.storedCartItems) {

			let productNameMatched: boolean = false;
			let fabricIdMatched: boolean = false;
			let finishedIdMatched: boolean = false;
			let sizeIdMatched: boolean = false;
			let orderTypeMatched: boolean = false;
			let isProductGroupMatched: boolean = false;
			let isCustomSizeEqual: boolean = false;

			if (storedCartItem.productName === cartItem.productName) {
				productNameMatched = true;
			}
			if (storedCartItem.orderType === cartItem.orderType) {
				orderTypeMatched = true;
			}
			else orderTypeMatched = false;

			if (storedCartItem.selectedFabricId === (cartItem.selectedFabricId?cartItem.selectedFabricId:0)) {
				fabricIdMatched = true;
			}

			if (storedCartItem.selectedSizeOptionId === (cartItem.selectedSizeOptionId?cartItem.selectedSizeOptionId:0)) {
				sizeIdMatched = true;
			}

			if(storedCartItem.productGroup === cartItem.productGroup) {
				isProductGroupMatched = true;
			}
			else isProductGroupMatched = false;

			if (
				this._isFinishMathed(
					cartItem.selectedFinishId ? cartItem.selectedFinishId : '',
					storedCartItem
				)
			) {
				finishedIdMatched = true;
			}

			if(storedCartItem.customSize && cartItem.customSize) {
				isCustomSizeEqual = this.isCustomSizeObjectEqual(storedCartItem.customSize, cartItem.customSize);
			}
			else {
				isCustomSizeEqual = true;
			}


			if(
				fabricIdMatched &&
				productNameMatched &&
				finishedIdMatched &&
				sizeIdMatched &&
				orderTypeMatched &&
				isProductGroupMatched &&
				isCustomSizeEqual
			) {
				this.duplicateObject = storedCartItem;
				return true;
			}
		}

		return false;
	}

	private _isFinishMathed(ids: string, storedCartItem: Cart): boolean {

		let selectedFinised: string = '';

		if (storedCartItem.selectedFinishId) {

			selectedFinised = (storedCartItem.selectedFinishId.split(',').sort(
				(a: any, b: any) => a - b
			).join(''))
		}

		let cartItem: string = ids.split(',').sort(
			(a: any, b: any) => a - b
		).join('');

		return selectedFinised === cartItem;
	}

}
