<!-- <p>footer works!</p> -->
<div class="footer_cont_outer">
	<div class="footer_cont">
		<div class="footer_link_cont">
			<div class="footer_link_inner">
				<h2>
					ABOUT US
				</h2>
				<ul>
					<li>
						<a href="{{anuprernaBlogLink}}about-us/about-the-brand/56485" target="_blank">
							Who We Are
						</a>
					</li>
					<li>
						<a href="{{anuprernaBlogLink}}about-us/about-the-platform/55951" target="_blank">
							About Our Platform
						</a>
					</li>
					<li>
						<a href="{{anuprernaBlogLink}}about-us/about-our-impact/57938" target="_blank">
							Sustainable Milestones
						</a>
					</li>
					<li>
						<a href="{{anuprernaBlogLink}}wholesale/custom-clothing-accessories-homewares/59339" target="_blank">
							What We Offer
						</a>
					</li>
				</ul>
			</div>
			<div class="footer_link_inner">
				<h2>
					Order Dashboard
				</h2>
				<ul>
					<li>
						<a href="/profile/order">Track Your Order</a>
					</li>
					<li>
						<a href="{{anuprernaBlogLink}}wholesale/order-fabric-swatches/59195" target="_blank">Order Swatches</a>
					</li>

					<li>
						<a href="{{anuprernaBlogLink}}whosale/custom-clothing-accessories-homewares/59339" target="_blank">Customise Your Own</a>
					</li>
					<li>
						<a href="{{anuprernaBlogLink}}wholesale/wholesale-production-preorder/59335" target="_blank">Wholesale and PreOrder</a>
					</li>
					<!-- <li>
						<a href="{{anuprernaBlogLink}}">Customer Reviews</a>
					</li> -->
				</ul>
			</div>
			<div class="footer_link_inner">
				<h2>
					Detailed Policy
				</h2>
				<ul>
					<li>
						<a href="https://anuprerna.com/content/policies/privacy-policy/173823"
						   target="_blank">Privacy Policy</a>
					</li>

					<li>
						<a href="https://anuprerna.com/content/policies/return-exchange-policy/170896"
						   target="_blank">Return & Exchange Policy</a>
					</li>
					<li>
						<a href="https://anuprerna.com/content/policies/terms-conditions/174271"
						   target="_blank">Terms & Conditions</a>
					</li>

					<li>
						<a href="https://anuprerna.com/content/policies/international-orders/174182"
						   target="_blank">International Orders</a>
					</li>
					<!-- <li>
						<a href="">Create Your Moodboards</a>
					</li> -->
				</ul>
			</div>
		</div>
		<div class="footer_contact_info">
			<a routerLink="/contact"><h2>CONTACT INFO</h2></a>
			<div class="contact_us_info">
				<span class="material-symbols-outlined for_info_icon">
					mail
				</span>
				<p><a href="mailto: support@anuprerna.com">support&#64;anuprerna.com</a></p>
			</div>
			<div class="contact_us_info">
				<span class="for_info_image">
					<img src="../../../assets/img/whatsapp.svg"/>
				</span>
				<p><a href="https://wa.me/+917483194942" target="_blank">+91 74831 94942</a></p>
			</div>
			<div class="contact_us_info">

				<p><a routerLink="/contact">Contact us</a></p>
			</div>
			<div class="follow_us_cont">
				<p>Follow Us:</p>
				<a href="https://twitter.com/Anuprerna6"><img src="../../../assets/img/twitter.svg"/></a>
				<a href="https://www.facebook.com/anuprernatelier/"><img src="../../../assets/img/facebook.svg"/></a>
				<a href="https://in.pinterest.com/anuprernas/"><img src="../../../assets/img/pininterest.svg"/></a>
				<a href="https://www.instagram.com/anuprerna_atelier/"><img src="../../../assets/img/instagram.svg"/></a>
			</div>
		</div>
	</div>
	<div class="botton_footer">
		Anuprerna Artisian Pvt. Ltd, ALL RIGHTS REGISTERED
	</div>
</div>
