import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Customer} from "../../raintree/interface/customer-profile/customer";
import {CustomerProfileTransmissionService} from "../../raintree/transmission/customer-profile-transmission/customer-profile-transmission.service";
import {GlobalService} from "../../services/global.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {RaintreeResponse} from "../../raintree/interface/raintree-response";
import {RequestMapperService} from "../../request-mapper.service";
import {isPlatformBrowser} from '@angular/common';
import {Router} from '@angular/router';
import {BrowserAPIService} from '@bloomscorp/ngbase';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class UpdateForexInProfileService {

	constructor(
		private _customerProfile: CustomerProfileTransmissionService,
		private _globalService: GlobalService,
		private _router: Router,
		private _browser: BrowserAPIService,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}
	public isBrowser: boolean = false;
	public storeCustomerProfile:Customer = {} as Customer

	public  updateProfileApi(currency: string): void {
		this.storeCustomerProfile.defaultCurrency = currency;
		this._updateProfile();
	}

	public updateProfileWishlist(wishlist: string): void {
		this.storeCustomerProfile.wishlist = wishlist;
		this._updateProfile();
	}

	private _updateProfile() {
		this._customerProfile.updtateCustomerInfo(
			RequestMapperService.UPDATE_CUSTOMER_PROFILE,
			this.storeCustomerProfile,
			() => {
			},
			() => {
			},
			(response: RaintreeResponse) => {
				if(this._router.url === '/checkout') {
					this._browser.window()?.location.reload();
				}
			},
			() => {

			},
			() => {

			}
		)
	}
}
