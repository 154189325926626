import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HttpErrorHandlerService} from "../../http-error-handler.service";
import {JWTService} from "../../../authentication/jwt.service";
import {PasteboxService} from "../../../services/pastebox.service";
import {TransmissionService} from "../transmission.service";
import {Forex} from "../../../navigation/desktop-navigation/interface/forex";
import {ForexResponse} from "../../interface/forex-response/forex-response";

@Injectable({
  providedIn: 'root'
})
export class ForexTransmissionService extends TransmissionService{

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
		_pastebox: PasteboxService
	) {
		super(_http, _httpErrorHandler, _jwt, _pastebox);
	}

	public getForexResponse(
		url: string,
		onPreExecute: () => void,
		onPostExecute: (response: Forex) => void,
		onSuccess: (response: Forex) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executeGetPayload<ForexResponse, Forex>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'forexExchangeRate',
			false
		);
	}
}
