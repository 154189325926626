import {Injectable} from '@angular/core';
import {ConstantService} from "../../services/constant.service";
import {TransmissionService} from "./transmission.service";
import {HttpClient} from "@angular/common/http";
import {HttpErrorHandlerService} from "../http-error-handler.service";
import {JWTService} from "../../authentication/jwt.service";
import {PasteboxService} from "../../services/pastebox.service";
import {ProductListingResponse} from "../interface/product-listing/product-listing-response";
import {RequestMapperService} from "../../request-mapper.service";
import {ProductPreview} from "../../product/product-listing/interface/product-preview";

@Injectable({
	providedIn: 'root'
})
export class WishlistTransmissionService extends TransmissionService {

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
		_pastebox: PasteboxService
	) {
		super(
			_http,
			_httpErrorHandler,
			_jwt,
			_pastebox
		);
	}

	public getProductPreviewByCSV(
		csv: string,
		onPreExecute: () => void,
		onPostExecute: (response: ProductPreview[]) => void,
		onSuccess: (response: ProductPreview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {
		this.executeGetPayload<ProductListingResponse,ProductPreview[]>(
			RequestMapperService.GET_PRODUCT_BY_CSV + csv,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.PRODUCT_LISTING_ATTRIBUTE_KEY,
			false
		);
	}
}
