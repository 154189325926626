import {Injectable} from '@angular/core';
import {StoryPreview} from "../../story/interface/story-preview";

@Injectable({
	providedIn: 'root'
})
export class SortStoryContentListService {

	public craftStoryData: StoryPreview[] = [];
	public artistStoryData: StoryPreview[] = [];
	public collaborationStoryData: StoryPreview[] = [];
	public clusterStoryData: StoryPreview[] = [];

	constructor() {
	}

	public prepareSortedStoryPreview(storyResponse: StoryPreview[]): void {

		storyResponse.forEach((story: StoryPreview): void => {

			if (story.storyContentCategory.storyContentType === 'CRAFTS') {
				this.craftStoryData.push(story);
			}
			if (story.storyContentCategory.storyContentType === 'ARTISTS') {
				this.artistStoryData.push(story);
			}
			if (story.storyContentCategory.storyContentType === 'COLLABORATIONS') {
				this.collaborationStoryData.push(story);
			}
			if (story.storyContentCategory.storyContentType === 'CLUSTERS') {
				this.clusterStoryData.push(story);
			}
		});

		this.craftStoryData.sort(
			(a: StoryPreview, b: StoryPreview) => a.timeOfCreation - b.timeOfCreation
		);

		this.artistStoryData.sort(
			(a: StoryPreview, b: StoryPreview) => a.timeOfCreation - b.timeOfCreation
		);

		this.collaborationStoryData.sort(
			(a: StoryPreview, b: StoryPreview) => a.timeOfCreation - b.timeOfCreation
		);

		this.clusterStoryData.sort(
			(a: StoryPreview, b: StoryPreview) => a.timeOfCreation - b.timeOfCreation
		);
	}
}
