import {HttpClient} from "@angular/common/http";
import {JWTService} from "../../authentication/jwt.service";
import {HttpErrorHandlerService} from "../http-error-handler.service";
import {TransmissionService} from "./transmission.service";
import {PasteboxService} from "../../services/pastebox.service";
import {StoryPreview} from "../../story/interface/story-preview";
import {StoryListResponse} from "../interface/story-list-response";
import {Injectable} from "@angular/core";
import {StoryDetailsResponse} from "../interface/story-details-response";
import {StoryDetails} from "src/app/story-details/interface/story-details/story-details";
import {Story} from "../../story/interface/story";
import {RequestMapperService} from "../../request-mapper.service";

@Injectable({
	providedIn: 'root'
})
export class StoryTransmissionService extends TransmissionService {
	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
        _pastebox: PasteboxService
	) {
		super(_http, _httpErrorHandler, _jwt, _pastebox);
	}

	getStoryList(
		url:string,
		onPreExecute: () => void,
		onPostExecute: (response: StoryPreview[]) => void,
		onSuccess: (response: StoryPreview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	){
		// debugger
		this.executeGetPayload<StoryListResponse,StoryPreview[]>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'storyContentList',
			false // :TODO fales
		);
	}

	getStoryListById(
		url:string,
		onPreExecute: () => void,
		onPostExecute: (response: StoryDetails) => void,
		onSuccess: (response: StoryDetails) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	){
		// debugger
		this.executeGetPayload<StoryDetailsResponse,StoryDetails>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'storyContent',
			false
		);
	}

	public getStoryListByIdCsv(
		csv: string,
		onPreExecute: () => void,
		onPostExecute: (response: StoryPreview[]) => void,
		onSuccess: (response: StoryPreview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	){
		// debugger
		this.executeGetPayload<StoryListResponse,StoryPreview[]>(
			RequestMapperService.GET_STORY_LIST_CSV + csv,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'storyContentList',
			false
		);
	}

}
