import { Component } from '@angular/core';
import {OwlOptions} from "ngx-owl-carousel-o";
import {OwlMultiSliderConfigService} from "../service/owl-multi-slider-config.service";
import {RequestMapperService} from "../../request-mapper.service";

@Component({
  selector: 'fb-home-third-party',
  templateUrl: './home-third-party.component.html',
  styleUrls: ['./home-third-party.component.scss']
})
export class HomeThirdPartyComponent {
	public customOptions: OwlOptions = OwlMultiSliderConfigService.owlOptionsNews;
	public cdnLink: string = RequestMapperService.CDN_HOME + 'news/';
}
