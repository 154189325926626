import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafeHtmlPipe} from './safe-html.pipe';
import {FormatDatePipe} from './format-date.pipe';
import {TruncketHtmlPipe} from './truncket-html.pipe';
import {CurrencyConverterPipe} from './currency-converter.pipe';
import {PreferredCurrencyConverterPipe} from './preferred-currency-converter.pipe';


@NgModule({
	declarations: [
		SafeHtmlPipe,
		FormatDatePipe,
		TruncketHtmlPipe,
		CurrencyConverterPipe,
  PreferredCurrencyConverterPipe
	],
	exports: [
		SafeHtmlPipe,
		FormatDatePipe,
		TruncketHtmlPipe,
		CurrencyConverterPipe,
		PreferredCurrencyConverterPipe
	],
	imports: [
		CommonModule
	]
})
export class PipeModule {
}
