import {Injectable} from '@angular/core';
import {Discount} from '../interface/discount';

@Injectable({
  providedIn: 'root'
})
export class StoreFreeShippingDiscountService {

  constructor() { }

  public storeFreeShippingDiscount:Discount = {} as Discount;
}
