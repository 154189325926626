import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'truncketHtml'
})
export class TruncketHtmlPipe implements PipeTransform {

	// transform(text: string, charlimit: number): string {
	// 	// if (!text || text.length <= charlimit) {
	// 	// 	return text;
	// 	// }


	// 	// let without_html = text.replace(/<(?:.|\n)*?>/gm, '');
	// 	// let shortened = without_html.substring(0, charlimit) + "...";
	// 	// return shortened;


	// }
	transform(value: string, limit: number = 350): string {

		if (!value) return '';

		const regex = /(<([^>]+)>)/ig;
		let output = value.replace(regex, "");
		// Normalize whitespace
		output = output.replace(/\s+/g, " ");
		// Trim leading and trailing spaces
		output = output.trim();

		if (output.length < limit) return output;

		return output?.substring(0, limit) + '...';

	}

}
