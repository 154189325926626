<!-- <p>category-nav-desktop works!</p> -->
<div class="cat_menu for_mobile flex justify-between {{isMenuOpen?'active':''}}">
	<div class="country_name_cont">
		<button class="{{isCountryListVisible?'active':''}}">
			<span class="currency_select_cont">
				<span class="currency_label">CURRENCY:</span> <strong>
					<fb-forex-mobile></fb-forex-mobile>
				</strong>
			</span>
			<!--			<span class="material-symbols-outlined" (click)="showCountry()">-->
			<!--				expand_more-->
			<!--			</span>-->
		</button>
		<div class="county_list {{isCountryListVisible?'active':''}}">
			<ng-container *ngFor="let country of countryList; let i = index">
				<div class="country" (click)="selectCountry(country)">
					{{country.name}}
				</div>
			</ng-container>

		</div>
	</div>

	<div class="menu_ind_outer">
		<a class="menu_ind {{selectedAccordion.toLowerCase() === 'fabrics'?'active':''}}">
			<span><a href="products/fabric">Fabrics</a></span>
			<span class="material-symbols-outlined" (click)="triggerAccordion('Fabrics')">
				expand_more
			</span>
		</a>
		<div class="secondary_menu_container {{selectedAccordion.toLowerCase() === 'fabrics'?'active':''}}">
			<div class="page-container">
				<div class="secondary_menu_container_inner">
					<div class="secondary_menu_inner">

						<fb-fabric-nav-section-mobile class="full_width" [material]="MaterialDataCraft"
							[pattern]="patternData" [color]="colorData" [fabricCraft]="fabricCraftData">
						</fb-fabric-nav-section-mobile>
						<!--						<fb-fabric-nav-section class="nav_sec_with_image_comp full_width" [material]="MaterialDataCraft"-->
						<!--											   [pattern]="patternData" [color]="colorData"-->
						<!--											   [fabricCraft]="fabricCraftData">-->
						<!--						</fb-fabric-nav-section>-->

					</div>
				</div>
			</div>
		</div>
	</div>


	<ng-container *ngFor="let finish of finishedData">
		<div class="menu_ind_outer">
			<span class="menu_ind {{selectedAccordion === finish.name ?'active':''}}">
				<a (click)="navigate('products/finished', 'category', finish.name)">
					{{finish.name}}
				</a>
				<span class="material-symbols-outlined" (click)="triggerAccordion(finish.name)">
					expand_more
				</span>
			</span>
			<div class="secondary_menu_container {{selectedAccordion === finish.name ?'active':''}}"
				style="background-image: url(./assets/img/tempnavmotif.svg);">
				<div class="page-container">
					<div class="secondary_menu_container_inner">
						<div class="secondary_menu_inner">
							<fb-finish-nav-section-mobile class=" full_width" [dataFinish]="finish">
							</fb-finish-nav-section-mobile>

						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>


	<div class="menu_ind_outer">
		<span class="menu_ind {{selectedAccordion === 'craft & cluster' ?'active':''}}">
			<a [routerLink]="['story']"> <span>Craft & Cluster</span></a>
			<span class="material-symbols-outlined" (click)="triggerAccordion('craft & cluster')">
				expand_more
			</span>
		</span>
		<div class="secondary_menu_container {{selectedAccordion === 'craft & cluster' ?'active':''}}"
			style="background-image: url(./assets/img/tempnavmotif.svg);">
			<div class="page-container">
				<div class="secondary_menu_container_inner">
					<div class="secondary_menu_inner">

						<fb-nav-section-with-image-mobile class="nav_sec_with_image_comp full_width"
							[categoryName]="'CRAFTS'" [moldedData]="craftData"></fb-nav-section-with-image-mobile>

						<fb-nav-section-with-image-mobile class="nav_sec_with_image_comp full_width"
							[categoryName]="'CLUSTERS'" [moldedData]="ClusterData"></fb-nav-section-with-image-mobile>

					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="menu_ind_outer">
		<a class="menu_ind {{selectedAccordion === 'collaborations' ?'active':''}}">

			<span>Collaborations</span>
			<span class="material-symbols-outlined" (click)="triggerAccordion('collaborations')">
				expand_more
			</span>

		</a>
		<div class="secondary_menu_container  {{selectedAccordion === 'collaborations' ?'active':''}}"
			style="background-image: url(./assets/img/tempnavmotif.svg);">
			<div class="page-container">
				<div class="secondary_menu_container_inner">
					<div class="secondary_menu_inner">

						<fb-nav-section-with-image-mobile class="nav_sec_with_image_comp full_width"
							[categoryName]="'DESIGNER'" [moldedData]="designerCollaboration">
						</fb-nav-section-with-image-mobile>

					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="menu_ind_outer">
		<a target="_blank" href="https://blog.anuprerna.com/" class="menu_ind border-0">

			STORIES

		</a>
	</div>

	<div class="static_nav">
		<!-- <ng-container *ngFor="let leftMenu of dataStaticLeftMenu; let i = index">
			<button>
				{{leftMenu.text}}
			</button>
		</ng-container> -->
		<!-- TODO: thid will be separate component -->

		<div class="mobile_static_nav">

			<div class="static_index_container {{staticIndex ==0 ?'active':''}}">
				<button (click)="openStaticIndex(0)">
					About Us
					<span class="material-symbols-outlined">
						expand_more
					</span>
				</button>
				<ul>
					<a href="{{anuprernaBlogLink}}about-the-brand/56485" target="_blank">
						<li>
							About The Brand
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}about-our-impact/57938" target="_blank">
						<li>
							About Our Impact
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}about-the-founder/57073" target="_blank">
						<li>
							About the Founder
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}about-anuprerna-studio/53794" target="_blank">
						<li>
							About the studio
						</li>
					</a>
					<a  routerLink="/contact">
						<li>
							Contact Us
						</li>
					</a>

				</ul>
			</div>

			<div class="static_index_container {{staticIndex ==1 ?'active':''}}">
				<button (click)="openStaticIndex(1)">
					WHOLESALE
					<span class="material-symbols-outlined">
						expand_more
					</span>
				</button>
				<ul>
					<a href="{{anuprernaBlogLink}}wholesale-production-preorder/59335" target="_blank">
						<li>
							Wholesale Production process
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}order-fabric-swatches/59195" target="_blank">
						<li>
							Order Fabric Swatches
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}natural-sustainable-custom-dyeing/59105" target="_blank">
						<li>
							Custom Sustainable Dyeing
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}custom-clothing-accessories-homewares/59339" target="_blank">
						<li>
							Custom Clothing, Accessories, & Homewares
						</li>
					</a>
				</ul>
			</div>

			<div class="static_index_container {{staticIndex ==2 ?'active':''}}">
				<button (click)="openStaticIndex(2)">
					CAREGUIDE
					<span class="material-symbols-outlined">
						expand_more
					</span>
				</button>
				<ul>
					<a href="{{anuprernaBlogLink}}how-to-nurture-your-natural-dyed-clothing/126408" target="_blank">
						<li>
							Natural Fabric CareGuide
						</li>
					</a>
					<a href="{{anuprernaBlogLink}}handmade-textiles-care-guide/108968" target="_blank">
						<li>
							Natural Dye CareGuide
						</li>
					</a>
				</ul>
			</div>
		</div>


		<div class="social_link_outer_cont">
			<button [routerLink]="['profile/dashboard']" *ngIf="isLoggedIn"
			(click)="closeMobileMenu()">
				<!-- <span class="material-symbols-outlined">
					  person
				</span> -->
				<span>
					<img class="light_image" src="./assets/img/loginicon_white.svg" alt="profile">
				</span>
				<b>Profile</b>
			</button>
			<button (click)="logout()" *ngIf="isLoggedIn" (click)="closeMobileMenu()">
				<span class="material-symbols-outlined">
					logout
				</span>
				<b>Logout</b>
			</button>
			<button [routerLink]="['auth/login']" *ngIf="!isLoggedIn" (click)="closeMobileMenu()">
				<span class="material-symbols-outlined">
					login
				</span>
				<b>Login</b>
			</button>
			<button [routerLink]="['contact']" (click)="closeMobileMenu()">
				<!-- <span class="material-symbols-outlined">
					call
				</span> -->
				<span>
					<img class="light_image" src="./assets/img/contact.svg" alt="contact">
				</span>
				<b>contact us</b>
			</button>
			<button (click)="closeMobileMenu()">
				<!-- <span class="material-symbols-outlined">
					policy
				</span> -->
				<span>
					<img class="light_image" src="./assets/img/policy.svg" alt="policy">
				</span>
				<b>policy</b>
			</button>
			<div class="social_cont_inner">
				<img src="./assets/img/facebook.svg" alt="facebook icon" (click)="closeMobileMenu()"/>
				<img src="./assets/img/twitter.svg" alt="twitter icon" />
				<img src="./assets/img/pininterest.svg" alt="pinterest icon" />
				<img src="./assets/img/instagram.svg" alt="instagram icon" />
			</div>
		</div>

	</div>




</div>
<div class="overlay_mobile_menu {{isMenuOpen?'active':''}}" (click)="closeMenu()"></div>
