import {Injectable} from '@angular/core';
import {TransmissionService} from '../transmission.service';
import {HttpClient} from '@angular/common/http';
import {JWTService} from 'src/app/authentication/jwt.service';
import {PasteboxService} from 'src/app/services/pastebox.service';
import {HttpErrorHandlerService} from '../../http-error-handler.service';
import {NavResponse} from '../../interface/nav-response/nav-response';
import {NavFetchedData} from 'src/app/navigation/desktop-navigation/interface/nav-fetched-data';

@Injectable({
	providedIn: 'root'
})
export class NavigationResponseService extends TransmissionService {

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
		_pastebox: PasteboxService
	) {
		super(_http, _httpErrorHandler, _jwt, _pastebox);
	}

	public getNavRequest(
		url: string,
		onPreExecute: () => void,
		onPostExecute: (response: NavFetchedData) => void,
		onSuccess: (response: NavFetchedData) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executeGetPayload<NavResponse, NavFetchedData>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'entity',
			false
		);
	}
}
