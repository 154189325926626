import {Component, Input} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ProductListing} from "../../raintree/interface/product-listing/product-listing";

@Component({
	selector: 'fb-search-products',
	templateUrl: './search-products.component.html',
	styleUrls: ['./search-products.component.scss']
})
export class SearchProductsComponent {

	@Input()
	public products: ProductListing[] = [];

	@Input()
	public term: string = '';

	constructor(
		private _dialog: MatDialog
	) {
	}

	ngOnInit(): void {
	}

	public click(): void {
		this._dialog.closeAll();
	}

}
