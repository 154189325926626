import {Injectable} from '@angular/core';
import {JWTService} from "../authentication/jwt.service";
import {UpdateForexInProfileService} from "../navigation/service/update-forex-in-profile.service";
import {LocalStorageService} from "@bloomscorp/ngbase";
import {Subject} from "rxjs";
import {ToastMessageService} from "../services/toast-message.service";
import {isEmptyString} from "bmx-pastebox";

@Injectable({
	providedIn: 'root'
})
export class WishlistService {

	private static readonly _WISHLIST_KEY: string = 'whaP78jBHY67yBGH5tr98BNhnbvfb';

	public static readonly MSG_ADD_TO_WISHLIST: string = 'added to your wishlist';
	public static readonly MSG_REMOVE_FROM_WISHLIST: string = 'removed from your wishlist';

	private authenticatedWishlist: string = '';
	private isLoggedIn: boolean = false;
	public wishlistItems: Subject<null> = new Subject<null>();

	constructor(
		private _jwt: JWTService,
		private _localStorage: LocalStorageService,
		private _toast: ToastMessageService,
		private _profileStore: UpdateForexInProfileService
	) {
		this.isLoggedIn = this._jwt.hasValidJWT();
	}

	public getWishlistCSV(): string {
		if(this.isLoggedIn) {
			return this.authenticatedWishlist;
		}
		else {
			return this._localStorage.retrieve(WishlistService._WISHLIST_KEY);
		}
	}

	public setWishlistCSV(wishlist: string): void {
		if(this.isLoggedIn) {
			this.authenticatedWishlist = wishlist;
		}
		else {
			this._localStorage.store(WishlistService._WISHLIST_KEY, wishlist);
		}
		this.wishlistItems.next(null);
	}

	public toggle(
		name: string,
		sku: string,
		showToast: boolean = true
	): void {

		let wishlist: string = this.getWishlistCSV();

		if (wishlist.includes(sku)) {
			this.setWishlistCSV(
				wishlist
					.replace(sku, '')
					.replace(/(^,)|(,$)/g, '')
					.replace(/,,/g, ',')
			);

			if (showToast)
				this._toast.success(name + ' ' + WishlistService.MSG_REMOVE_FROM_WISHLIST, sku);
		}
		else {
			this.setWishlistCSV(
				isEmptyString(wishlist) ? sku : wishlist + ',' + sku
			);
			if (showToast)
				this._toast.success(name + ' ' + WishlistService.MSG_ADD_TO_WISHLIST, sku);
		}

		if(this.isLoggedIn)
			this._profileStore.updateProfileWishlist(this.getWishlistCSV());
	}

	public isInWishlist(sku: string): boolean {
		let wishlist: string = this.getWishlistCSV();
		return wishlist.includes(sku);
	}
}
