import {Injectable} from '@angular/core';
import {Cart} from 'src/app/cart/interface/cart';
import {VolumeDiscountProfileItem} from 'src/app/product-details/interface/volume-discount-profile-item';
import {Price} from '../interface/price';
import {GetCartVolumeDiscountProfile, GetCartVolumeDiscountProfileItemList} from 'src/app/cart/interface/cart-get';
import {FabricVolumeDiscuntCalculationService} from 'src/app/product-details/service/fabric-volume-discunt-calculation.service';
import {SettingsDataService} from 'src/app/services/settings-data.service';

@Injectable({
	providedIn: 'root'
})
export class VolumeDiscountCalculationService {

	constructor(
		private _fabricVolumeDiscountCalculation: FabricVolumeDiscuntCalculationService,
		private _settings: SettingsDataService,
	) { }

	// public appliedVolumeDiscount = 0;

	public calculate(cartList: Cart[]): Cart[] {
		if (cartList.length > 0) {

			// debugger
			// console.log(data)

			cartList.forEach((cart: Cart) => {

				// debugger
				let isThisProductFabric: boolean = cart.fabricProductPreview? true : false;

				let productPreview = cart.fabricProductPreview?cart.fabricProductPreview:cart.finishedProductPreview;

				let fabricPrice:number = (cart.selectedFabric?.id ? cart.selectedFabric.product.price : productPreview?.product.price || 0);

				let selectedFabricVolumeDiscountProfile = ((cart.selectedFabric?.id && cart.selectedFabric.product.volumeDiscountProfile) ? cart.selectedFabric.product.volumeDiscountProfile : {} as GetCartVolumeDiscountProfile);
				let consumedFabric:number = 0;

				let productVolumeDiscountProfile = cart.volumeDiscountProfile?cart.volumeDiscountProfile:{} as GetCartVolumeDiscountProfile;

				let productVolumeDiscountItemList = productVolumeDiscountProfile?productVolumeDiscountProfile.volumeDiscountProfileItemList:[];

				let isAllVoluumeDiscountZero:boolean = this._isAllVolumeDiscountZero(productVolumeDiscountItemList);

				if(productVolumeDiscountProfile.id) {
					productVolumeDiscountItemList.sort(
						(a: VolumeDiscountProfileItem, b: VolumeDiscountProfileItem) => a.minimumOrderQuantity - b.minimumOrderQuantity);

					productVolumeDiscountItemList = productVolumeDiscountItemList.filter(
						(vdItem:VolumeDiscountProfileItem)=>vdItem.preOrder);
				}



				if(cart.selectedSizeOption && cart.selectedSizeOption.id) {

					consumedFabric = cart.selectedSizeOption.consumedFabric
				}
				else {
					consumedFabric = productPreview?.product.madeToOrderProfile?.consumedFabric || 0;
				}

				// if(cart.customSize){
				// 	consumedFabric = 1;
				// }

				let calcPrice = cart.price ? cart.price : 0;

				// if(cart.customSize && productPreview?.product.customSizeProfile) {
				// 	//TODO int custom size price
				// 	// calcPrice = calcPrice + productPreview?.product.customSizeProfile.price;
				// 	calcPrice = calcPrice + 800;
				// }

				let fabricCost: number = (consumedFabric * fabricPrice);


				// debugger

				let discountedFabricCost:number = 0;

				if(selectedFabricVolumeDiscountProfile && selectedFabricVolumeDiscountProfile.id) { // fabric volume discount calculation

					discountedFabricCost = this._fabricVolumeDiscountCalculation.calculateFabricVolumeDiscount(

						fabricCost,
						(cart.quantity * (consumedFabric)),
						selectedFabricVolumeDiscountProfile
					);
				}

				if(isThisProductFabric) { // fabric price calculation

					// let dyeingCost: number = calcPrice - fabricCost;

					let dyeingCost: number = 0;

					if(cart.selectedFabric && cart.selectedFabric.id) {
						dyeingCost = calcPrice - fabricCost;
					}
					else {
						dyeingCost = calcPrice;
					}

					let discountedDyeingCost: number = this._fabricVolumeDiscountCalculation.calculateFabricVolumeDiscount(
						dyeingCost,
						(cart.quantity),
						cart.volumeDiscountProfile?cart.volumeDiscountProfile:{} as GetCartVolumeDiscountProfile,

					);

					if(
						(discountedFabricCost|| discountedDyeingCost) &&
						( fabricCost >= discountedFabricCost) || ( dyeingCost >= discountedDyeingCost) ) {
						calcPrice = discountedDyeingCost +  discountedFabricCost;

						if(cart.productGroup === 'swatch') {
							calcPrice = cart.price!;
						}

						if(cart.price === calcPrice) {
							cart.volumeDiscountedPrice = 0;
							cart.UnDiscountPrice = cart.price;
							cart.volumeDiscount =  {} as VolumeDiscountProfileItem;

							// if(productVolumeDiscountItemList && productVolumeDiscountItemList.length===0
							// 	&& productVolumeDiscountItemList[0].discount === 0)
							if(productVolumeDiscountItemList && isAllVoluumeDiscountZero) {
									cart.volumeDiscount = productVolumeDiscountItemList[0];
									cart.volumeDiscountedPrice = cart.price;
									cart.UnDiscountPrice = cart.price;
								}
						}
						else{
							cart.UnDiscountPrice = cart.price;
							cart.volumeDiscountedPrice = calcPrice;

							cart.volumeDiscount = this._fabricVolumeDiscountCalculation.passedVolumeDIscount;

							cart.minQuantity = this._fabricVolumeDiscountCalculation.minimumVolumeDIscountItem;

							if(productVolumeDiscountProfile && cart.orderType === 'PRE_ORDER') {

								cart.minQuantity = productVolumeDiscountItemList[0].minimumOrderQuantity;
							}

							if(cart.orderType === 'MADE_TO_ORDER') {

								cart.minQuantity = productPreview?.product.madeToOrderProfile?.minimumOrderQuantity;
							}
							if(cart.orderType === 'IN_STOCK') {
								cart.minQuantity = .5;
							}
						}

						// if(cart.productGroup === 'swatch') {
						// 	// cart.price = this.swatchPriceCalculation(cart.price!, this._settings.swatchDiscount);
						// 	cart.price = this.swatchPriceCalculation(cart.copyPrice!, this._settings.swatchDiscount);
						// 	cart.minQuantity = 1;
						// 	console.log(cart)
						// }


						// cart.passedVolumeDIscount =  this._fabricVolumeDiscountCalculation.passedVolumeDIscount;

					}
				}
				else {


					let makingCost: number = calcPrice - fabricCost;
					let discountedMakingCost: number = this._fabricVolumeDiscountCalculation.calculateFabricVolumeDiscount(
						makingCost,
						(cart.quantity),
						cart.volumeDiscountProfile?cart.volumeDiscountProfile:{} as GetCartVolumeDiscountProfile,

					);

					if((cart.volumeDiscountProfile) && (( fabricCost >= discountedFabricCost) || ( makingCost >= discountedMakingCost) )) {

						calcPrice = discountedMakingCost +  discountedFabricCost;

						if(cart.price === calcPrice) {
							cart.volumeDiscountedPrice = 0;
							cart.UnDiscountPrice = cart.price;
						}
						else{
							cart.UnDiscountPrice = cart.price;
							cart.volumeDiscountedPrice = calcPrice;

							cart.volumeDiscount = this._fabricVolumeDiscountCalculation.passedVolumeDIscount;

							cart.minQuantity = this._fabricVolumeDiscountCalculation.minimumVolumeDIscountItem;

							if(productVolumeDiscountProfile && cart.orderType === 'PRE_ORDER') {

								cart.minQuantity = productVolumeDiscountItemList[0].minimumOrderQuantity;
							}

							if(cart.orderType === 'MADE_TO_ORDER') {

								cart.minQuantity = productPreview?.product.madeToOrderProfile?.minimumOrderQuantity;
							}
							if(cart.orderType === 'IN_STOCK') {
								cart.minQuantity = 1;
							}
						}

						// cart.passedVolumeDIscount =  this._fabricVolumeDiscountCalculation.passedVolumeDIscount;

					}
				}

				// debugger
				// console.log(calcPrice)










				// if (cart.volumeDiscountProfile) {

				// 	cart.passedVolumeDIscount = {} as VolumeDiscountProfileItem;

				// 	let sorterdVolumeDiscountUtem: VolumeDiscountProfileItem[] = cart.volumeDiscountProfile.volumeDiscountProfileItemList.sort(
				// 		(a: VolumeDiscountProfileItem, b: VolumeDiscountProfileItem) => a.minimumOrderQuantity - b.minimumOrderQuantity).filter(
				// 			(vdItem:VolumeDiscountProfileItem)=>vdItem.preOrder) //sorting vd by minimum quantity for calc

				// 	for (let i = 0; i < sorterdVolumeDiscountUtem.length; i++) {
				// 		let minDiscount = sorterdVolumeDiscountUtem[i].minimumOrderQuantity;
				// 		let maxDiscount =
				// 			!sorterdVolumeDiscountUtem[i + 1] ? minDiscount + 10000 : sorterdVolumeDiscountUtem[i + 1].minimumOrderQuantity;
				// 		// console.log(vd);

				// 		if (
				// 			cart.quantity < minDiscount
				// 		) { //if quantity less than vs quantity

				// 			cart.passedVolumeDIscount = {} as VolumeDiscountProfileItem;
				// 			cart.volumeDiscountedPrice = 0;
				// 			cart.volumeDiscount = {} as VolumeDiscountProfileItem
				// 			cart.minQuantity = 1;
				// 			break

				// 		}
				// 		if (cart.quantity >= minDiscount && cart.quantity <= (maxDiscount - 1)) {

				// 			if (sorterdVolumeDiscountUtem.length < 2) {
				// 				cart.passedVolumeDIscount = sorterdVolumeDiscountUtem[0];
				// 				cart.volumeDiscount = sorterdVolumeDiscountUtem[0];
				// 				cart.minQuantity = sorterdVolumeDiscountUtem[0].minimumOrderQuantity;

				// 				break
				// 			}
				// 			else {
				// 				cart.passedVolumeDIscount = sorterdVolumeDiscountUtem[i];
				// 				cart.volumeDiscount = sorterdVolumeDiscountUtem[i];
				// 				cart.minQuantity = sorterdVolumeDiscountUtem[0].minimumOrderQuantity;

				// 				break
				// 			}
				// 		}
				// 	}
				// 	// console.log(cart)
				// }
			});

			let calculatedVd: Cart[] = this._calculatedVd(cartList)

			return calculatedVd;
		}

		else return cartList;
	}



	private _calculatedVd(cartList: Cart[]): Cart[] {

		cartList.forEach((cart: Cart) => {
			if (
				cart.passedVolumeDIscount != undefined &&
				Object.keys(cart.passedVolumeDIscount).length !== 0
			) {
				// console.log(cart)
				cart.UnDiscountPrice = cart.price;
				cart.volumeDiscountedPrice = this._discountCalculation(cart.price!, cart.passedVolumeDIscount.discount);

			}
		})

		return cartList;
	}


	private _discountCalculation(amount: number, discountPercentage: number): number {

		const discount = (discountPercentage / 100) * amount;
		const discountedAmount = amount - discount;
		return discountedAmount;

	}



	public calculateVolumeDiscountedPrice(cart: Cart[], price: Price): number {
		let calculatedPrice = 0;
		// debugger
		cart.forEach((elm: Cart) => {
			let price = (((elm.volumeDiscountedPrice! ? elm.volumeDiscountedPrice! : elm.price!)) * elm.quantity)
			calculatedPrice += price;
		})
		// price.volumeDiscountPercentage =
		// debugger
		return calculatedPrice
	}

	public calculatePreOrderVolumeDiscountedPrice(cart: Cart[]): number {
		let calculatedPrice = 0;
		// debugger
		cart.forEach((elm: Cart) => {
			if (elm.orderType === 'PRE_ORDER') {
				let price = (((elm.volumeDiscountedPrice! ? elm.volumeDiscountedPrice! : elm.price!)) * elm.quantity)
				calculatedPrice += price;
			}
		})
		// debugger
		return calculatedPrice
	}

	public calculateNormalOrderVolumeDiscountedPrice(cart: Cart[]): number {
		let calculatedPrice = 0;
		// debugger
		cart.forEach((elm: Cart) => {
			if (elm.orderType != 'PRE_ORDER' && elm.volumeDiscountedPrice) {
				let price = ((elm.volumeDiscountedPrice) * elm.quantity)
				calculatedPrice += price;
			}
		})
		// debugger
		return calculatedPrice
	}

	public totalVolumeDiscountedItemQTY(cartItems: Cart[]): number {
		let sum = 0
		cartItems.forEach((cartItem: Cart) => {
			if (cartItem.volumeDiscountedPrice) {
				sum += cartItem.quantity
			}
		});
		// debugger
		return sum
	}

	public normalItemVolumeDiscountedQTY(cartItems: Cart[]): number {
		let sum = 0
		cartItems.forEach((cartItem: Cart) => {
			if (cartItem.volumeDiscountedPrice && cartItem.orderType != 'PRE_ORDER') {
				sum += cartItem.quantity
			}
		});
		// debugger
		return sum
	}

	public preOrderVolumeDiscountedQTY(cartItems: Cart[]): number {
		let sum = 0
		cartItems.forEach((cartItem: Cart) => {
			if (cartItem.volumeDiscountedPrice && cartItem.orderType === 'PRE_ORDER') {
				sum += cartItem.quantity
			}
		});
		// debugger
		return sum
	}

	public swatchPriceCalculation(price: number, percent: number): number {

		if (price && percent) {

			const discount: number = (percent / 100) * price;
			// const discountedAmount: number = price - discount; //TODO: do not remove

			return Math.round(discount);
		} else {
			return 0
		}
	}

	private _isAllVolumeDiscountZero(productVolumeDiscountItemList: GetCartVolumeDiscountProfileItemList[]): boolean {

		let allVolumeDiscountZero = true;

		if(productVolumeDiscountItemList && productVolumeDiscountItemList.length){
			productVolumeDiscountItemList.forEach((item: GetCartVolumeDiscountProfileItemList) => {

				if (item.discount) {
					allVolumeDiscountZero = false;
				}
				else {
					allVolumeDiscountZero = true;
				}
			});
		}


		return allVolumeDiscountZero;
	}
}
