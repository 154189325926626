<section class="fb-home-review w-full flex flex-col justify-center items-center py-10">
	<h1 class="text-3xl sm:text-5xl text-[#b7a98f] font-medium mb-10">
		Hear from our <span class="text-black">Customers</span>
	</h1>
	<div class="w-full container lg:px-12">
		<owl-carousel-o [options]="carouselConfig">
			<ng-template *ngFor="let review of reviews; let i = index" carouselSlide>
				<div class="fb-home-review-card">
					<div class="flex justify-between items-start">
						<div class="w-1/5">
							<img *ngIf="review.productImages != ''"
								 [src]="review.productImages!.split(',')[0]"
								 (click)="onUserProvidedImagesClick(review.productImages)"
								 alt=""
								 class="w-[80px] h-[80px] rounded border-2 border-[#6c5b48] cursor-pointer">
							<img *ngIf="review.productImages == ''"
								 src="/assets/img/logo-brown.svg"
								 alt=""
								 class="w-[80px] h-[80px] rounded border-2 border-[#6c5b48]">
						</div>

						<span class="text-xs text-end text-[#9c8a6c]">{{review.createdAt |  date: 'dd MMM YYYY' }}</span>
					</div>
					<div class="fb-home-review-content">
						<a *ngIf="review.link"
						   target="_blank"
						   href="{{review.link}}"
						   class="text-sm text-[#9c8a6c] block hover:text-[#8d7961] hover:underline">
							<q class="italic">{{ review.description }}</q>
						</a>
						<p *ngIf="!review.link"
						   class="text-sm text-[#9c8a6c]">
							<q class="italic">{{ review.description }}</q>
						</p>
					</div>
					<div class="flex flex-col justify-center items-end">
						<div class="flex items-center gap-1">
							<span *ngFor="let _ of stars; let i = index">
								<span class="star" [class.filled]="review.rating > i">&#9733;</span>
							</span>
						</div>
						<span class="text-[#6c5b48]">{{ review.name }}</span>
					</div>
					<p class="text-xs text-end text-[#9c8a6c]">
						<span *ngIf="review.city">{{ review.city }},</span> {{ review.country }}
					</p>
					<a *ngIf="review.product" target="_blank" [href]="prepareProductRedirectionUrl(review.product)"
					   class="w-full flex justify-start items-center gap-2 mt-auto cursor_pointer">
						<div class="1/5">
							<img [src]="review.product.heroImage" alt="" class="w-[50px] h-[50px] rounded border-[1px] border-[#6c5b48]">
						</div>
						<span class="w-3/5 text-sm text-[#9c8a6c]">{{ review.product.sku }}</span>
					</a>
				</div>
			</ng-template>
		</owl-carousel-o>
	</div>
</section>
