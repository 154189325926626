import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddStoryboardComponent} from './add-storyboard/add-storyboard.component';
import {AddExistingStoryboardComponent} from './add-existing-storyboard/add-existing-storyboard.component';
import {NotificationBarComponent} from './notification-bar/notification-bar.component';
import {StoryAndBlogListingSectionComponent} from './story-and-blog-listing-section/story-and-blog-listing-section.component';
import {FeaturedSectionOneComponent} from './featured-section-one/featured-section-one.component';
import {FeaturedSectionTwoComponent} from './featured-section-two/featured-section-two.component';

import {CarouselModule} from 'ngx-owl-carousel-o';
import {FaqSectionComponent} from './faq-section/faq-section.component';
import {ContentDetailsSectionOneComponent} from './content-details-section-one/content-details-section-one.component';
import {ContentDetailsSectionTwoComponent} from './content-details-section-two/content-details-section-two.component';
import {RouterModule} from '@angular/router';
import {JumpToPreviousBlogComponent} from './jump-to-previous/jump-to-previous-blog.component';
import {JumpToNextBlogComponent} from './jump-to-next/jump-to-next-blog.component';
import {ShareProductComponent} from './share-product/share-product.component';
import {FormsModule} from '@angular/forms';
import {PipeModule} from '../pipe/pipe.module';
import {ParallaxDirective} from './directive/parallax.directive';
import {VideoPopupComponent} from './video-popup/video-popup.component';
import {ImageCarouselComponent} from './image-carousel/image-carousel.component';
import {NewsLetterComponent} from './news-letter/news-letter.component';
import {AuthorSectionComponent} from './author-section/author-section.component';

// import { TestimonialSliderContentComponent } from './testimonial-slider-content/testimonial-slider-content.component';


@NgModule({
	declarations: [
		AddStoryboardComponent,
		AddExistingStoryboardComponent,
		NotificationBarComponent,
		StoryAndBlogListingSectionComponent,
		FeaturedSectionOneComponent,
		FeaturedSectionTwoComponent,
		FaqSectionComponent,
		ContentDetailsSectionOneComponent,
		ContentDetailsSectionTwoComponent,
		JumpToPreviousBlogComponent,
		JumpToNextBlogComponent,
		ShareProductComponent,
		ParallaxDirective,
		VideoPopupComponent,
		ImageCarouselComponent,
		NewsLetterComponent,
		AuthorSectionComponent,
		// TestimonialSliderContentComponent,

	],
	imports: [
		CommonModule,
		FormsModule,
		CarouselModule,
		RouterModule,
		PipeModule
	],
	exports: [
		AddStoryboardComponent,
		AddExistingStoryboardComponent,
		NotificationBarComponent,
		StoryAndBlogListingSectionComponent,
		FeaturedSectionOneComponent,
		FaqSectionComponent,
		FeaturedSectionTwoComponent,
		ContentDetailsSectionOneComponent,
		ContentDetailsSectionTwoComponent,
		JumpToPreviousBlogComponent,
		JumpToNextBlogComponent,
		ShareProductComponent,
		ParallaxDirective,
		VideoPopupComponent,
		ImageCarouselComponent,
		NewsLetterComponent,
		AuthorSectionComponent,
		// TestimonialSliderContentComponent

		// FormatDatePipe
		// SingleCarouselComponent
	]
})
export class ComponentsLibraryModule {
}
