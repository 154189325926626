<div class="outer">
	<div class="craft_wrapper">
		<div class="menu_ind_wrapper">
			<div class="menu_ind_secondary">
				{{categoryName}}
			</div>
			<ng-container *ngFor="let seg of this.moldedData.category; let i = index">
				<div class="tertiary_menu_inner {{this.moldedData.category.length<2?'full':''}}">
					<div class="tertiary_ind">
						<span class="tertiary_heading">{{seg.segmentCategoryName}}</span>
						<ng-container *ngFor="let sub of seg.segmentCategory; let o = index">
							<a href="story-details/{{sub.slug}}/{{sub.id}}">
								<button class="tertiary_menu {{sub.hoverActive?'hover_active':''}}"
									(mouseenter)="hoverUpdateImage(sub, sub.image,i,o)"
									(mouseleave)="hoverleaveImage(seg.segmentCategory[o].image)">
									{{sub.subCategoryName}}
								</button>
							</a>
						</ng-container>
					</div>
				</div>
			</ng-container>

		</div>

	</div>
	<div class="image_container_for_nav">
		<!-- <img src="{{hoveredImage}}" /> -->
		<a routerLink="/story-details/{{hoveredImageData.slug}}/{{hoveredImageData.id}}">
		<div class="bg_image_image_container"

			[ngStyle]="{'background': 'url(' + hoveredImage + ') no-repeat 0 0 / cover'}">

			<!-- </div> -->

		</div>
		</a>
	</div>
