<section class="fb-home-stories-new w-full flex justify-center items-center">
	<div class="container flex flex-col lg:flex-row justify-between items-center">
		<div class="my-5 lg:my-0  lg:flex-[30%] mx-2 lg:mx-0">
			<h2 class="text-6xl">All</h2>
			<h1 class="text-7xl text-[#b7a98f] font-medium">
				Stories
			</h1>
			<p class="text-2xl my-2">About <span class="text-[#9c8a6c]">People</span>, Processes and Products</p>
			<a href="https://blog.anuprerna.com"
			   target="_blank"
			   class="text-3xl py-2 fb_animate_icon_button">
				<i class="fb_animate"><b ></b><span></span></i>
				Discover More
			</a>
		</div>
		<div class="fb_story_gallery lg:flex-[70%]">
			<ng-container *ngFor="let blog of blogPreviewList; let i = index">
				<div class="fb_story_container"
					 [ngClass]="{'fb_content_hover': blogListShow[i]}"
					 (mouseenter)="onMouseEnter(i)"
					 (mouseleave)="blogListShow[i]=false">
					<div class="fb_story_content">
						<h3 class="fb_story_title">{{blog.title}}</h3>
						<p class="fb_story_description" [innerHTML]="blog.description | truncketHtml:250">
						</p>
						<a href="{{'https://blog.anuprerna.com/blog-details/'+blog.slug+'/'+blog.id}}"
						   target="_blank"
						   class="text-base py-2 fb_story_button ">
							<button>Learn More</button>
							<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/></svg>
						</a>
					</div>

					<img [src]="blog.bannerImageDesktop"
						 [alt]="blog.title"/>
				</div>

			</ng-container>
		</div>
		<div class="lg:hidden">
			<div class="fb_story_gallery_mobile fb-disable-scrollbar">
				<ng-container *ngFor="let blog of blogPreviewList; let i = index">
					<div class="fb_story_container">
						<div class="fb_story_content">
							<h3 class="fb_story_title">{{blog.title}}</h3>
							<p class="fb_story_description" [innerHTML]="blog.description | truncketHtml:200">
							</p>
							<a href="{{'https://blog.anuprerna.com/blog-details/'+blog.slug+'/'+blog.id}}"
							   target="_blank"
							   class="text-base py-2 fb_story_button ">
								<button>Learn More</button>
								<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/></svg>
							</a>
						</div>

						<img [src]="blog.bannerImageMobile"
							 [alt]="blog.title"/>
					</div>

				</ng-container>
			</div>
			<div class="flex gap-1 justify-end mx-3 mb-6 md:mb-0">
				Swipe for more
				<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/></svg></div>
		</div>

	</div>
</section>
