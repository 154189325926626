import {RequestMapperService} from "src/app/request-mapper.service"
import {LeftSideMenu} from "../interface/left-side-menu"
import {RightSideMenu} from "../interface/right--side-menu"

export class StaticNavList {
	private static readonly _leftMenu1: LeftSideMenu = {
		link: '',
		text: 'About Us'
	}
	private static readonly _leftMenu2: LeftSideMenu = {
		link: '',
		text: 'Behind the Label'
	}
	private static readonly _leftMenu3: LeftSideMenu = {
		link: '',
		text: 'Product/Service'
	}



	private static readonly _rightMenu1: RightSideMenu = {
		icon: 'person',
		imageLink: './assets/img/loginicon.svg',
		imageLinkWhite: './assets/img/loginicon_white.svg',
		link: RequestMapperService.AUTH+'/'+RequestMapperService.LOGIN,
		text: 'Login',
		auth: false
	}
	private static readonly _rightMenu2: RightSideMenu = {
		icon: 'favorite',
		imageLink: './assets/img/sb_black.svg',
		imageLinkWhite: './assets/img/sb_white.svg',
		link: '/' + RequestMapperService.WISHLIST,
		text: 'Wishlist',
		auth: false
	}
	private static readonly _rightMenu4: RightSideMenu = {
		icon: 'shopping_cart',
		imageLink: './assets/img/carticon.svg',
		imageLinkWhite: './assets/img/carticonwhite.svg',
		link: '',
		text: 'Cart',
		auth: false
	}

	private static readonly _contact: RightSideMenu = {
		icon: 'contact_support',
		imageLink: './assets/img/contact-color.png',
		imageLinkWhite: './assets/img/contact-white.png',
		link: '/' + RequestMapperService.CONTACT,
		text: 'Contact Us',
		auth: false
	}
	private static readonly _logout: RightSideMenu = {
		icon: 'logout',
		link: '',
		text: 'Logout',
		auth: true
	}
	private static readonly _leftMenuCombine: LeftSideMenu[] = [
		StaticNavList._leftMenu1,
		StaticNavList._leftMenu2,
		StaticNavList._leftMenu3,
	]

	private static readonly _rightMenuCombine: RightSideMenu[] = [
		StaticNavList._rightMenu1,
		StaticNavList._rightMenu2,
		StaticNavList._rightMenu4,
		StaticNavList._contact,
		StaticNavList._logout
	]

	public  static get methleftMenu():LeftSideMenu[] {
		return StaticNavList._leftMenuCombine
	}
	public  static get methRightMenu():RightSideMenu[] {
		return StaticNavList._rightMenuCombine
	}

}
