<div class="outer">
	<div class="craft_wrapper for_craft">
		<div class="menu_ind_wrapper">
			<a class="swatch_product_button"
					[href]="['products/fabric?swatchkit=swatchkits']">
				ORDER A SWATCHKIT
			</a>
			<div class="menu_ind_secondary {{selectedAccordion.toLowerCase() === 'crafts'?'active':''}}">
				<span>CRAFTS</span>
				<span class="material-symbols-outlined" (click)="triggerAccordion('CRAFTS')">
					expand_more
				</span>
			</div>

			<ng-container *ngFor="let seg of this.fabricCraft.category; let i = index">

				<div *ngIf="selectedAccordion.toLowerCase() === 'crafts'"
					class="tertiary_menu_inner {{this.fabricCraft.category.length<2?'full':''}}">
					<div class="tertiary_ind">
						<span class="tertiary_heading" (click)="navigateCategoryRoute('products/fabric', seg)">{{seg.segmentCategoryName}}</span>
						<ng-container *ngFor="let sub of seg.segmentCategory; let o = index">
							<a (click)="navigate('products/fabric',seg.segmentCategoryName, sub.subCategoryName)">
								<button class="tertiary_menu ">
									{{sub.subCategoryName}}
								</button>
							</a>
						</ng-container>
					</div>
				</div>

			</ng-container>
		</div>
	</div>
	<div class="craft_wrapper">
		<div class="menu_ind_wrapper by_material_by_color_by_pattern">
			<div class="menu_ind_secondary {{selectedAccordion.toLowerCase() === 'by material'?'active':''}}" >
				<span>BY MATERIAL</span>
				<span class="material-symbols-outlined" (click)="triggerAccordion('BY MATERIAL')">
					expand_more
				</span>
			</div>
			<div class="tertiary_menu_inner" *ngIf="selectedAccordion.toLowerCase() === 'by material'">
				<div class="tertiary_ind">

						<ng-container *ngFor="let item of material">
							<!--						<a [routerLink]="['products/fabric']"-->
							<!--						   [queryParams]="{materials: item.name}">-->
							<a (click)="navigate('products/fabric','material', item.name)">
								<button class="tertiary_menu">
									{{item.name}}
								</button>
							</a>
						</ng-container>


				</div>
			</div>
		</div>
	</div>
	<div class="craft_wrapper ">
		<div class="menu_ind_wrapper by_material_by_color_by_pattern">
			<div class="menu_ind_secondary {{selectedAccordion.toLowerCase() === 'by pattern'?'active':''}}">
				<span>BY PATTERN</span>
				<span class="material-symbols-outlined"  (click)="triggerAccordion('BY PATTERN')">
					expand_more
				</span>
			</div>

			<div class="tertiary_menu_inner" *ngIf="selectedAccordion.toLowerCase() === 'by pattern'">
				<div class="tertiary_ind">

					<ng-container *ngFor="let item of pattern">
						<a
							(click)="navigate('products/fabric','pattern', item.name)">
							<button class="tertiary_menu">
								{{item.name}}
							</button>
						</a>
					</ng-container>

				</div>
			</div>
		</div>
	</div>
	<div class="craft_wrapper">
		<div class="menu_ind_wrapper by_material_by_color_by_pattern">
			<div class="menu_ind_secondary {{selectedAccordion.toLowerCase() === 'by colour'?'active':''}}">
				<span>BY COLOUR</span>
				<span class="material-symbols-outlined" (click)="triggerAccordion('BY COLOUR')">
					expand_more
				</span>
			</div>

			<div class="tertiary_menu_inner"  *ngIf="selectedAccordion.toLowerCase() === 'by colour'">
				<div class="tertiary_ind">

					<ng-container *ngFor="let item of color">
						<a
							(click)="navigate('products/fabric','color', item.name)">
							<button class="tertiary_menu for_colors">
								<span class="hex" [ngStyle]="{'background-color': item.hex}"></span>
								<span>{{item.name}}</span>
							</button>
						</a>
					</ng-container>

				</div>
			</div>
		</div>
	</div>

</div>
