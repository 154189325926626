import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {JWTService} from '../jwt.service';
import {ToastMessageService} from 'src/app/services/toast-message.service';
import {RequestMapperService} from 'src/app/request-mapper.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard  {

	constructor(
		private _jwt: JWTService,
		private _router: Router,
		public _toast: ToastMessageService,
	) {
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		route.url.forEach((urlSegment: UrlSegment) => {
			// console.log(urlSegment)
			if (urlSegment.path === RequestMapperService.STORYBOARD) {
				if (!this._jwt.hasValidJWT()) {
					this._router.navigateByUrl('').then(success => {
						if (!success) this._toast.error('something is not right', 'something is not right. please Login Again')
						return;
					})
				}
			}
		})

		if (!this._jwt.hasValidJWT()) {
			this._router.navigateByUrl('')
				.then(success => {
					if (!success) this._toast.error('something is not right', 'something is not right. please Login Again')
					return;
				})
			return false;
		} else return true;

	// 	route.url.forEach((urlSegment: UrlSegment) => {
	// 		// if (urlSegment.path === RequestMapperService.PROFILE)
	// 		// 	this._localStorage.storeOnAuthSuccessRoute(RequestMapperService.PROFILE);
	// 		if (urlSegment.path === RequestMapperService.STORYBOARD)
	// 			this._localStorage.storeOnAuthSuccessRoute(RequestMapperService.STORYBOARD);
	// 		else if (
	// 			(urlSegment.path === RequestMapperService.SIGNUP) &&
	// 			this._jwt.hasValidJWT()
	// 		) this._router.navigateByUrl(
	// 				RequestMapperService.BASE_RELATIVE_URL
	// 			).then(success => {
	// 				if (!success) this._toast.error('something is not right', 'something is not right. please Login Again')
	// 				return;
	// 			});
	// 	});

	// 	if (!this._jwt.hasValidJWT()) {
	// 		this._router.navigateByUrl(
	// 			RequestMapperService.getAbsoluteUrl(
	// 				RequestMapperService.LOGIN
	// 			)
	// 		).then(success => success ? this._snack.info(MessageService.LOGIN_REQUEST) : this._snack.error(MessageService.GENERIC_FAILURE)
	// 		);
	// 		return false;
	// 	} else return true;
	// }


	}


}
