import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Cart} from '../interface/cart';
import {Subject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class GetAndSetCartDataInLocalstorageService {

	constructor(
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}
	public isBrowser: boolean = false;
	public openCartModal: Subject<boolean> = new Subject<boolean>()
	public cartResponse: Subject<Cart[]> = new Subject<Cart[]>();
	public temporaryCartData: Cart[] = [];

	public setCartInLocal(data: Cart): void {


		if(this.isBrowser) {
			let localCartData: Cart[] = JSON.parse(localStorage.getItem("cartData")!)
			if (localStorage.getItem("cartData")) {
				// debugger
				let merge = [...this.temporaryCartData, ...localCartData]
				localStorage.setItem('cartData', JSON.stringify(merge))
			}
			else{
				localStorage.setItem('cartData', JSON.stringify(this.temporaryCartData))
			}
		}


		this.temporaryCartData = []; // clear array after push to local
		this.getCartFromLocal();
	}

	public getCartFromLocal() {
		// debugger
		if(this.isBrowser) {
			let data = localStorage.getItem("cartData");
			this.cartResponse.next(JSON.parse(data!));
			// return JSON.parse(data!);
		}


	}
}
