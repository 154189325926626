import {config} from "./auth_config";

const { domain, clientId, authorizationParams: { audience } } = config as {
	domain: string;
	clientId: string;
	authorizationParams: {
		audience?: string;
	},
};

export const environment = {
	production: true,
	auth: {
		domain,
		clientId,
		authorizationParams: { // auth0 API url for your auth0 domain
			...(audience && audience !== 'https://dev-cxnfeuu6gvepp7qu.us.auth0.com/api/v2/' ? { audience } : null),
			// redirect_uri:  (typeof window !== 'undefined') ? window.location.origin : '',
			// redirect_uri: window.location.origin,
			// redirect_uri: ConfigurationService.FRONTEND_ENDPOINT,
		},
	},
};
