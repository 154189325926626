import {Component, OnInit} from '@angular/core';
import {MoldedContent} from "../../navigation/desktop-navigation/interface/molding/molded-content";
import {PrepareFabricProductCraftService} from "../../navigation/service/prepare-fabric-product-craft.service";
import {PrepareFinishProductDataService} from "../../navigation/service/prepare-finish-product-data.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {RequestMapperService} from "../../request-mapper.service";
import {StoryPreview} from "../../story/interface/story-preview";
import {StoryTransmissionService} from "../../raintree/transmission/story-transmission.service";
import {SortStoryContentListService} from "../service/sort-story-content-list.service";
import {FetchBlogListService} from 'src/app/navigation/service/fetch-blog-list.service';
import {BlogPreview} from 'src/app/navigation/interface/story-preview';
import {ReviewTransmissionService} from "../../raintree/transmission/review-transmission.service";
import {IReview} from "../../review/interface/review";

@UntilDestroy()
@Component({
	selector: 'fb-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	public fabricData: MoldedContent = {} as MoldedContent;
	public finishedData: MoldedContent[] = [];
	public craftStoryData: StoryPreview[] = [];
	public artistStoryData: StoryPreview[] = [];
	public collaborationStoryData: StoryPreview[] = [];
	public clusterStoryData: StoryPreview[] = [];
	public blogList: BlogPreview[] = [];
	public reviewList: IReview[] = [];

	constructor(
		private _preparedFabricData: PrepareFabricProductCraftService,
		private _preparedFinishData: PrepareFinishProductDataService,
		private _storyTransmissionService: StoryTransmissionService,
		private _prepareSortStoryContent: SortStoryContentListService,
		private _fetchBlogList: FetchBlogListService,
		private _reviewTransmitter: ReviewTransmissionService
	) {
	}

	public ngOnInit(): void {

		if (!this.fabricData.name) {
			this.fabricData = this._preparedFabricData.storeFabricData;
		}

		if (this.finishedData.length < 1) {
			this.finishedData = this._preparedFinishData.storeFinishData;
		}

		this._preparedFabricData.fabricDataFromNav.subscribe((fabricData: MoldedContent): void => {
				this.fabricData = fabricData;
			}
		);

		this._preparedFinishData.finishDataFromNav
			.subscribe((finishData: MoldedContent[]): void => {
					this.finishedData = finishData;
				}
			);

		if (this._fetchBlogList.storeBlogList.length > 0) {
			this.blogList = this._fetchBlogList.storeBlogList;
		} else {
			this._fetchBlogList.blogDataFromNav
				.subscribe((blogList: BlogPreview[]): void => {
					this.blogList = blogList;
				});
		}
		this.fetchStoryList();
		this.resolveReviewList();
	}

	public fetchStoryList(): void {
		this._storyTransmissionService.getStoryList(
			RequestMapperService.GET_STORY_LIST,
			(): void => {
			},
			(response: StoryPreview[]): void => {

				this._prepareSortStoryContent.prepareSortedStoryPreview(response);

				this.craftStoryData = this._prepareSortStoryContent.craftStoryData;
				this.artistStoryData = this._prepareSortStoryContent.artistStoryData;
				this.collaborationStoryData = this._prepareSortStoryContent.collaborationStoryData;
				this.clusterStoryData = this._prepareSortStoryContent.clusterStoryData;
			},
			(): void => {
			},
			(error: string): void => {
			},
			(): void => {
			},
		)
	}

	public resolveReviewList(): void {
		this._reviewTransmitter.getReviewList(
			0,
			10,
			(): void => {
			},
			(): void => {
			},
			(reviews: IReview[]): void => {
				this.reviewList = reviews;
			},
			(error: string): void => {
			},
			(): void => {
			}
		);
	}
}
