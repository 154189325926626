import {Component, HostListener, Inject, Input, OnChanges, OnInit, PLATFORM_ID} from '@angular/core';
import {PrimaryNavigation} from '../../interface/primary-navigation';
import {LeftSideMenu} from '../interface/left-side-menu';
import {RightSideMenu} from '../interface/right--side-menu';
import {NavFetchedData} from '../interface/nav-fetched-data';
import {PrepareContentNavService} from '../../service/prepare-craft-nav.service';
import {MoldedContent} from '../interface/molding/molded-content';
import {PrepareClusterNavService} from '../../service/prepare-cluster-nav.service';
import {PrepareDesignersCollabNavService} from '../../service/prepare-designers-collab-nav.service';
import {MoldedMeterialNav} from '../interface/molding/molded-meterial-nav';
import {PrepareMeterialNavService} from '../../service/prepare-meterial-nav.service';
import {PreparePatternsNavService} from '../../service/prepare-patterns-nav.service';
import {MoldedPatternNav} from '../interface/molding/molded-pattern-nav';
import {PrepareColorNavService} from '../../service/prepare-color-nav.service';
import {MoldedColorNav} from '../interface/molding/molded-color-nav';
import {PrepareFabricProductCraftService} from '../../service/prepare-fabric-product-craft.service';
import {PrepareFinishProductDataService} from '../../service/prepare-finish-product-data.service';
import {NavigationResponseService} from 'src/app/raintree/transmission/navigation-response/navigation-response.service';
import {RequestMapperService} from 'src/app/request-mapper.service';
import {FetchBlogListService} from '../../service/fetch-blog-list.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
	selector: 'fb-desktop-navigation',
	templateUrl: './desktop-navigation.component.html',
	styleUrls: ['./desktop-navigation.component.scss']
})
export class DesktopNavigationComponent implements OnInit, OnChanges {

	@Input() dataMenuList: PrimaryNavigation[] = [];
	@Input() dataStaticLeftMenu: LeftSideMenu[] = [];
	@Input() dataStaticRightMenu: RightSideMenu[] = [];
	public isOpen = true;
	public nav: NavFetchedData = {} as NavFetchedData;
	public craftMoldedData: MoldedContent = {} as MoldedContent;
	public clusterMoldedData: MoldedContent = {} as MoldedContent;
	public designerMoldedData: MoldedContent = {} as MoldedContent;
	public navMaterialData: MoldedMeterialNav[] = [];
	public navPatternData: MoldedPatternNav[] = [];
	public navColorData: MoldedColorNav[] = [];
	public fabricCraft: MoldedContent = {} as MoldedContent;
	public finishNav: MoldedContent[] = [];
	public isMobileDevice: boolean = false;

	constructor(
		private _prepareCraftContentNav: PrepareContentNavService,
		private _prepareClusterContentNav: PrepareClusterNavService,
		private _prepareDesignersCollaborationContentNav: PrepareDesignersCollabNavService,
		private _prepareMoldedMaterial: PrepareMeterialNavService,
		private _prepareMoldedPattern: PreparePatternsNavService,
		private _prepareMoldedColor: PrepareColorNavService,
		private _prepareFabricCraftContentNav: PrepareFabricProductCraftService,
		private _prepareFinishCraftContentNav: PrepareFinishProductDataService,
		private _getNavRequest: NavigationResponseService,
		private _fetchBlogList: FetchBlogListService,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	public isBrowser: boolean = false;
	public closeNotification(): void {
		this.isOpen = false;
	}

	public ngOnInit(): void {

		if (this.isBrowser) {
			this.isMobileDevice = window.screen.width <= 999;
			if(this.isMobileDevice) {
				return
			}
		}

		this._getNavResponse();

	}

	public ngOnChanges(): void {

		this.sendDataForMolding();
	}

	public sendDataForMolding(): void {

		this.craftMoldedData = this._prepareCraftContentNav.prepareCraftContentNav(this.nav.contents);

		this.clusterMoldedData = this._prepareClusterContentNav.prepareClusterContentNav(this.nav.contents);

		this.designerMoldedData = this._prepareDesignersCollaborationContentNav.prepareDesignerContentNav(this.nav.contents);

		this.navMaterialData = this._prepareMoldedMaterial.prepareMeterial(this.nav.fabricProducts);

		this.navPatternData = this._prepareMoldedPattern.preparePattern(this.nav.fabricProducts);

		this.navColorData = this._prepareMoldedColor.prepareColor(this.nav.fabricProducts);

		this.fabricCraft = this._prepareFabricCraftContentNav.prepareCraftContentNav(this.nav.fabricProducts);

		this.finishNav = this._prepareFinishCraftContentNav.prepareFinishData(this.nav.finishedProducts);


		this._fetchBlogList.getBlogList();
	}

	private _getNavResponse(): void {
		this._getNavRequest.getNavRequest(
			RequestMapperService.GET_NAV,
			() => {
				//success
			},
			() => {
				//error
			},
			(response: NavFetchedData) => {
				// console.log(response);
				this.nav = response;
				this.sendDataForMolding()
			},
			() => {
			},
			() => {
			}
		)
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		if (this.isBrowser) {
			this.isMobileDevice = window.screen.width <= 999;
			if(this.isMobileDevice) {
				return
			}
		}

		this._getNavResponse();
	}

}
