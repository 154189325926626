import {Component, Input, OnInit} from '@angular/core';
import {StoryPreview} from "../../story/interface/story-preview";
import {WishlistTransmissionService} from "../../raintree/transmission/wishlist-transmission.service";
import {ProductPreview} from "../../product/product-listing/interface/product-preview";
import {StoryTransmissionService} from "../../raintree/transmission/story-transmission.service";
import {HomeBlogViewService} from "../service/home-blog-view.service";

@Component({
	selector: 'fb-home-blog-view',
	templateUrl: './home-blog-view.component.html',
	styleUrls: ['./home-blog-view.component.scss']
})
export class HomeBlogViewComponent implements OnInit {

	private _storyCsv: string = '9149,14799,16676';
	private _productCsv: string = 'JKD1000003, SAK0960002';
	public storyPreview: StoryPreview[] = [];
	@Input() storyContentType: string = '';
	public products: ProductPreview[] = [];
	public loading: boolean = true;

	constructor(
		public blogView: HomeBlogViewService,
		private _wishlistApi: WishlistTransmissionService,
		private _storyApi: StoryTransmissionService
	) {
	}

	ngOnInit() {
		this._wishlistApi.getProductPreviewByCSV(
			this._productCsv,
			()=> {},
			()=> {},
			(response: ProductPreview[])=> {
				this.products = response;
			},
			()=> {},
			()=> {},
		)

		this._storyApi.getStoryListByIdCsv(
			this._storyCsv,
			() => {
				this.loading = true;
			},
			() => {},
			(response) => {
				this.storyPreview = response;
			},
			() => {},
			() => {
				this.loading = false;
			},
		)
	}
}
