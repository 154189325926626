import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {ProductListing} from "../../raintree/interface/product-listing/product-listing";
import {FabricProfileItemPl} from "../../product/product-listing/interface/fabric-profile-item-pl";
import {RelatedProductList} from "../../product-details/interface/related-product-list";
import {JWTService} from "../../authentication/jwt.service";
import {ProductListPriceCalculationService} from "../../product/service/product-list-price-calculation.service";
import {isPlatformBrowser} from "@angular/common";
import {VolumeDiscountProfileItem} from "../../product-details/interface/volume-discount-profile-item";
import {CurrencyResolverService} from "../../currency-resolver.service";
import {ApCurrency} from "../../pipe/enum/ap-currency";

@Component({
	selector: 'fb-search-product-preview',
	templateUrl: './search-product-preview.component.html',
	animations: [
		trigger(
			'enterAnimation', [
				transition(':enter', [
					style({transform: 'translateX(0)', opacity: 0}),
					animate('200ms', style({transform: 'translateX(0)', opacity: 1}))
				]),
				transition(':leave', [
					style({transform: 'translateX(0)', opacity: 1}),
					animate('200ms', style({transform: 'translateX(0)', opacity: 0}))
				])
			]
		)
	],
	styleUrls: ['./search-product-preview.component.scss']
})
export class SearchProductPreviewComponent implements OnInit {

	@Input()
	public productListingData: ProductListing = {} as ProductListing;
	public fabricOrFinished = '';
	public isLoggedIn: boolean = false;
	public fabricProfileItemList: FabricProfileItemPl[] = [];
	public rateOfFabricProfileItemList: FabricProfileItemPl[] = [];
	public relatedProductList: RelatedProductList[] = [];
	public extendRelatedProductList: RelatedProductList[] = [];
	public imageCont = "";
	public hoverActive = false;
	public discountedPrice = 0;
	public maxDiscount = 0;
	public isBrowser: boolean = false;
	public selectedCurrency: ApCurrency = ApCurrency.USD;

	constructor(
		private _jwt: JWTService,
		private _productListingPriceCalculation: ProductListPriceCalculationService,
		private _currencyResolver: CurrencyResolverService,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	public ngOnInit(): void {

		this.selectedCurrency = this._currencyResolver.getSelectedCurrency();
		this._currencyResolver.selectCurrencyChange.subscribe(() => {
			this.selectedCurrency = this._currencyResolver.getSelectedCurrency();
		});

		this.isLoggedIn = this._jwt.hasValidJWT();

		if (this.productListingData.product.productGroup == 'fabric') {
			this.fabricOrFinished = 'fabric-product';
		} else {
			this.fabricOrFinished = 'finished-product'
		}

		this.imageCont = this.productListingData.product.heroImage;

		this.productListingData.product.fabricProfile?.fabricProfileItemList.forEach(
			(elm: FabricProfileItemPl, i: number) => {
				if (i <= 2) this.fabricProfileItemList.push(elm)
				else this.rateOfFabricProfileItemList.push(elm)
			});

		if (this.productListingData.product.relatedProductList) {
			this.productListingData.product.relatedProductList.forEach(
				(elm: RelatedProductList, i: number) => {
				if (i <= 2) this.relatedProductList.push(elm)
				else this.extendRelatedProductList.push(elm)
			});
		}


		if (this.productListingData.product.volumeDiscountProfile) {
			let maxDiscount: VolumeDiscountProfileItem = this.productListingData.product.volumeDiscountProfile!
				.volumeDiscountProfileItemList.reduce(
					(max, obj) =>
						(max.discount > obj.discount) ? max : obj);
			this.maxDiscount = maxDiscount.discount;

			this.discountedPrice = this._productListingPriceCalculation.getBulkPrice(this.productListingData,
				this.productListingData.product.productGroup == 'fabric'
			)
		}


	}

	public changeHeroToHoverImage(str: string) {
		if (str == "onover") {
			this.hoverActive = true;
		} else {
			this.hoverActive = false;
			;
		}
		// console.log(this.hoverActive)
	}

	public storeUrl(): void {
		if (this.isBrowser) {
			localStorage.setItem("storeUrl", window.location.href)
		}

	}
}
