import {Injectable} from '@angular/core';
import {OwlOptions} from "ngx-owl-carousel-o";

@Injectable({
    providedIn: 'root'
})
export class OwlMultiSliderConfigService {

    public static owlOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        rewind: false,
        dots: false,
        navSpeed: 700,
        margin: 20,
        autoplay: true,
        autoplayTimeout: 3000,
		nav: false,
        navText: ['', ''],
        slideBy: 1,
        dotsData: false,
        dotsEach: 1,
        center: false,
        autoHeight: false,
        responsive: {
            0: {
                items: 1
            },
            740: {
                items: 3
            },
            940: {
                items: 5
            }
        },
    }

    public static owlOptionsNews: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        rewind: false,
        dots: false,
        navSpeed: 700,
        margin: 20,
        autoplay: true,
        autoplayTimeout: 3000,
		nav: true,
        navText: ['<', '>'],
        slideBy: 1,
        dotsData: false,
        dotsEach: 1,
        center: false,
        autoHeight: false,
        responsive: {
            0: {
                items: 1
            },
            740: {
                items: 3
            },
            940: {
                items: 5
            }
        },
    }

    public static owlOptionsSingleSlide: OwlOptions = {
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        rewind: false,
        dots: true,
        navSpeed: 700,
        margin: 20,
        autoplay: true,
        autoplayTimeout: 3000,
        navText: ['', ''],
        slideBy: 1,
        dotsData: true,
        dotsEach: 1,
        center: false,
        responsive: {
            0: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 1
            }
        },
        nav: false,
    }

	public static owlOptionsReview: OwlOptions = {
		loop: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		rewind: false,
		dots: false,
		navSpeed: 700,
		margin: 20,
		autoplay: true,
		autoplayTimeout: 5000,
		nav: true,
		navText: ['<', '>'],
		slideBy: 1,
		dotsData: false,
		dotsEach: 1,
		center: true,
		autoHeight: false,
		responsive: {
			0: {
				items: 1
			},
			740: {
				items: 2
			},
			940: {
				items: 3
			}
		},
	}

    constructor() {
    }
}
