
<lib-bmx-toast></lib-bmx-toast>
<div class="body_wrapper {{isCheckoutPageActivated?'hide_forrex':''}} {{popupOpen?'popupOpen_body_wrapper':''}} {{popupOpenPreorder?'popupOpen_body_wrapper':''}}">
	<div class="desktop_nav_cont {{menuScrolled?'scrolled':''}} home_page_not_active {{popupOpen?'popupOpen':''}} {{popupOpenPreorder?'popupOpen':''}}"
		*ngIf="navShow">
		<fb-navigation [dataStaticLeftMenu]="staticMenuLeft" [dataStaticRightMenu]="staticMenuRight"
			[navigationData]="navigationDataFetch"></fb-navigation>
	</div>
	<div class="{{navShow?'a-main-c':'a-main-margin'}}">
		<!-- <div [ngClass]="isHomeRoute ? '':'a-main-margin'" class="a-main"> -->
		<div class="a-main a-main-margin {{!isHomePage?'home_page_not_active':''}}">
			<router-outlet></router-outlet>
			<!-- <fb-home></fb-home> -->

			<!-- <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>vsdvs<br/><br/><br/><br/><br/><br/> -->
		</div>
		<!-- <app-footer></app-footer> -->
	</div>
	<!-- <router-outlet></router-outlet> -->
</div>


<!-- <section class="progress_top_to_bottom">
	<div>
		<h3>test</h3>
		<svg>
			<circle id="border-track" cx="75" cy="75" r="65"></circle>
			<circle id="track" cx="75" cy="75" r="65"></circle>
			<circle id="progress" cx="75" cy="75" r="65"></circle>
		</svg>
	</div>
</section> -->
<fb-cookie-information *ngIf="showCookieBanner"></fb-cookie-information>
<fb-footer></fb-footer>
