import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PasteboxService} from '../../services/pastebox.service';
import {RaintreeResponse} from '../interface/raintree-response';
import {MessageService} from '../../services/message.service';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandlerService} from '../http-error-handler.service';
import {JWTService} from 'src/app/authentication/jwt.service';

@Injectable({
	providedIn: 'root',
})
export class TransmissionService {
	constructor(
		private _http: HttpClient,
		private _httpErrorHandler: HttpErrorHandlerService,
		private _jwt: JWTService,
		private _pastebox: PasteboxService
	) {}

	public executeBasicGet(
		url: string,
		onPreExecute: () => void,
		onPostExecute: (response: string) => void,
		onSuccess: (response: string) => void,
		onFailure: (error: string) => void,
		onComplete: () => void
	): void {
		onPreExecute();

		this._http
			.get<RaintreeResponse>(url, {
				headers: this._jwt.injectToken(url),
			})
			.pipe(catchError(this._httpErrorHandler.intercept))
			.subscribe(
				(response: RaintreeResponse) => {
					onPostExecute(response.message);
					if (response.success) onSuccess(response.message);
					else onFailure(MessageService.RESOURCE_VALIDATION_FAILED);
				},
				(error) => onFailure(error),
				() => onComplete()
			);
	}

	public executeGetPayload<T extends RaintreeResponse, S>(
		url: string,
		onPreExecute: () => void,
		onPostExecute: (response: S) => void,
		onSuccess: (response: S) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
		payloadKey: string,
		requiresAuthentication: boolean = true
	): void {
		onPreExecute();

		this._http
			.get<T>(url, {
				// TODO: uncoment this start
				headers: requiresAuthentication ? this._jwt.injectToken(url) : undefined,
				// TODO: uncoment this end


			})
			.pipe(catchError(this._httpErrorHandler.intercept))
			.subscribe(
				(response: T) => {
					onPostExecute(response[payloadKey]);
					if (response.success) onSuccess(response[payloadKey]);
					else onFailure(MessageService.RESOURCE_VALIDATION_FAILED);
				},
				(error) => onFailure(error),
				() => onComplete()
			);
	}

	public executePostPayload<T>(
		url: string,
		payload: T,
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
		requiresAuthentication: boolean = true,
	): void {

		onPreExecute();

		this._http.post<RaintreeResponse>(url, payload, {
			headers: requiresAuthentication ? this._jwt.injectToken(url) : undefined,
		}).pipe(
			catchError(this._httpErrorHandler.intercept)
		).subscribe(
			(response: RaintreeResponse) => {
				onPostExecute(response);
				if (response.success) onSuccess(response);
				else onFailure(this._pastebox.isEmptyString(response.message) ? MessageService.RESOURCE_VALIDATION_FAILED : response.message);
			},
			error => {
				onFailure(error);
				onComplete();
			},
			() => onComplete()
		);
	}

	public executePostPayloadCustomResponse<T>(
		url: string,
		payload: T,
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: RaintreeResponse) => void,
		onComplete: () => void,
		requiresAuthentication: boolean = true,
	): void {

		onPreExecute();

		this._http.post<RaintreeResponse>(url, payload, {
			headers: requiresAuthentication ? this._jwt.injectToken(url) : undefined,
		}).pipe(
			catchError(this._httpErrorHandler.intercept)
		).subscribe(
			(response: RaintreeResponse) => {
				onPostExecute(response);
				if (response.success) onSuccess(response);
				else onFailure(response);
			},
			error => {
				onFailure(error);
				onComplete();
			},
			() => onComplete()
		);
	}

	public executePatchPayload<T>(
		url: string,
		payload: T,
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
		requiresAuthentication: boolean = true,
	): void {

		onPreExecute();

		this._http.patch<RaintreeResponse>(url, payload, {
			headers: requiresAuthentication ? this._jwt.injectToken(url) : undefined,
		}).pipe(
			catchError(this._httpErrorHandler.intercept)
		).subscribe(
			(response: RaintreeResponse) => {
				onPostExecute(response);
				if (response.success) onSuccess(response);
				else onFailure(this._pastebox.isEmptyString(response.message) ? MessageService.RESOURCE_VALIDATION_FAILED : response.message);
			},
			error => {
				onFailure(error);
				onComplete();
			},
			() => onComplete()
		);
	}

	public executeDeletePayload<T>(
		url: string,
		payload: T,
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
		requiresAuthentication: boolean = true,
	): void {

		onPreExecute();

		this._http.delete<RaintreeResponse>(url, {
			headers: requiresAuthentication ? this._jwt.injectToken(url) : undefined,
		}).pipe(
			catchError(this._httpErrorHandler.intercept)
		).subscribe(
			(response: RaintreeResponse) => {
				onPostExecute(response);
				if (response.success) onSuccess(response);
				else onFailure(this._pastebox.isEmptyString(response.message) ? MessageService.RESOURCE_VALIDATION_FAILED : response.message);
			},
			error => {
				onFailure(error);
				onComplete();
			},
			() => onComplete()
		);
	}

    public executeFormPostPayload<T extends RaintreeResponse>(
		url: string,
		payload: FormData,
		onPreExecute: () => void,
		onPostExecute: (response: T) => void,
		onSuccess: (response: T) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
		requiresAuthentication: boolean = true,
	): void {

		onPreExecute();

        // const formData: FormData = new FormData();

        // debugger;

        // Object.keys(payload).forEach((key)=>{
        //     formData.append(key,payload[key])
        // });

		this._http.post<T>(url, payload, {
			headers: requiresAuthentication ? this._jwt.injectToken(url, false) : undefined,
		}).pipe(
			catchError(this._httpErrorHandler.intercept)
		).subscribe(
			(response: T) => {
				onPostExecute(response);
				if (response.success) onSuccess(response);
				else onFailure(this._pastebox.isEmptyString(response.message) ? MessageService.RESOURCE_VALIDATION_FAILED : response.message);
			},
			error => {
				onFailure(error);
				onComplete();
			},
			() => onComplete()
		);
	}
}
