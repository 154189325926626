import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TopStaticNavComponent} from './top-static-nav/top-static-nav.component';
import {NavigationComponent} from './navigation/navigation.component';
import {DesktopNavigationModule} from './desktop-navigation/desktop-navigation.module';
import {MobileNavigationModule} from "./mobile-navigation/mobile-navigation.module";


@NgModule({
	declarations: [
		TopStaticNavComponent,
		NavigationComponent,
	],
	imports: [
		CommonModule,
		DesktopNavigationModule,
		MobileNavigationModule
		// CartModule
	],
	exports: [
		TopStaticNavComponent,
		NavigationComponent,
	]
})
export class NavigationModule {
}
