import {Component} from '@angular/core';

@Component({
	selector: 'fb-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

	public anuprernaBlogLink = '/content/';
}
