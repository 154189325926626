import {Component, Input} from '@angular/core';
import {IReview} from "../../review/interface/review";
import {OwlOptions} from "ngx-owl-carousel-o";
import {OwlMultiSliderConfigService} from "../service/owl-multi-slider-config.service";
import ImageViewer from "awesome-image-viewer";
import {IReviewProductPreview} from "../../review/interface/review-product-preview";

@Component({
	selector: 'fb-home-review-carousel',
	templateUrl: './home-review-carousel.component.html',
	styleUrls: ['./home-review-carousel.component.scss']
})
export class HomeReviewCarouselComponent {

	@Input()
	public reviews: IReview[] = [];

	protected stars: boolean[] = Array(5).fill(0);
	public carouselConfig: OwlOptions = OwlMultiSliderConfigService.owlOptionsReview;

	private prepareProductImages(imagesCSV: string): { mainUrl: string }[] {
		return imagesCSV.split(",").map(image => {
			return {mainUrl: image}
		})
	}

	protected onUserProvidedImagesClick(productImages: string | undefined): void {
		if (!productImages) return;

		new ImageViewer({
			images: this.prepareProductImages(productImages)
		})
	}

	protected prepareProductRedirectionUrl(product: IReviewProductPreview | undefined): string {
		if (!product) return '#';

		return (product.productGroup == 'fabric' || product.productGroup == 'swatch')
			? `https://anuprerna.com/product/fabric-product/${product.slug}`
			: `https://anuprerna.com/product/finished-product/${product.slug}`;
	}
}
