<section class="fb-search-c flex flex-col justify-start items-stretch">
    <div class="fb-search-wrapper flex justify-center items-center">
        <form [formGroup]="searchFormGroup"
              (keydown)="onSubmit($event)"
              (ngSubmit)="onSubmit($event)"
              class="fb-search flex flex-row-reverse justify-start items-stretch">
            <label class="fb-search-label text-gray-400 hidden" for="search-anuprerna">Search</label>
            <input class="fb-search-input w-full"
                   type="text"
                   name="searchBarField"
                   id="search-anuprerna"
                   [formControl]="searchFormControl"
                   autocomplete="off"
                   placeholder="Search"
				   (paste)="searchOnChange()"
                   (keyup)="searchOnChange()">
            <div class="flex justify-center items-center">
                <button [disabled]="searchFormControl.valid"
						type="submit"
						class="ml-2.5 pt-2 fb-search-button h-full">
					<span class="material-symbols-outlined">search</span>
                </button>
            </div>
        </form>
        <button (click)="clearSearchBar()"
                *ngIf="( searchFormControl.dirty || searchFormControl.touched )"
                class="fb-search-clear-button fb-font-tgb uppercase text-base mt-0.5"
                title="Clear button"
                aria-label="Clear button" >Clear</button>
        <div class="fb-search-close h-full">
            <button (click)="closeSearchBar()" class=" mr-4 mt-0.5">
				<span class="material-symbols-outlined "> clear </span>
            </button>
        </div>
    </div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
    <fb-search-products  *ngIf="!loading && searchFormControl.valid && ( searchFormControl.dirty || searchFormControl.touched )"
                          [products]="searchProducts"
                          [term]="searchFormControl.value"
                          class="py-1 px-2"></fb-search-products>
	<div *ngIf="!loading && searchFormControl.valid && ( searchFormControl.dirty || searchFormControl.touched ) && searchProducts.length > 0"
		 class="mb-5 px-2 w-full text-center">
		<a routerLink="/display/search"
		   [queryParams]="{search: searchFormControl.value}"
		   (click)="dialogRef.close()"
		   class="text-base flex items-center justify-end gap-2">
			View All Results
			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#b7a98f"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/></svg>
		</a>
	</div>
</section>
