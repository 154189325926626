import {Injectable} from '@angular/core';
import {MoldedColorNav} from '../desktop-navigation/interface/molding/molded-color-nav';
import {NavProductFetchedData} from '../desktop-navigation/interface/nav-product-fetched-data';
import {NavProductColorFetched} from '../desktop-navigation/interface/nav-product-color-fetched';

@Injectable({
  providedIn: 'root'
})
export class PrepareColorNavService {

  constructor() { }

  public prepareColor(data: NavProductFetchedData[]): MoldedColorNav[] {

	// console.log(data);
	let colors: MoldedColorNav[] = [];

	  if (data && data.length > 0) {
		  data.forEach((elm: NavProductFetchedData) => {
			  if (elm.colorId) {
				  elm.colors!.forEach((color: NavProductColorFetched) => {

					  colors.push({
						  name: color.name,
						  id: color.id,
						  hex: color.hex
					  })

				  })
			  }

		  })
		  // debugger

		  colors = [...new Map(colors.map(item => [item['name'], item])).values()]
	  }

	// console.log(colors);
	return colors
}
}
