import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {MoldedContent} from '../interface/molding/molded-content';
import {MoldedContentSubCategory} from '../interface/molding/monded-content-sub-category';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'fb-nav-section-with-image',
  templateUrl: './nav-section-with-image.component.html',
  styleUrls: ['./nav-section-with-image.component.scss']
})
export class NavSectionWithImageComponent {

	@Input() moldedData: MoldedContent = {} as MoldedContent;
	@Input() categoryName ='';
	public hoveredImageData: MoldedContentSubCategory = {} as MoldedContentSubCategory;
	public hoveredImage = '';
	public isBrowser: boolean = false;
	constructor(
		private _router: Router,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	public ngOnChanges(): void {
		// debugger
		// console.log(this.moldedData);
		if(this.moldedData.category && this.moldedData.category.length){
			this.moldedData.category[0].segmentCategory[0].hoverActive = true;
			this.hoveredImage = this.moldedData.category[0].segmentCategory[0].image;
			this.hoveredImageData = this.moldedData.category[0].segmentCategory[0];
		}

	}

	public hoverUpdateImage(storySubCategory:MoldedContentSubCategory  ,image:string, segIndex:number, subIndex: number): void {
		this.hoveredImage = image;
		this.moldedData.category[0].segmentCategory[0].hoverActive = false;
		this.hoveredImageData = storySubCategory;
	}

	public hoverleaveImage(image:string): void {
		this.hoveredImage = image;
		this.moldedData.category[0].segmentCategory[0].hoverActive = true;
	}

	public navigate(
		productTypeLink: string,
		paramKey: string,
		paramValue: string
	): void {

		this._router.navigate([productTypeLink,],
			{ queryParams: { [paramKey]: paramValue } }).then((): void => {
				if (this.isBrowser) {
					window.location.reload();
				}

			});
	}
}
