<section class="fb-third-party w-full flex flex-col justify-center items-center py-10">
	<h1 class="text-3xl sm:text-5xl text-[#b7a98f] font-medium mb-10">
		We are in the <span class="text-black">news</span>
	</h1>
	<div class="w-full container lg:px-12">
		<owl-carousel-o [options]="customOptions">
			<ng-template carouselSlide>
			<a class="fb-third-party-card"
			   target="_blank"
			   href="https://www.buildanest.org/preserving-indias-khadi-tradition-with-anuprerna/">
				<img class="fb-tp-news" [src]="cdnLink + 'nest-news.jpg'"  alt="Preserving India’s Khadi Tradition with Guild Member, Anuprerna" lazyLoad>
				<div class="icon">
					<div class="fb-third-party-header">
						<img src="./assets/img/nest.png" alt="nest">
						<p>
							Nest
						</p>
					</div>

					<article>
						Preserving India’s Khadi Tradition with Guild Member, Anuprerna
					</article>
				</div>
			</a>
			</ng-template>
			<ng-template carouselSlide>
			<a class="fb-third-party-card"
			   target="_blank"
			   href="https://craftatlas.co/artisans/anuprerna-artisanal-heritage-textiles-of-bengal">
				<img class="fb-tp-news" [src]="cdnLink + 'craft-news.png'"  alt="Anuprerna - Artisanal heritage textiles of Bengal" lazyLoad>

				<div class="icon">
					<div class="fb-third-party-header">

					<img src="./assets/img/the_craft_atlas.png" alt="the craft atlas">
					<p>
						The Craft Atlas
					</p>
					</div>
					<article>Anuprerna - Artisanal heritage textiles of Bengal</article>
				</div>
			</a>
			</ng-template>
			<ng-template carouselSlide>
			<a class="fb-third-party-card"
			   target="_blank"
			   href="https://www.etsy.com/in-en/shop/Anuprerna/reviews?ref=shop_info">
				<img class="fb-tp-news etsy" [src]="cdnLink + 'etsy-news.png'"  alt="Anuprerna - East India's Handwoven Natural Ethical Textile & Crafts" lazyload>
				<div class="icon">
					<div class="fb-third-party-header">
					<img src="./assets/img/etsy.png" alt="etsy">
					<p>
						Etsy Speaks
					</p>
					</div>
					<article>Anuprerna - East India's Handwoven Natural Ethical Textile & Crafts</article>
				</div>
			</a>
			</ng-template>
			<ng-template carouselSlide>
			<a class="fb-third-party-card"
			   target="_blank"
			   href="https://www.thetextileatlas.com/craft-stories/jamdani-weaving">
				<img class="fb-tp-news" [src]="cdnLink + 'textile-news.jpg'"  alt="Anuprerna, creating Jamdani fabrics in 4 clusters at the Burdwan district in West Bengal, India." lazyLoad>
				<div class="icon">
					<div class="fb-third-party-header">

					<img src="./assets/img/the_textail_atlas.png" alt="the textail atlas">
					<p>
						The Textile Atlas
					</p>
					</div>
					<article>Anuprerna, creating Jamdani fabrics in 4 clusters at the Burdwan district in West Bengal, India.</article>
				</div>
			</a>
			</ng-template>
			<ng-template carouselSlide>
			<a class="fb-third-party-card"
			   target="_blank"
			   href="https://in.fashionnetwork.com/news/Anuprerna-launches-e-commerce-platform-eyes-international-handloom-trade,1222271.html">
				<img class="fb-tp-news" [src]="cdnLink + 'fashion-network-news.jpg'"  alt="Anuprerna launches e-commerce platform, eyes international handloom trade" lazyLoad>
				<div class="icon">
					<div class="fb-third-party-header">

					<img src="./assets/img/fashion_network.png" alt="fashion network">
					<p>
						Fashion Network
					</p>
					</div>
					<article>
						Anuprerna launches e-commerce platform, eyes international handloom trade
					</article>
				</div>
			</a>
			</ng-template>
			<ng-template carouselSlide>
			<a class="fb-third-party-card"
			   target="_blank"
			   href="https://www.paypal.com/IN/webapps/mpp/supportlocal#">
				<img class="fb-tp-news" [src]="cdnLink + 'paypal-news.jpg'"  alt="Championing slow fashion before it was fashionable" lazyLoad>
				<div class="icon">
					<div class="fb-third-party-header">

					<img src="./assets/img/paypal.png" alt="Championing slow fashion before it was fashionable">
					<p>
						Paypal
					</p>
					</div>
					<article>Championing slow fashion before it was fashionable</article>
				</div>
			</a>
			</ng-template>
			<ng-template carouselSlide>
			<a class="fb-third-party-card"
			   target="_blank"
			   href="https://lbb.in/kolkata/anuprerna-fabrics-scarves-sarees/">
				<img class="fb-tp-news" [src]="cdnLink + 'lbb-news.jpg'"  alt="Shop For Handwoven Fabrics, Scarves And Sarees From This Bengal-Based Brand" lazyLoad>
				<div class="icon">
					<div class="fb-third-party-header">

					<img src="./assets/img/lbb.png" alt="lbb">
					<p>
						LBB
					</p>
					</div>
					<article>Shop For Handwoven Fabrics, Scarves And Sarees From This Bengal-Based Brand</article>
				</div>
			</a>
			</ng-template>
			<ng-template carouselSlide>
			<a class="fb-third-party-card"
			   target="_blank"
			   href="https://www.youtube.com/watch?v=zCz3Z-t7q9E&ab_channel=NDTV">
				<img class="fb-tp-news" [src]="cdnLink + 'ndtv-news.jpg'"  alt="Sustainable, Upcycled And Repurposed - Meet the masters of their craft" lazyLoad>
				<div class="icon">
					<div class="fb-third-party-header">

					<img src="./assets/img/ndtv.png" alt="ndtv">
					<p>
						NDTV
					</p>
					</div>
					<article>Sustainable, Upcycled And Repurposed - Meet the masters of their craft</article>
				</div>
			</a>
			</ng-template>

			<ng-template carouselSlide>
			<a class="fb-third-party-card"
			   target="_blank"
			   href="https://www.fibre2fashion.com/interviews/face2face/anuprerna/amit-singha/12581-1/">
				<img class="fb-tp-news" [src]="cdnLink + 'fibre-news.jpg'"  alt="" lazyLoad>
				<div class="icon">
					<div class="fb-third-party-header">

					<img src="./assets/img/fibre_fashion.png" alt="Interview With Amit Singha, Founder of Anuprerna">
					<p>
						Fibre2Fashion
					</p>
					</div>
					<article>Interview With Amit Singha, Founder of Anuprerna</article>
				</div>
			</a>
			</ng-template>
		</owl-carousel-o>

	</div>
</section>
