import {Pipe, PipeTransform} from '@angular/core';
import {StoreForexService} from "../navigation/service/store-forex.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ForexList} from '../navigation/desktop-navigation/interface/forex-list';
import {CurrencyResolverService} from "../currency-resolver.service";
import {ApCurrency} from "./enum/ap-currency";

@UntilDestroy()
@Pipe({
	name: 'currencyConverter',
	pure: false
})
export class CurrencyConverterPipe implements PipeTransform {

	private exchangeRate: number = 1;
	constructor(
		private _currencyResolver: CurrencyResolverService,
		private _storedForex: StoreForexService
	) {
		this.initializeCurrency();

		this._currencyResolver.selectCurrencyChange.pipe(untilDestroyed(this)).subscribe(() => {
			this._changeExchangeRate(this._currencyResolver.getSelectedCurrency());
		});
	}

	private initializeCurrency(): void {
		const selectedCurrency: ApCurrency = this._currencyResolver.getSelectedCurrency();
		this._changeExchangeRate(selectedCurrency);
	}

	private _changeExchangeRate(selectedCurrency: string): void {
		const baseForexRate: number = this._storedForex.forexResponse[selectedCurrency]? this._storedForex.forexResponse[selectedCurrency]: 1;
		this.exchangeRate = baseForexRate * this._conversionRate(this._storedForex.forexListResponse, selectedCurrency);
	}

	public transform(inrPrice: number): number {
		return inrPrice * this.exchangeRate;
	}

	private _conversionRate(conversionRateList: ForexList[], selectedCurrency: string): number {
		const currency: string = selectedCurrency.toLowerCase();
		const conversionRate: ForexList | undefined = conversionRateList.find(rate => rate.currency.toLowerCase() === currency);
		return conversionRate ? conversionRate.rate : 1.25;
	}


}
